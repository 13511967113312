import { FormGroup } from '@angular/forms';
import { Observable, EMPTY, switchMap, from } from 'rxjs';
import { ErrorDialogComponent } from './error-dialog.component';
import { ConfirmationDialogComponent } from './confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

/* This class is a helper class for common values
Usage:
in the component you need:
import { DataHelper } from '@components/hr/helpers/data-helper';
dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)
*/
export class DataHelper {
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: any
  ) {}


  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })

  /*
  * Use this function to fetch any data from any table
  * Usage Example for fetching employees:
  *  this.dataHelper.fetchItems( 
      this.employeeService.getEmployees(page, size), <--- State the getter method from the corresponding service
      (response: EmployeesResponse) => {  <--- Set response type
        this.employees = response.items; <--- get the data you want from response
        this.totalEmployees = response.totalItems;
        this.employees.forEach(employee => {
          this.loadEmployeePicture(employee);
        });
        this.filteredEmployees = [...this.employees];
      }
    );
  */
  fetchItems(serviceCall: any, responseSetter: Function): void {
    serviceCall.subscribe(
      (data: any) => {
        if (data && typeof data === 'object' || 'text') {
          responseSetter(data);
        } else {
          console.error('Unexpected data format:', data);
        }
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  /*
  Use this function to add any new Record
  Usage Example of adding new department:
   this.dataHelper.addNewRecord(
      this.departmentForm, <---- pass the form
      dataToSend, <-- passs the data that are going to be put in the db
      (data) => this.departmentsService.createDepartment(data), <---- pass the corresponding service
      'Department created successfully', <---- success message
      'Error creating department', <--- error meesage
      '/humanResources/departments' <--- path to redirect
    );

  */ 
    addNewRecord(
      form: FormGroup,
      dataToSend: any,
      serviceCall: (data: any) => Observable<any>,
      successMessage: string,
      navigationRoute: string,
      successCallback?: (response: any) => void
    ) {
      if (form.valid) {
        serviceCall(dataToSend).subscribe({
          next: (response) => {
            this.Toast.fire({
              icon: 'success',
              title:  this.translate.instant('Success!'),
              text: this.translate.instant(successMessage),
            });
            form.reset();
    
            // If there's a successCallback, execute it
            if(successCallback) {
              successCallback(response);
            }
    
            this.router.navigate([navigationRoute]);
          },
          error: (error) => {
        this.showError(error);
        
         
          }
        });
      }
    }
  
  

  
  /*
  * Use this function to complete delete and update operations with warning messages
  * Usage Example for deleting employee:
  *   this.dataHelper.withConfirmation(
      'Delete Confirmation',  <--- Title of dialog
      'Do you confirm the deletion of this employee? Some changes may affect major functionalities', <---- body of dialog
      () => this.employeeService.deleteEmployee(element.id), <---- servicce that will execute the operation
      'Employee deleted successfully', <--- success message
      () => this.fetchEmployees(), <-- success callback service
      () => null <--- error callback service
    );
  */
    withConfirmation(
      title: string,
      message: string,
      serviceCall: () => Observable<any>,
      successMessage: string,
      successCallback?: () => void,
      errorCallback?: (error: any) => void
    ): void {
      Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('No'),
      }).then((result) => {
        if (result.isConfirmed) {
          serviceCall().subscribe(
            () => {
              this.Toast.fire({
                icon: 'success',
                title:  this.translate.instant('Success!'),
                text: this.translate.instant(successMessage),
              });
              if (successCallback) successCallback();
            },
            (error: any) => {
              this.showError(error);
              if (errorCallback) errorCallback(error);
            }
          );
        }
      });
    }
    
    showError(error: any): void {
      const errorMessage = this.translate.instant(error || 'Error performing operation');
      
      // Using Toast to display the error message
      this.Toast.fire({
        icon: 'error',
        title: this.translate.instant('Error!'),
        text: errorMessage,
      });
    }
  
  // use to format date when loading from db
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
/**
 * 
 * the following 2 methods (confirmLeave and getBeforeUnloadEventMessage) prevent any component from closing unless the data were submitted.
 * USAGE(copy paste in the component you want):
 *   @HostListener('window:beforeunload', ['$event'])
      unloadNotification($event: any) {
        this.dataHelper.getBeforeUnloadEventMessage($event, this.yourform.dirty);
      }

      canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.yourform.dirty) {
          return true;
        }
        return this.dataHelper.confirmLeave();
      }
    ** for the above you need this line un the routing of the component:
    * canDeactivate: [CanDeactivateGuard],
 */
  confirmLeave(message: string = "If you leave, your unsaved changes will be discarded."): Observable<boolean> {
    const confirmation = Swal.fire({
      title: 'Leave this page?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Leave',
      cancelButtonText: 'Stay'
    }).then((result) => {
      return result.isConfirmed;
    });

    return from(confirmation); // Convert Promise to Observable
  }

  getBeforeUnloadEventMessage(event: any, isDirty: boolean): string | undefined {
    if (isDirty) {
      const message = "You have unsaved changes! Are you sure you want to leave?";
      event.returnValue = message; // For legacy browsers
      return message;
    }
    return;
  }

  
}
