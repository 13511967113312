import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetEmployee } from '@interfaces/GetEmployee.interface';
import { PostEmployee } from '@interfaces/PostEmployee.interface';
import { backend } from '@apis/api-endpoints';
import { EmployeesResponse } from '@interfaces/EmployeeResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClient) {}

  getEmployees(pageNumber: number,pageSize: number): Observable<EmployeesResponse> {
    return this.http.get<EmployeesResponse>(backend.employee.getAll(pageNumber,pageSize)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  registerEmployee(employee: any): Observable<any> {
    return this.http
      .post(backend.employee.post, employee, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
  uploadEmployeePicture(employeeId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post(backend.employee.uploadPicture(employeeId), formData)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  downloadEmployeePicture(employeeId: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(backend.employee.getPicture(employeeId), {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
    

  }

  deleteEmployee(employeeId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.employee.deleteById(employeeId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getEmployeeById(employeeId: number): Observable<PostEmployee> {
    return this.http
      .get<PostEmployee>(`${backend.employee.getById(employeeId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateEmployeeById(employeeId: number, employee: any): Observable<any> {
    return this.http
      .put(backend.employee.updateById(employeeId), employee, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
