import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_URL } from '@apis/api-endpoints';
import { GetOrder } from '@interfaces/GetOrder.interface';
import { Order } from '@interfaces/Order.interface';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@services/order.service';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { DataHelper } from 'src/app/helpers/data-helper';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css'],
})
export class ViewOrderComponent {
  orderId: number = 0;
  order!: GetOrder;
  logoUrl!: string;
  companyName!: string;
  companyEmail!: string;
  companyAddress!: string;
  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
    private tenantSettingsService: TenantSettingsService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.loadFromTenantSettings();
    this.loadDataMaps();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.orderId = +id;
        this.orderService
          .getByIdWithOrderLines(this.orderId)
          .subscribe((response) => {
            console.log(response);
            this.order = response;
            this.breadcrumbService.set(
              '@orderNumber',
              response.purchaseOrderNumber
            );
          });
        console.log(this.orderId);
      }
    });
  }
  loadFromTenantSettings(): void {
    this.dataHelper.fetchItems(
      this.tenantSettingsService.getTenantSettings(),
      (response: TenantSettings) => {
        this.logoUrl = `${BASE_URL}/${response.invoiceLogoPath}`;
        this.companyEmail = response.companyEmail;
        this.companyName = response.companyName;
        this.companyAddress = response.address;
      }
    );
  }
  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
  }
  onEdit() {
    this.router.navigate(['/purchases/orders/edit_order', this.orderId]);
  }

  onDelete() {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this order? Some changes may affect major functionalities',
      () => this.orderService.deleteOrder(this.orderId),
      'Order deleted successfully',
      () => this.router.navigate(['/purchases/orders']),
      () => null
    );
  }
  onMarkIssued(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Order status will be changed to issued!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm!'
      
    }).then((result) => {
      if (result.isConfirmed) {
        this.order.status = "ISSUED";
        this.orderService.updateOrderById(this.orderId,this.order).subscribe(
          () => {
            Swal.fire(
              'Success!',
              'Order updated successfully',
              'success'
            );
            this.router.navigate(['/purchases/orders/view_order', this.orderId]);
          },
        );
      }
    })
  }
  recordPurchaseReceive(){
    //TODO after implementation of purchasereceive
  }

}
