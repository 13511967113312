// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly translate: TranslateService
  ) {
    this.translate.setDefaultLang('English');
    this.translate.use('English');
  }

  async canActivate(): Promise<boolean> {
    var res = await this.keycloakService.isLoggedIn();
    if (res) {
      this.keycloakService.getToken().then((token) => {
        if (token) {
          const payload = JSON.parse(atob(token.split('.')[1]));
          this.userService.setLocale(payload.locale);
          this.translate.use(payload.locale || 'English');
        }
      });
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
