import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailData } from '@interfaces/EmailData.interface';
import { Invoice } from '@interfaces/Invoice.interface';
import { EmailDataService } from '@services/emailData.service';
import { InvoiceService } from '@services/invoices.service';
import { Location } from '@angular/common'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  emailForm: FormGroup;
  invoiceId!: number;
  invoice!: Invoice;
  isPdfAttached = true; // Property to track if the PDF is attached (default value of checkbox->checked)
  recipientName:string ="";

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private emailDataService: EmailDataService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.emailForm = this.fb.group({
      from: ['',[Validators.required,Validators.email]],
      emailAddress: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      pdfTitle: ['', Validators.required],
      pdfBlob: [null] // Initially null, will be set if there's an attachment
    });
  }

  ngOnInit() {
    this.emailDataService.currentEmailData.subscribe((data) => {
      if (data) {
        this.emailForm.patchValue({
          from: data.from,
          emailAddress: data.emailAddress,
          subject: data.subject,
          body: data.body,
          pdfTitle: data.pdfTitle
        });
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params['invoiceId'];
      this.invoiceId = params['invoice'];
      this.recipientName = params['recipientName'];
    });
  }

   // Method to toggle PDF attachment
   togglePdfAttachment(event: any) {
    this.isPdfAttached = event.target.checked;
    if (!this.isPdfAttached) {
      this.emailForm.patchValue({ pdfBlob: null });
    }
  }

  // Method to handle file selection
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      // this.emailForm.patchValue({ pdfBlob: file });
      // Optionally clear and set new email data if necessary
      this.emailDataService.clearEmailData();
      const emailData: EmailData = {
        from: this.emailForm.value.from,
        emailAddress: this.emailForm.value.emailAddress,
        subject: this.emailForm.value.subject,
        body: this.emailForm.value.body,
        pdfBlob: file, // Use the file directly here
        pdfTitle: file.name
      };
      this.emailDataService.setEmailData(emailData);
    } 
  }

  sendEmail() {
    if (!this.emailForm.valid) return;
  
    // Show loading alert
    Swal.fire({
      title: 'Sending Email...',
      html: 'Please wait while the email is being sent.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    const formData = new FormData();
    formData.append('emailAddress', this.emailForm.value.emailAddress);
    formData.append('subjectToSent', this.emailForm.value.subject);
    formData.append('bodyToSent', this.emailForm.value.body);
  
    const emailData = this.emailDataService.getCurrentEmailDataValue();
    if (emailData && emailData.pdfBlob) {
      formData.append('formFile', emailData.pdfBlob, `${this.emailForm.value.pdfTitle}.pdf`);
    }
  
    this.invoiceService.sendEmail(formData).subscribe({
      next: () => {
        if (this.invoice && this.invoice.status?.toLowerCase() != 'sent') {
          this.invoice.status = 'SENT';
          this.invoiceService.updateInvoiceById(this.invoiceId, this.invoice).subscribe({
            next: () => {},
            error: (err) => {
              console.log("Error updating invoice status",err)
            }
          });
        }
        // Close loading alert and show success message
        Swal.fire({
          icon: 'success',
          title: 'Email Sent!',
          text: 'Your email has been sent successfully.',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
              this.router.navigate(['/sales/invoices']);            
          }
        });
  
        
      },
      error: (error) => {
        // Close loading alert and show error message
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonText: 'Try Again'
        });
      },
    });
  }

  onCancel(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.location.back();
  }
  
  
}
