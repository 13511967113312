import { Component, OnInit } from '@angular/core';
import { BrandService } from '@services/brand.service';
import { Brand } from '@interfaces/Brand.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css'],
})
export class BrandsComponent implements OnInit {
  displayedColumns:Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
  ];
  brands: Brand[] = [];
  filteredBrands: Brand[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private brandService: BrandService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  ngOnInit(): void {
    this.fetchBrands();
  }

  fetchBrands(): void {
    this.dataHelper.fetchItems(
      this.brandService.getAll(),
      (data: Brand[]) => {
        this.brands = data;
        this.filteredBrands = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredBrands = this.brands.filter(
      (brand) =>
        brand.name.toLowerCase().includes(filterValue)
    );
  }

  deleteBrand(element: Brand): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this brand? Some changes may affect major functionalities',
      () => this.brandService.deleteBrand(element.id),
      'Brand deleted successfully',
      () => this.fetchBrands(),
      () => null
    );
  }

  addBrand() {
    this.router.navigate(['masters/brands/new_brand']);
  }

  editBrand(element: Brand): void {
    this.router.navigate(['masters/brands/edit_brand', element.id]);
  }
}
