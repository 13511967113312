import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { Category } from '@interfaces/Category.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<Category[]> {
    return this.http.get<Category[]>(backend.categoriesData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getById(categoryId: number): Observable<Category> {
    return this.http.get<Category>(backend.categoriesData.getByID(categoryId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  createCategory(categoryData: Category): Observable<Category> {
    return this.http.post<Category>(backend.categoriesData.post, categoryData).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  updateCategory(categoryId: number, updatedCategoryData: Category): Observable<Category> {
    return this.http.put<Category>(backend.categoriesData.updateById(categoryId), updatedCategoryData).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this.http.delete<void>(backend.categoriesData.deleteById(categoryId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
