import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../../helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { HResourceDataService } from '@services/resourceData.service';
import { UserManagementResponse } from '@interfaces/UserManagementResponse.interface';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { UserManagement } from '@interfaces/UserManagement.interface';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UserManagementService } from '@services/userManagement.service';
import { UserManagementDropdowns } from '@interfaces/UserManagementDropdowns.interface';
import Swal from "sweetalert2";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  userForm!: FormGroup;
  countriesMap: { [id: number]: string } = this.hResourceService.countries;
  regionsMap: { [id: number]: string } = this.hResourceService.regions;
  userId: string ='';
  isEditMode: boolean = false;
  dropdowndata!: UserManagementDropdowns;
  initialFormData: any | null = null;


  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}


  faEdit = faPen;
  faDelete = faTrashCan;
  faExit = faArrowLeftRotate;


  constructor(
    private fb: FormBuilder,
    private userManagementService: UserManagementService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceService: HResourceDataService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      locale: ['', Validators.required],
      roles: ['', Validators.required],
      localeId: ['', Validators.required],
      rolesId: [''],
      requiredActions: []
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog,
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.userForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.userId = id;
        this.userManagementService
         .getUserById(this.userId)
          .subscribe((response: UserManagement) => {
            this.userForm.patchValue(response);
            this.initialFormData = { ...response };
            this.breadcrumbService.set('@userName',`${response.firstName} ${response.lastName}`);
          });
      }
    });
    this.fetchDrowpdowns();
  }

  fetchDrowpdowns(): void {
    this.dataHelper.fetchItems(
      this.userManagementService.getDropdownData(),
      (response: UserManagementDropdowns) => {
        this.dropdowndata = response;
      }
    );
  }

  sendMail() {
    try {
      // Show loading here
      
      this.userManagementService.sendEmail(this.userId).subscribe(
        () => {
          console.log('Email sent successfully.');
          Swal.fire('Success', 'Email sent successfully.', 'success');
        },
        (error) => {
          console.error('Error sending email:', error);
          Swal.fire('Error', 'Failed to send email. Please try again later.', 'error');
        }
      );
    } catch (error) {
      console.error('Error in try block:', error);
    }
  }
  cancel() {
    if (this.userForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.userForm.patchValue(this.initialFormData);
    }
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.isEditMode = false;
  }
  onEdit(){
   this.isEditMode=true;
   const roleName = this.userForm.get('roles')?.value;
   const roleEntry = this.dropdowndata.roles.find(role => role.name === roleName);

   if (roleEntry) {
     this.userForm.get('rolesId')?.setValue(roleEntry.id);
   }

    this.userForm.get('localeId')?.setValue(this.userForm.get('locale')?.value);
  }

  onDelete(){
      this.dataHelper.withConfirmation(
        'Delete Confirmation',
        'Do you confirm the deletion of this user? Some changes may affect major functionalities',
        () => this.userManagementService.deleteUser(this.userId),
        'User deleted successfully',
        () => this.router.navigate(['settings/userManagement']),
        () => null
      );
    }


  exitView(){
    this.router.navigate(['settings/userManagement']);
  }

  setRoleName(event: any) {
    const selectedRoleId = event.target.value;
    const selectedRole = this.dropdowndata.roles.find(role => role.id === selectedRoleId);
    this.userForm.patchValue({ roles: selectedRole?.name });
  }



  onSubmit() {
    if(this.userForm.invalid){
      Object.values(this.userForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.userForm.getRawValue();

    const dataToSend = {
      userUpdate: {
        username: formValue.username || null,
        email: formValue.email || null,
        firstName: formValue.firstName || null,
        lastName: formValue.lastName || null,
        credentials: [
          {
            value: formValue.password || null,
          }
        ],
        attributes: {
          locale: formValue.localeId || null,
          phoneNumber: formValue.phoneNumber || null,
        }
      },
      roleInfo: {
        id: formValue.rolesId || null,
        name: formValue.roles || null,
      }
    };
    this.dataHelper.addNewRecord(
      this.userForm,
      dataToSend,
      (data) => this.userManagementService.updateUserById(this.userId,data),
      'User created successfully',
      'settings/userManagement'
    );
  }

}
