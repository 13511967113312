import { Component, OnInit } from '@angular/core';
import { OrderService } from '@services/order.service';
import { GetOrder } from '@interfaces/GetOrder.interface'; // Import GetOrder interface
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { OrderResponse } from '@interfaces/OrderResponse.interface'; // Import OrderResponse interface
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-orders', // Change selector to 'app-orders'
  templateUrl: './orders.component.html', // Change template URL
  styleUrls: ['./orders.component.css'], // Change style URLs
})
export class OrdersComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'date', header: this.translate.instant('Date'), transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'purchaseOrderNumber', header: this.translate.instant('Purchase Order#') },
    { prop: 'supplier', header: this.translate.instant('Supplier') },
    { prop: 'status', header: this.translate.instant('Status') },
    { prop: 'isReceived', header: this.translate.instant('Received') },
    { prop: 'isBilled', header: this.translate.instant('Billed') },
    { prop: 'total', header: this.translate.instant('Total') },
    { prop: 'expectedDeliveryDate', header: this.translate.instant('Expected Delivery Date'),transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
   
  ];
  

  constructor(
    private translate: TranslateService,
    private orderService: OrderService, // Change to OrderService
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  orders: GetOrder[] = [];
  filteredOrders = [...this.orders];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchOrders(); // Change to fetchOrders
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalOrders: number = 0;

  fetchOrders(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.orderService.getAllWithoutOrderLines(page, size), // Change to getOrders
      (response: OrderResponse) => {
        this.orders = response.items;
        this.totalOrders = response.totalItems;
        this.filteredOrders = [...this.orders];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchOrders(this.currentPage, this.pageSize);
  }

  deleteOrder(element: GetOrder): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this order? Some changes may affect major functionalities',
      () => this.orderService.deleteOrder(element.id), // Change to deleteOrder
      'Order deleted successfully',
      () => this.fetchOrders(),
      () => null
    );
  }

  addOrder() {
    this.router.navigate(['/purchases/orders/new_order']); // Change the route
  }

  editOrder(element: GetOrder): void {
    this.router.navigate(['/purchases/orders/view_order', element.id]); // Change the route
  }
}
