import { Component, OnInit } from '@angular/core';
import { CustomerService } from '@services/customer.service'; 
import { Customer } from '@interfaces/Customer.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { CustomersResponse } from '@interfaces/CustomerResponse.interfaece';
import { PaginationData } from '../../../helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-customers',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('Code') },
    { prop: 'firstName', header: this.translate.instant('First Name') },
    { prop: 'lastName', header: this.translate.instant('Last Name') },
    { prop: 'phoneNumber', header: this.translate.instant('Phone Number') },
    { prop: 'email', header: this.translate.instant('Email') },
    { prop: 'address', header: this.translate.instant('Address') },
];


  constructor(
    private translate: TranslateService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  customers: Customer[] = [];
  filteredCustomers = [...this.customers];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchCustomers();
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;  
  totalCustomers: number = 0;

  fetchCustomers(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.customerService.getCustomers(page, size), 
      (response: CustomersResponse) => {
        this.customers = response.items;
        this.totalCustomers = response.totalItems;
        this.filteredCustomers = [...this.customers];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; 
    this.pageSize = event.pageSize;
    this.fetchCustomers(this.currentPage, this.pageSize);
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredCustomers = this.customers.filter((customer) =>
      customer.firstName.toLowerCase().includes(filterValue) ||
      customer.lastName.toLowerCase().includes(filterValue) ||
      customer.address.toLowerCase().includes(filterValue) ||
      customer.phoneNumber.toLowerCase().includes(filterValue)
    );
  }

  deleteCustomer(element: Customer): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this customer? Some changes may affect major functionalities',
      () => this.customerService.deleteCustomer(element.id),
      'Customer deleted successfully',
      () => this.fetchCustomers(),
      () => null
    );
  }

  addCustomer() {
    this.router.navigate(['/sales/customers/new_customer']);
  }

  editCustomer(element: Customer): void {
    this.router.navigate(['/sales/customers/edit_customer', element.id]);
  }
}
