import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {DatePipe} from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EmployeeService} from "@services/employees.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {UserManagementService} from "@services/userManagement.service";
import { UserManagementDropdowns } from '@interfaces/UserManagementDropdowns.interface';
import { DataHelper } from '../../../../helpers/data-helper';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
  providers: [DatePipe],
})
export class NewUserComponent implements OnInit {
  userForm!: FormGroup;
  dropdowndata!: UserManagementDropdowns;

  constructor(
      private fb: FormBuilder,
      private userManagementService: UserManagementService,
      private datePipe: DatePipe,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private router: Router,
      private translate: TranslateService,

  ) {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      localeId: ['', Validators.required],
      rolesId: ['', Validators.required],
      roleName: ['']
    });
  }
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.userForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.fetchDrowpdowns();
  }

  fetchDrowpdowns(): void {
    this.dataHelper.fetchItems(
      this.userManagementService.getDropdownData(),
      (response: UserManagementDropdowns) => {
        this.dropdowndata = response;
      }
    );
  }

  setRoleName(event: any) {
    const selectedRoleId = event.target.value;
    const selectedRole = this.dropdowndata.roles.find(role => role.id === selectedRoleId);
    this.userForm.patchValue({ roleName: selectedRole?.name });
  }
  
  

  onSubmit() {
    if(this.userForm.invalid){
      Object.values(this.userForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.userForm.getRawValue();
  
    const dataToSend = {
      userRegistration: {
        username: formValue.username || null,
        email: formValue.email || null,
        firstName: formValue.firstName || null,
        lastName: formValue.lastName || null,
        credentials: [
          {
            value: formValue.password || null,
          }
        ],
        attributes: {
          locale: formValue.localeId || null,
          phoneNumber: formValue.phone || null,
        }
      },
      userRole: {
        id: formValue.rolesId || null,
        name: formValue.roleName || null,
      }
    };
      this.dataHelper.addNewRecord(
      this.userForm,
      dataToSend,
      (data) => this.userManagementService.registerUser(data),
      'User created successfully',
      '/settings/userManagement'
    );
  }
  
  

  cancel() {
    this.router.navigate(['/settings/userManagement']);
  }
}
