// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masters-form-group-smaller{
    margin-top: 10px;
}

.form-actions-gap{
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/masters/services/service-item/new-service-item/new-service-item.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".masters-form-group-smaller{\r\n    margin-top: 10px;\r\n}\r\n\r\n.form-actions-gap{\r\n    margin-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
