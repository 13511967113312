// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-section {
  padding-left: 50px;
  width: 800px;
}
.custom {
  justify-content: space-between;
}

.status-children {
  border-left: 3px solid green;
  padding-left: 20px;
}

.items-section table th {
  font-weight: bold !important;
}

.items-section table {
  width: 100%;
  border-collapse: collapse;
}

.items-section table th,
.items-section table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/purchases/orders/view-order/view-order.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".form-section {\r\n  padding-left: 50px;\r\n  width: 800px;\r\n}\r\n.custom {\r\n  justify-content: space-between;\r\n}\r\n\r\n.status-children {\r\n  border-left: 3px solid green;\r\n  padding-left: 20px;\r\n}\r\n\r\n.items-section table th {\r\n  font-weight: bold !important;\r\n}\r\n\r\n.items-section table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\n.items-section table th,\r\n.items-section table td {\r\n  border: 1px solid #000;\r\n  padding: 8px;\r\n  text-align: left;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
