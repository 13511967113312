
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LeftNavService } from '@services/left-nav.service';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';
import { Module } from '@interfaces/Module.interface';
import { Router } from '@angular/router';
import { faArrowTrendUp, faChevronDown, faChevronUp, faCircle, faDollarSign, faFileAlt, faFileLines, faGears, faHouse, faKey, faSuitcaseMedical, faUsers, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from '@services/category.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Category } from '@interfaces/Category.interface';


@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  userRole: string | null = null;

  iconMap: { [key: string]: IconProp } = {
    Dashboard: faHouse,
    Masters: faKey,
    'Human Resources': faUsers,
    'Services Invoices': faFileImage,
    Inventory: faWarehouse,
    Equipment: faSuitcaseMedical,
    Administrator: faArrowTrendUp,
    Sales: faArrowTrendUp,
    Purchases: faDollarSign,
    Reports: faFileLines,
    Settings: faGears
  };

  faCircle = faCircle;
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp
  categories!: Category[];


  private subscription: Subscription = new Subscription();
  leftNavData: Module[] = [];
  constructor(
    private userService: UserService,
    private leftNavService: LeftNavService,
    private router: Router,
    private translate: TranslateService,
    private categoryService: CategoryService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}



  ngOnInit() {
    this.fetchCategories();
    const currentPath = this.router.url;
    this.subscription.add(
      this.userService.userRole$.subscribe((role) => {
        this.userRole = role;
        this.leftNavService.getData().subscribe((data) => {
          this.leftNavData = this.initializeOpenState(data);
          this.leftNavData = this.sortModulesByOrder(this.leftNavData);
          this.openMenuForPath(currentPath);
        });
      })
    );
  }
  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  fetchCategories(): void {
    this.dataHelper.fetchItems(
      this.categoryService.getAll(),
      (data: Category[]) => {
        this.categories = data;
        console.log(this.categories)
      }
    );
  }

  removeWhitespace(value: string): string {
    return value.replace(/\s/g, '');
  }
  

  private sortModulesByOrder(data: Module[]): Module[] {
    return data.sort((a, b) => a.orderBy - b.orderBy)
      .map((item) => ({
        ...item,
        childModules: item.childModules
          ? this.sortModulesByOrder(item.childModules)
          : [],
      }));
  }

  toggleSubMenu(clickedItem: Module) {
    this.leftNavData = this.leftNavData.map((item) => {
      const isOpen = item === clickedItem ? !item.isOpen : false;
      return { ...item, isOpen };
    });
  }

  navigate(path: string){
    this.router.navigate([`/${path}`])
  }

  private openMenuForPath(path: string) {
    for (let item of this.leftNavData) {
      if (path.startsWith(`/${item.path}`)) {
        item.isOpen = true;

        if (item.childModules) {
          for (let child of item.childModules) {
              if (path === `/${item.path}/${child.path}`) {
                child.isOpen = true;
              }
              else{
                child.isOpen = false;
              }
          }
        }
      }
    }
  }

  isActive(item: Module): boolean {
    const currentPath = this.router.url;
    let urlString = item.path.replace(/\s/g, '%20');
    return currentPath.includes(`/${urlString}`);
  }


  private initializeOpenState(data: Module[]): Module[] {
    return data.map((item) => ({
      ...item,
      isOpen: false,
      childModules: item.childModules
        ? this.initializeOpenState(item.childModules)
        : [],
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
