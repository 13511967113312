import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { UserManagement } from '@interfaces/UserManagement.interface';
import { PostUserManagementInterface } from '@interfaces/PostUserManagement.interface';
import { UserManagementResponse } from '@interfaces/UserManagementResponse.interface';
import { UserManagementDropdowns } from '@interfaces/UserManagementDropdowns.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {}

  getUsers(
    pageNumber: number,
    pageSize: number
  ): Observable<UserManagementResponse> {
    return this.http
      .get<UserManagementResponse>(
        backend.userManagement.getAll(pageNumber, pageSize)
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  registerUser(user: PostUserManagementInterface): Observable<any> {
    return this.http
      .post(backend.userManagement.post, user, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteUser(userId: string): Observable<any> {
    return this.http
      .delete(`${backend.userManagement.deleteById(userId)}`, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
}


  getUserById(userId: string): Observable<UserManagement> {
    return this.http
      .get<UserManagement>(`${backend.userManagement.getById(userId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateUserById(userId: string, user: UserManagement): Observable<any> {
    return this.http
      .put(backend.userManagement.updateById(userId), user, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  sendEmail(userId: string): Observable<any> {
    return this.http
      .put(backend.userManagement.sendMailById(userId),{}, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getDropdownData(): Observable<UserManagementDropdowns> {
    return this.http
      .get<UserManagementDropdowns>(`${backend.userManagement.getDropdownData}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
