import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { OrderService } from '@services/order.service';
import { BillLines } from '@interfaces/BillLines.interface';
import { BillService } from '@services/bill.service';
import { Bill } from '@interfaces/Bill.interface';
import { ExtendedPurchaseReiceve } from '@interfaces/ExtendedPurchaseReiceive.interface';
import { ExtendedPurchaseReceiveItem } from '@interfaces/ExtendedPurchaseReceiveItem.interface';
import { GetOrder } from '@interfaces/GetOrder.interface';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-bill-receive',
  templateUrl: './new-bill.component.html',
  styleUrls: ['./new-bill.component.css'],
})
export class NewBillComponent implements OnInit {
  billForm!: FormGroup;
  selectedSupplierId!: number;
  order!: GetOrder;

  billLinesDataSource: MatTableDataSource<BillLines>;
  purchaseReceives: ExtendedPurchaseReiceve[] = [];
  selectedReceive: ExtendedPurchaseReiceve | null;
  selectedPurchaseReceiveId!: number;
  selectedPurchaseReceiveItems: ExtendedPurchaseReceiveItem[] = [];

  today = new Date();
  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  wrongQuantities: boolean = true;

  totalAmount: number = 0;
  balanceDue: number = 0;

  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private billService: BillService,
    private orderService: OrderService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private tenantSettingsService: TenantSettingsService
  ) {
    this.billLinesDataSource = new MatTableDataSource<BillLines>([]);
    this.initializeForm();
    this.selectedReceive = null;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.billForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.billForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.loadDataMaps();
    this.loadAutoIncreamentNumber();
  }

  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
  }

  loadAutoIncreamentNumber(): void {
    //'4' represents the bills prefix in the lookup table of the backend (accountant_scopes)
    this.tenantSettingsService.getAutoIncreamentNumbersData(5)
      .subscribe((response: string) => {
        this.billForm.patchValue({ 
          billNumber: response
        });
      }, error => {
        console.error('Failed to load invoice number:', error);
      });
  }

  get billLines() {
    return this.billForm.get('billLines') as FormArray;
  }

  getProductNamesByOrderItemId(orderItemId: number): string {
    if (!this.order || !this.order.orderItems) {
      return 'Product Not Found';
    }

    const orderItem = this.order.orderItems.find(
      (item) => item.id === orderItemId
    );
    if (orderItem) {
      return orderItem.product;
    } else {
      return 'Product Not Found';
    }
  }

  initializeForm() {
    this.billForm = this.fb.group({
      billNumber: [{ value: '', disabled: true }, Validators.required],
      supplierId: ['', Validators.required], // supplierId remains enabled
      orderId: [{ value: '', disabled: true }],
      billDate: [{ value: '', disabled: true }, Validators.required],
      dueDate: [{ value: '', disabled: true }, Validators.required],
      notes: [{ value: '', disabled: true }],
      paymentTerms: [{ value: '', disabled: true }],
      balanceDue: [{ value: '', disabled: true }],
      ammount: [{ value: '', disabled: true }],

      billLines: [
        { value: this.fb.array([]), disabled: true },
        Validators.required,
      ],
    });
  }

  fetchPurchaseReceives(supplierId: number = this.selectedSupplierId): void {
    this.dataHelper.fetchItems(
      this.billService.getUnBilledPurchaseReceivesBySupplier(supplierId),
      (response: ExtendedPurchaseReiceve[]) => {
        this.purchaseReceives = response;
        if (this.purchaseReceives.length === 1) {
          this.selectedPurchaseReceiveId = this.purchaseReceives[0].id;
          this.onPurchaseReceiveChange();
        }
        this.updateTotalsAfterDataFetch();
      }
    );
  }

  updateTotalsAfterDataFetch() {
    this.totalAmount = this.selectedPurchaseReceiveItems.reduce(
      (sum, item) => sum + item.ammount,
      0
    );
    this.balanceDue = this.totalAmount;
    this.cdr.detectChanges();
  }

  onSupplierSelected() {
    if (this.selectedSupplierId) {
      this.billForm.enable(); // Enable all form controls
      this.billForm.get('supplierId')?.enable(); // Ensure customer ID field remains enabled
      this.fetchPurchaseReceives();
    } else {
      this.billForm.disable(); // Disable all form controls
      this.billForm.get('supplierId')?.enable(); // Ensure customer ID field remains enabled
    }
  }

  onPurchaseReceiveChange() {
    this.selectedReceive =
      this.purchaseReceives.find(
        (receive) => receive.id === this.selectedPurchaseReceiveId
      ) || null;
    if (this.selectedReceive && this.selectedReceive != null) {
      this.selectedPurchaseReceiveItems =
        this.selectedReceive.purchaseReceivedItems; // Assuming each receive has an `items` property
    }
  }

  updateTotals() {
    this.totalAmount = this.selectedPurchaseReceiveItems.reduce((sum, item) => {
      const taxInput =
        (document.getElementById(`tax-${item.id}`) as HTMLInputElement) || 0;
      const taxPercentage = taxInput ? parseFloat(taxInput.value) / 100 : 0;
      return sum + item.ammount + item.ammount * taxPercentage;
    }, 0);
    this.balanceDue = this.totalAmount; // Since balance due is equal to total amount
  }

  updateTax(taxValue: string, item: ExtendedPurchaseReceiveItem) {
    const tax = parseFloat(taxValue);
    if (isNaN(tax) || tax < 0) {
      Swal.fire({
        title: 'Invalid Tax',
        text: 'Tax must be a positive number',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      const taxInput = document.getElementById(
        `tax-${item.id}`
      ) as HTMLInputElement;
      if (taxInput) {
        taxInput.value = '0'; // Reset to 0 if the value is not valid
      }
    } else {
      this.updateTotals();
    }
  }

  onSubmit() {
    if(this.billForm.invalid){
      Object.values(this.billForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.billForm.getRawValue();
    // Initialize an empty array to store billLines
    const billLines: BillLines[] = [];
    // Iterate through receivedOrderItems and gather the input values
    for (const item of this.selectedPurchaseReceiveItems) {
      const taxInput = document.getElementById(
        `tax-${item.id}`
      ) as HTMLInputElement;
      const taxDto = parseFloat(taxInput.value) || 0;
      // Create an item object based on the input values
      const billLine: BillLines = {
        billId: 0,
        orderItemId: item.orderItemId,
        item: this.getProductNamesByOrderItemId(item.orderItemId) || 'empty',
        quantity: item.receivedQuantity,
        ammount: item.ammount,
        rate: item.rate,
        tax: taxDto, 
      };
      // Push the item object to the billLines array
      billLines.push(billLine);
    }
    // Create the billData object with the gathered data
    const billData: Bill = {
      id: 0,
      billNumber: formValue.billNumber || '',
      orderId: this.selectedReceive?.orderId || 0,
      billDate: new Date(formValue.billDate).toISOString(),
      dueDate: new Date(formValue.dueDate).toISOString(),
      paymentTerms: formValue.paymentTerms,
      notes: formValue.notes,
      balanceDue: this.balanceDue,
      ammount: this.totalAmount,
      status: '',
      billLines: billLines,
    };

    console.log(billData);

    this.billService.addBill(billData)
      .subscribe(
        () => {
            //'5' represents the invoice prefix in the lookup table of the backend (accountant_scopes)
            this.tenantSettingsService.updatAutoIncreamentNumbersData(5).subscribe({
              next: (updateResponse) => {
                Swal.fire('Success', 'Bill created successfully.', 'success')
                .then(() => {
                  this.billForm.markAsPristine();
                  this.billForm.markAsUntouched();
                  this.billForm.reset();
                  this.router.navigate(['/purchases/bills'])
                });              
              },
              error: (updateError) => {
                Swal.fire('Error', updateError , 'error')              
              }
            });
        },
        error => Swal.fire('Error', error , 'error')
      );
  }

  onCancel(): void {
    this.router.navigate(['/purchases/bills']);
  }
}
