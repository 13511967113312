import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '@services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../../helpers/data-helper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faArrowLeftRotate,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { OrderService } from '@services/order.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { NewServiceItemComponent } from '../service-item/new-service-item/new-service-item.component';
import { UdpateServiceItemComponent } from '../service-item/udpate-service-item/udpate-service-item.component';
import { ServiceItem } from '@interfaces/ServiceItem.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Service } from '@interfaces/Service.interface';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css'],
})
export class EditServiceComponent implements OnInit {
  serviceForm!: FormGroup;
  serviceId!: number;
  serviceName: string = '';
  serviceDescription: string = '';
  serviceCharge: number = 0;
  serviceTax: number = 0;
  faEdit = faPen;
  faDelete = faTrashCan;
  faExit = faArrowLeftRotate;
  isEditMode: boolean = false;
  productsMap: { [id: number]: any } = {};
  categoriesMap: { [id: number]: any } = {};
  filteredProductsMap: { [lineIndex: number]: any[] } = {};
  serviceItemsDataSource: MatTableDataSource<ServiceItem>;
  originalServiceItems!: any[];
  initialFormData: Service | null = null;


  constructor(
    private fb: FormBuilder,
    private serviceService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private orderService: OrderService,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.serviceItemsDataSource = new MatTableDataSource<ServiceItem>([]);
    this.serviceForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      charge: ['', Validators.required],
      tax: ['', Validators.required],
      serviceItems: this.fb.array([]),
    });
  }

  get serviceItems() {
    return this.serviceForm.get('serviceItems') as FormArray;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.serviceForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.serviceForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.productsMap = this.orderService.products;
    this.categoriesMap = this.serviceInvoiceDataService.categories;
    this.route.paramMap.subscribe((params) => {
      this.serviceId = Number(params.get('id')); // Extract serviceId from the route

      if (!isNaN(this.serviceId)) {
        this.serviceService.getById(this.serviceId).subscribe((service) => {
          if (Array.isArray(service) && service.length > 0) {
            // If service is an array, get the name from the first element of the array
            this.serviceName = service[0].name;
            this.serviceDescription = service[0].description;
            this.serviceCharge = service[0].charge;
            this.serviceTax = service[0].tax;
            this.serviceForm.patchValue({ name: this.serviceName }); // Prefill the "Name" field
            this.serviceForm.patchValue({
              description: this.serviceDescription,
            }); // Prefill the "Name" field
            this.serviceForm.patchValue({ charge: this.serviceCharge }); // Prefill the "Name" field
            this.serviceForm.patchValue({ tax: this.serviceTax }); // Prefill the "Name" field
            this.initialFormData = { ...service[0] };
            if (service[0].serviceItems) {
              this.serviceItems.clear();
              service[0].serviceItems.forEach((item: any,index: number) => {
                item.categoryId = this.productsMap[item.productId].categoryId
                let serviceItemsFormGroup = this.fb.group({
                  categoryId: [item.categoryId, Validators.required],
                  productId: [item.productId, [Validators.min(0)]],
                  quantity: [item.quantity, [Validators.required, Validators.min(1)]],
                });
                this.loadProducts(item.categoryId,index)
                this.serviceItems.push(serviceItemsFormGroup);
              });
            }
          } else if (service) {
            this.serviceName = service.name;
            this.serviceDescription = service.description;
            this.serviceCharge = service.charge;
            this.serviceTax = service.tax;
            this.serviceForm.patchValue({ name: this.serviceName }); // Prefill the "Name" field
            this.serviceForm.patchValue({
              description: this.serviceDescription,
            }); // Prefill the "Name" field
            this.serviceForm.patchValue({ charge: this.serviceCharge }); // Prefill the "Name" field
            this.serviceForm.patchValue({ tax: this.serviceTax }); // Prefill the "Name" field
            this.initialFormData = { ...service };
            if (service.serviceItems) {
              this.serviceItems.clear();
              service.serviceItems.forEach((item: any, index: number) => {
                item.categoryId = this.productsMap[item.productId].categoryId
                let serviceItemsFormGroup = this.fb.group({
                  categoryId: [item.categoryId, Validators.required],
                  productId: [item.productId, [Validators.min(0)]],
                  quantity: [item.quantity, [Validators.required, Validators.min(1)]],
                });
                this.loadProducts(item.categoryId,index)
                this.serviceItems.push(serviceItemsFormGroup);
              });
            }
          }
          this.breadcrumbService.set('@serviceName', this.serviceName);
          if(this.isEditMode){
            this.serviceItems.push(this.createServiceItem());
          }
        });
      } else {
        console.error('Invalid serviceId');
      }
    });
  }

  createServiceItem(): FormGroup {
    const serviceLineFormGroup = this.fb.group({
      categoryId: ['0'],
      productId: [{ value: '0', disabled: true }, Validators.required],
      quantity: [
        { value: '1', disabled: true },
        [Validators.required, Validators.min(1)],
      ],
    });
    return serviceLineFormGroup;
  }

  addServiceLine(): void {
    this.serviceItems.push(this.createServiceItem());
  }

  loadProducts(selectedCategoryId: number, lineIndex: number) {
    this.dataHelper.fetchItems(
      this.serviceService.getProductsByCategory(selectedCategoryId),
      (data: any) => {
        if (data && data.products) {
          // Update the filtered products for this line
          this.filteredProductsMap[lineIndex] = data.products;
          console.log(
            'Filtered products for line',
            lineIndex,
            ':',
            data.products
          );
          this.cdr.detectChanges();
        }
      }
    );
  }

  onCategorySelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const categoryId = selectElement.value;
    const selectedCategory = this.categoriesMap[+categoryId];

    if (selectedCategory) {
      this.serviceItems.at(index).patchValue({
        categoryId: categoryId,
      });
      this.loadProducts(+categoryId, index);

      // Enable other controls
      this.serviceItems.at(index).get('productId')?.enable();
      this.serviceItems.at(index).get('quantity')?.enable();
    }
  }

  onProductSelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;
    const selectedProduct = this.productsMap[+productId];
    if (selectedProduct) {
      this.serviceItems.at(index).patchValue({
        serviceId: productId,
        quantity: 1, // Default
      });
      if (index === this.serviceItems.length - 1) {
        this.addServiceLine();
      }
    }
  }

  removeServiceItem(index: number): void {
    // Only remove if more than one invoice line exists
    if (this.serviceItems.length > 1) {
      this.serviceItems.removeAt(index);
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.serviceItemsDataSource.data = this.serviceItems.controls.map(
      (control) => control.value
    );
  }

  onSubmit() {
    if(!this.serviceForm.valid){
      Object.values(this.serviceForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.serviceForm.valid) {
      const formValue = this.serviceForm.getRawValue();
      // the first row of invoice lines is always the template row which we dont want to be put in the database
      if (this.serviceItems.length === 1) {
        formValue.serviceItems = [];
      } else {
        // Exclude the last row from submission if length is bigger than 1 because the last is always the template row in this case
        formValue.serviceItems = formValue.serviceItems.slice(0, -1);
      }      const dataToSend = {
        id: this.serviceId,
        name: formValue.name || 'string',
        description: formValue.description || 'string',
        charge: formValue.charge || Number,
        tax: formValue.tax || Number,
        serviceItems: formValue.serviceItems || null
      };
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this service? Some changes may affect major functionalities',
        () => this.serviceService.updateService(this.serviceId, dataToSend),
        'service updated successfully',
        () => {
          this.serviceForm.markAsPristine();
          this.serviceForm.markAsUntouched();
          this.serviceForm.reset();
          this.router.navigate(['/masters/services']);
        }
      );
    }
  }

  resetServiceItemsToOriginalState() {
    // Clear the current form array without affecting the original state.
    this.serviceItems.clear();
    // Repopulate the form array with the original items.
    this.originalServiceItems.forEach(item => {
      const group = this.fb.group({
        categoryId: [item.categoryId, Validators.required],
        productId: [item.productId, Validators.required],
        quantity: [item.quantity, [Validators.required, Validators.min(1)]],
      });
      this.serviceItems.push(group);
    });
  }

  cancel() {
    if (this.serviceForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.serviceForm.patchValue(this.initialFormData);
    }
    this.serviceForm.markAsPristine();
    this.serviceForm.markAsUntouched();
    this.isEditMode = false;
    if (this.serviceItems.length > 0) {
      // Remove the last item from the array
      this.serviceItems.removeAt(this.serviceItems.length - 1);
    }
    this.resetServiceItemsToOriginalState();
  }


  exitView() {
    this.router.navigate(['/masters/services']);
  }

  onEdit() {
    this.isEditMode = true;
    this.originalServiceItems = this.serviceItems.controls.map(control => ({
      ...control.value
    }));
    this.serviceItems.push(this.createServiceItem());
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this service? Some changes may affect major functionalities',
      () => this.serviceService.deleteService(this.serviceId),
      'Service deleted successfully',
      () => this.router.navigate(['/masters/services']),
      () => null
    );
  }
}
