import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentDataService } from '@services/paymentData.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../../helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment-type.component.html',
  styleUrls: ['./new-payment-type.component.css'],
})
export class NewPaymentTypeComponent implements OnInit {
  paymentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private paymentService: PaymentDataService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.paymentForm = this.fb.group({
      name: ['', Validators.required],
    });
  }
  
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.paymentForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.paymentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {}

  onSubmit() {
    if(!this.paymentForm.valid){
      Object.values(this.paymentForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.paymentForm.getRawValue();
    const dataToSend = {
      name: formValue.name || 'string',
    };

    this.dataHelper.addNewRecord(
      this.paymentForm,
      dataToSend,
      (data) => this.paymentService.createPayment(data),
      'Payment created successfully',
      '/masters/paymentTypes'
    );
  }

  cancel() {
    this.router.navigate(['/masters/paymentTypes']);
  }
}
