import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Department } from '@interfaces/Department.interface';
import { backend } from '@apis/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  constructor(private http: HttpClient) {}

  getDepartment(
    departmentId: number | null
  ): Observable<Department | Department[]> {
    if (!departmentId) {
      return this.http.get<Department[]>(backend.departmentData.getAll).pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
    }
    return this.http
      .get<Department>(backend.departmentData.get(departmentId))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  createDepartment(department: any): Observable<any> {
    return this.http
      .post(backend.departmentData.post, department, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateDepartment(
    departmentId: number | null,
    department: any
  ): Observable<any> {
    return this.http
      .put(backend.departmentData.put(departmentId), department, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteDepartment(departmentId: number | null): Observable<void> {
    return this.http
      .delete<void>(backend.departmentData.delete(departmentId))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
