import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Vat } from '@interfaces/Vat.interface';
import { TranslateService } from '@ngx-translate/core';
import { VatService } from '@services/vat.service';
import { Observable } from 'rxjs';
import { DataHelper } from 'src/app/helpers/data-helper';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-edit-vat',
  templateUrl: './edit-vat.component.html',
  styleUrls: ['./edit-vat.component.css']
})
export class EditVatComponent {
  vatForm!: FormGroup;
  vatId!: number;
  vatName: string = '';
  vatValue: number = 0;
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: Vat | null = null;


  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  constructor(
    private fb: FormBuilder,
    private vatService: VatService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.vatForm = this.fb.group({
      name: ['', Validators.required],
      value: ['', [Validators.required,Validators.min(0),Validators.max(100)]],
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.vatForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.vatForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.vatId = Number(params.get('id')); // Extract vatId from the route

      if (!isNaN(this.vatId)) {
        this.vatService
          .getById(this.vatId)
          .subscribe((vat) => {
            console.log(vat)
            if (Array.isArray(vat) && vat.length > 0) {
              // If currency is an array, get the name from the first element of the array
              this.vatName = vat[0].name;
              this.vatValue = vat[0].value;
              this.vatForm.patchValue({ name: this.vatName, value: this.vatValue }); // Prefill the "Name" field
              this.initialFormData = { ...vat[0] };
              
            } else if (vat) {
              // If currency is a single object, get the name from it
              this.vatName = vat.name;
              this.vatValue = vat.value;
              this.vatForm.patchValue({ name: this.vatName }); // Prefill the "Name" field
              this.initialFormData = { ...vat };

            }
            this.breadcrumbService.set('@vatName', this.vatName);
          });
      } else {
        console.error('Invalid vatId');
      }
    });
  }

  onSubmit() {
    if(!this.vatForm.valid){
      Object.values(this.vatForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.vatForm.valid) {
      const updatedVat = this.vatForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this vat? Some changes may affect major functionalities',
        () =>
          this.vatService.updateVat(this.vatId, updatedVat),
        'Vat updated successfully',
        () => {
          this.vatForm.markAsPristine();
          this.vatForm.markAsUntouched();
          this.vatForm.reset();
          this.router.navigate(['/masters/vats']);
        }
      );
    }
  }

  cancel() {
    if (this.vatForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.vatForm.patchValue(this.initialFormData);
    }
    this.vatForm.markAsPristine();
    this.vatForm.markAsUntouched();
    this.isEditMode = false;
  }




  exitView(){
    this.router.navigate(['/masters/vats']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this vat? Some changes may affect major functionalities',
      () => this.vatService.deleteVat(this.vatId),
      'currency deleted successfully',
      () => this.router.navigate(['/masters/vats']),
      () => null
    );
  }
}
