import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  template: `
    <h1 mat-dialog-title>{{'Error' | translate}}</h1>
    <div mat-dialog-content>
      <p>{{ data.error.message }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>{{'Close' | translate}}</button>
    </div>
  `,
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { error: any }) { }
}
