import { Component, OnInit } from '@angular/core';
import { SupplierService } from '@services/supplier.service';
import { Supplier } from '@interfaces/Supplier.interface'; 
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { SuppliersResponse } from '@interfaces/SupplierResponse.interface'; 
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { GetSupplier } from '@interfaces/GetSupplier.interface';

@Component({
  selector: 'app-suppliers',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css'], 
})
export class SupplierComponent implements OnInit {
  displayedColumns: Column[] = [
    // { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'contactName', header: this.translate.instant('Contact Name') },
    { prop: 'contactTitle', header: this.translate.instant('Contact Title') },
    { prop: 'companyName', header: this.translate.instant('Company Name') },
    { prop: 'phone', header: this.translate.instant('Phone') },
    { prop: 'email', header: this.translate.instant('Email') },
    { prop: 'website', header: this.translate.instant('Website') },
    // { prop: 'city', header: this.translate.instant('City') },
    // { prop: 'region', header: this.translate.instant('Region') },
    // { prop: 'country', header: this.translate.instant('Country') },
    // { prop: 'postalCode', header: this.translate.instant('Postal Code') }, 

  ];

  constructor(
    private translate: TranslateService,
    private supplierService: SupplierService, 
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  suppliers: GetSupplier[] = [];
  filteredSuppliers = [...this.suppliers];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchSuppliers(); 
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalSuppliers: number = 0; 

  fetchSuppliers(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.supplierService.getSuppliers(page, size), 
      (response: SuppliersResponse) => { 
        this.suppliers = response.items; 
        this.totalSuppliers = response.totalItems; 
        this.filteredSuppliers = [...this.suppliers];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchSuppliers(this.currentPage, this.pageSize);
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();
  
    this.filteredSuppliers = this.suppliers.filter((supplier) => 
      supplier.contactName.toLowerCase().includes(filterValue) ||
      supplier.contactTitle.toLowerCase().includes(filterValue) ||
      supplier.city.toLowerCase().includes(filterValue) ||
      supplier.country.toLowerCase().includes(filterValue) ||
      supplier.email.toLowerCase().includes(filterValue) ||
      supplier.phone.toLowerCase().includes(filterValue) ||
      supplier.region.toLowerCase().includes(filterValue) ||
      supplier.website.toLowerCase().includes(filterValue) ||
      supplier.postalCode.toLowerCase().includes(filterValue)
    );
  }

  deleteSupplier(element: GetSupplier): void { 
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this supplier? Some changes may affect major functionalities',
      () => this.supplierService.deleteSupplier(element.id),
      'Supplier deleted successfully',
      () => this.fetchSuppliers(), 
      () => null
    );
  }

  addSupplier() {
    this.router.navigate(['/purchases/suppliers/new_supplier']);
  }

  editSupplier(element: GetSupplier): void { 
    this.router.navigate(['/purchases/suppliers/view_supplier', element.id]);
  }
}
