// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-select-cell,
.table td select,
.table td textarea {
  min-width: 0 !important;
  max-width: 150px !important;
}

.table-cell, .table td input{
  min-width: 80px !important;
  max-width: 100px !important;
}

.table-input,.table-input-discount{
  border: 1px solid transparent !important;
}

.table-input:disabled {
  cursor: not-allowed ;
}


.table-input:hover,.bottom-textarea:hover,.bottom-textarea:focus,.table-input-discount:hover{
  border: 1px solid #76c3bc !important;
}

select{
  cursor: pointer;
}

ul{
  list-style: none;
}

.totals-section {
  float: right;
  margin-bottom: 40px;
}

.details-section {
  float: left;
}

.form-row {
  margin-bottom: 20px;
}
.discount-group{
  display: flex;
  flex-direction: row;
}
.table-input-discount {
  width: auto !important;
}
.red-border {
  border: 2px solid red;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/sales/invoices/new-invoice/new-invoice.component.css"],"names":[],"mappings":"AAAA;;;EAGE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,oBAAoB;AACtB;;;AAGA;EACE,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".table-select-cell,\r\n.table td select,\r\n.table td textarea {\r\n  min-width: 0 !important;\r\n  max-width: 150px !important;\r\n}\r\n\r\n.table-cell, .table td input{\r\n  min-width: 80px !important;\r\n  max-width: 100px !important;\r\n}\r\n\r\n.table-input,.table-input-discount{\r\n  border: 1px solid transparent !important;\r\n}\r\n\r\n.table-input:disabled {\r\n  cursor: not-allowed ;\r\n}\r\n\r\n\r\n.table-input:hover,.bottom-textarea:hover,.bottom-textarea:focus,.table-input-discount:hover{\r\n  border: 1px solid #76c3bc !important;\r\n}\r\n\r\nselect{\r\n  cursor: pointer;\r\n}\r\n\r\nul{\r\n  list-style: none;\r\n}\r\n\r\n.totals-section {\r\n  float: right;\r\n  margin-bottom: 40px;\r\n}\r\n\r\n.details-section {\r\n  float: left;\r\n}\r\n\r\n.form-row {\r\n  margin-bottom: 20px;\r\n}\r\n.discount-group{\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n.table-input-discount {\r\n  width: auto !important;\r\n}\r\n.red-border {\r\n  border: 2px solid red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
