import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@services/product.service'; // Adjust the import as per your project structure
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { HResourceDataService } from '@services/resourceData.service';
import { InventoryDataService } from '@services/inventoryData.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css'],
})
export class NewProductComponent implements OnInit {
  productForm!: FormGroup;
  brandMap: { [id: number]: string } = this.inventorDataService.brands;
  categoryMap: { [id: number]: string } = this.inventorDataService.categories;
  departmentMap: { [id: number]: string } = this.hResourceDataService.departments;
  categoryName: string | null = null;
  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceDataService: HResourceDataService,
    private inventorDataService: InventoryDataService,
    private route: ActivatedRoute,
  ) {
    this.productForm = this.fb.group({
      code: [''],
      serialNumber: ['', Validators.required],
      name: ['', Validators.required],
      details: [''],
      model: [''],
      price: [''],
      retailPrice: [''],
      inStock: ['', Validators.required],
      isProductActive: [true],
      brandId: ['', Validators.required],
      categoryId: ['', Validators.required],
      departmentId: ['', Validators.required],
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.productForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.productForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.categoryName = params.get('categoryName');
    });
  }

  onSubmit() {
    if(this.productForm.invalid){
      Object.values(this.productForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.productForm.getRawValue();
    const dataToSend = {
      code: formValue.code || 'string',
      serialNumber: formValue.serialNumber || 'string',
      name: formValue.name || 'string',
      details: formValue.details || 'string',
      model: formValue.model || 'string',
      price: formValue.price || 0,
      retailPrice: formValue.retailPrice || 0,
      inStock: formValue.inStock || 0,
      isProductActive: formValue.isProductActive || false,
      brandId: formValue.brandId || 0,
      categoryId: formValue.categoryId || 0,
      departmentId: formValue.departmentId || 0,
    };

    this.dataHelper.addNewRecord(
      this.productForm,
      dataToSend,
      (data) => this.productService.addProduct(data),
      'Product created successfully',
      `/inventory/products/${this.categoryName}`
    );
  }

  cancel() {
    this.router.navigate([`/inventory/products/${this.categoryName}`]);
  }
}
