import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { BillResponse } from '@interfaces/BillResponse.interface';
import { Bill } from '@interfaces/Bill.interface';
import { ExtendedPurchaseReiceve } from '@interfaces/ExtendedPurchaseReiceive.interface';

@Injectable({
  providedIn: 'root',
})
export class BillService {
  constructor(private http: HttpClient) {}

  getBills(
    pageNumber: number,
    pageSize: number
  ): Observable<BillResponse> {
    return this.http
      .get<BillResponse>(backend.bills.getAll(pageNumber, pageSize))
      .pipe(
        catchError((err) => {
          console.error(err.error);
         
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addBill(bill: Bill): Observable<any> {
    return this.http
      .post(backend.bills.post, bill, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteBill(billId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.bills.deleteById(billId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getBillById(billId: number): Observable<Bill> {
    return this.http
      .get<Bill>(`${backend.bills.getById(billId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getUnBilledPurchaseReceivesBySupplier(supplierId: number): Observable<ExtendedPurchaseReiceve[]> {
    return this.http
      .get<ExtendedPurchaseReiceve[]>(`${backend.bills.getReicevesBySupplier(supplierId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateBillById(billId: number, bill: Bill): Observable<any> {
    return this.http
      .put(backend.bills.updateById(billId), bill, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
