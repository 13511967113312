import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { PurchaseReceiveService } from '@services/purchaseReceive.service';
import { GetOrder } from '@interfaces/GetOrder.interface';
import { PurchaseReceive } from '@interfaces/PurchaseReceive.interface';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseReceivesItem } from '@interfaces/PurchaseReceivesItem.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '@services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-purchase-receives',
  templateUrl: './edit-purchase-receives.component.html',
  styleUrls: ['./edit-purchase-receives.component.css'],
})
export class EditPurchaseReceivesComponent implements OnInit {
  purchaseReceiveForm: FormGroup;
  purchaseReceivedItemsDataSource: MatTableDataSource<PurchaseReceivesItem>;
  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  wrongQuantities: boolean = true;
  isEditMode: boolean = false;
  pruchaseReceiveId: number = 0;
  purchaseReceive!: PurchaseReceive;
  order!: GetOrder;
  initialFormData: PurchaseReceive | null = null;


  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private purchaseReceiveService: PurchaseReceiveService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private orderService: OrderService,
    private dialog: MatDialog,

  ) {
    this.purchaseReceivedItemsDataSource =
      new MatTableDataSource<PurchaseReceivesItem>([]);
    this.purchaseReceiveForm = this.fb.group({
      purchaseReceivedNumber: ['',Validators.required],
      supplierId: [''],
      orderId: [''],
      receivedDate: [ '', Validators.required],
      internalNotes: [ ''],
      purchaseReceivedItems: [
         this.fb.array([]),
        Validators.required,
      ],
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.purchaseReceiveForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.purchaseReceiveForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.fetchPurchaseReceive();
  }

  fetchPurchaseReceive(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.pruchaseReceiveId = +id;
        this.purchaseReceiveService
          .getPurchaseReceiveById(this.pruchaseReceiveId)
          .subscribe((response) => {
            response.receivedDate = this.dataHelper.formatDate(response.receivedDate)
            this.purchaseReceive = response;
            this.purchaseReceiveForm.patchValue(response);
            this.initialFormData = { ...response };
            this.breadcrumbService.set(
              '@purchaseReceiveNumber',
              this.purchaseReceive.purchaseReceivedNumber
            );
            this.loadDataMaps();
            this.fetchOrder();
          });
      }
    });
  }

  getProductNamesByOrderItemId(orderItemId: number): string {
    if (!this.order || !this.order.orderItems) {
      return 'Product Not Found';
    }

    const orderItem = this.order.orderItems.find(
      (item) => item.id === orderItemId
    );
    if (orderItem) {
      return orderItem.product;
    } else {
      return 'Product Not Found';
    }
  }

  getQuantityByOrderItemId(orderItemId: number): string {
    if (!this.order || !this.order.orderItems) {
      return 'Quantity Not Found';
    }

    const orderItem = this.order.orderItems.find(
      (item) => item.id === orderItemId
    );
    if (orderItem) {
      return orderItem.quantity.toString();
    } else {
      return 'Product Not Found';
    }
  }

  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
  }

  get purchaseReceivedItems() {
    return this.purchaseReceiveForm.get('purchaseReceivedItems') as FormArray;
  }

  fetchOrder(): void {
    this.dataHelper.fetchItems(
      this.orderService.getByIdWithOrderLines(this.purchaseReceive.orderId),
      (response: GetOrder) => {
        this.order = response;
      }
    );
  }

  updateItemQuantities(itemId: number, event: any) {
    const inputValue = (event.target as HTMLInputElement).value;
    const quantityToReceive = parseFloat(inputValue);
    if(!quantityToReceive){
      this.wrongQuantities = true;
      Swal.fire(
        'Error',
        'Received quantities cannot be empty',
        'error'
      );
      return;
    }
    if (itemId) {
      const pr_item = this.purchaseReceive.purchaseReceivedItems.find((i) => i.id === itemId);
      const order_item = this.order.orderItems?.find((i) => i.id === pr_item?.orderItemId);

      if (pr_item && quantityToReceive && order_item) {
        const remainingQuantity = order_item?.quantity - pr_item.receivedQuantity;
        if (quantityToReceive < 1) {
            this.wrongQuantities = true;
            Swal.fire(
              'Error',
              'Received quantities cannot be less than 1',
              'error'
            );
            return;
        }
        if (quantityToReceive > remainingQuantity) {
          this.wrongQuantities = true;
          Swal.fire(
            'Error',
            'Received quantities cannot exceed remaining quantity.',
            'error'
          );
          return;
        }
        this.wrongQuantities = false;
      }
    }
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  onSubmit() {
    if(this.purchaseReceiveForm.invalid || this.wrongQuantities){
      Object.values(this.purchaseReceiveForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.purchaseReceiveForm.getRawValue();
    const purchaseReceivedItems: any[] = [];

    for (const item of this.purchaseReceive.purchaseReceivedItems) {
      const quantityToReceiveInput = document.getElementById(
        `quantityToReceive-${item.id}`
      ) as HTMLInputElement;

      const quantityToReceive = parseFloat(quantityToReceiveInput.value) || 0;

      const purchaseReceivedItem = {
        id: 0,
        receivedQuantity: quantityToReceive,
        orderItemId: item.orderItemId,
      };

      purchaseReceivedItems.push(purchaseReceivedItem);
    }

    const purchaseReceiveData: PurchaseReceive = {
      id: this.purchaseReceive.id,
      supplierId: formValue.supplierId || 0,
      orderId: this.purchaseReceive.orderId || 0,
      purchaseReceivedNumber: formValue.purchaseReceivedNumber || '',
      receivedDate: new Date(formValue.receivedDate).toISOString(),
      internalNotes: formValue.internalNotes || 'no notes',
      purchaseReceivedItems: purchaseReceivedItems,
    };

    console.log(purchaseReceiveData);

    this.purchaseReceiveService
      .updatePurchaseReceiveById(this.purchaseReceive.id,purchaseReceiveData)
      .subscribe(
        () => {
          Swal.fire(
            'Success',
            'Purchase Receive updated successfully.',
            'success'
          ).then(() => {
            this.purchaseReceiveForm.markAsPristine();
            this.purchaseReceiveForm.markAsUntouched();
            this.purchaseReceiveForm.reset();
            this.router.navigate(['/purchases/purchaseReceives']);
          });
        },
        (error) => Swal.fire('Error', error, 'error')
      );
  }

  onCancel() {
    if (this.purchaseReceiveForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.purchaseReceiveForm.patchValue(this.initialFormData);
    }
    this.purchaseReceiveForm.markAsPristine();
    this.purchaseReceiveForm.markAsUntouched();
    this.isEditMode = false;
  }

  onEdit() {
    this.isEditMode = true;
  }

  onDelete() {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this purchase receive?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.purchaseReceiveService
          .deletePurchaseReceive(this.purchaseReceive.id)
          .subscribe(
            () => {
              Swal.fire(
                'Success',
                'Purchase Receive deleted successfully.',
                'success'
              ).then(() => this.router.navigate(['/purchases/purchaseReceives']));
            },
            (error) => Swal.fire('Error', error, 'error')
          );
      }
    });
  }
  
  
}
