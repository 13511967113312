import { environment } from "src/environments/environment";

// export const BASE_URL = 'https://localhost:7290';
export const BASE_URL = environment.BACKEND_URL;

export const backend = {
  departmentData: {
    getAll: `${BASE_URL}/DepartmentData/{departmentId}`,
    get: (departmentId: number | null) =>
      `${BASE_URL}/DepartmentData/{departmentId}?departmentId=${departmentId}`,
    post: `${BASE_URL}/DepartmentData`,
    put: (departmentId: number | null) =>
      `${BASE_URL}/DepartmentData/${departmentId}`,
    delete: (departmentId: number | null) =>
      `${BASE_URL}/DepartmentData/${departmentId}`,
  },

  jobTitleData: {
    getAll: `${BASE_URL}/JobTitleData/{jobTitleId}`,
    get: (jobTitleId: number | null) =>
      `${BASE_URL}/JobTitleData/{jobTitleId}?jobTitleId=${jobTitleId}`,
    post: `${BASE_URL}/JobTitleData`,
    put: (jobTitleId: number | null) =>
      `${BASE_URL}/JobTitleData/${jobTitleId}`,
    delete: (jobTitleId: number | null) =>
      `${BASE_URL}/JobTitleData/${jobTitleId}`,
  },
  employee: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Employee/{employeeId}/{employeeName}`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    // getAll: `${BASE_URL}/Employee/{employeeId}/{employeeName}`,
    getById: (employeeId: number) =>
      `${BASE_URL}/Employee/${employeeId}`,
    post: `${BASE_URL}/Employee`,
    uploadPicture: (employeeId: number) =>
      `${BASE_URL}/Employee/UploadNewPicture/${employeeId}`,
    getPicture: (employeeId: number) =>
      `${BASE_URL}/Employee/DownloadPicture/${employeeId}`,
    updateById: (employeeId: number) => `${BASE_URL}/Employee/${employeeId}`,
    deleteById: (employeeId: number) => `${BASE_URL}/Employee/${employeeId}`,
  },
  hResourceData: {
    get: `${BASE_URL}/HResourceData`,
  },
  regionData: {
    get: `${BASE_URL}/RegionData`,
    post: `${BASE_URL}/RegionData`,
    put: `${BASE_URL}/RegionData`,
    delete: `${BASE_URL}/RegionData`,
  },
  modules: {
    getAll: `${BASE_URL}/Module`,
  },
  localeType: {
    put:(locale:string)=> `${BASE_URL}/UserManagement/ChangeLanguage?locale=${locale}`,
  },
  productsData: {
    getAll: (categoryId:number,pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Products/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (categoryId) params.push(`categoryId=${categoryId}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (productId: number) =>
      `${BASE_URL}/Products/${productId}`,

    post: `${BASE_URL}/Products`,
    deleteById: (productId: number) => `${BASE_URL}/Products/${productId}`,
    updateById: (productId: number) => `${BASE_URL}/Products/${productId}`,
  },
  equipmentData: {
    getAll: (categoryId:number,pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Equipment/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (categoryId) params.push(`equipmetCategoryId=${categoryId}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (equipmentId : number) =>
      `${BASE_URL}/Equipment/${equipmentId }`,

    post: `${BASE_URL}/Equipment`,
    deleteById: (equipmentId : number) => `${BASE_URL}/Equipment/${equipmentId }`,
    updateById: (equipmentId : number) => `${BASE_URL}/Equipment/${equipmentId }`,
    getEquipmentByCategory: (categoryId:number) => 
      `${BASE_URL}/Equipment/equipmentByCategory/${categoryId}`,
  },
  equipmentDropdownData:{
    get: `${BASE_URL}/EquipmentData`,
  },
  inventoryData: {
    get: `${BASE_URL}/InventoryData`,
  },
  categoriesData: {
    getAll: `${BASE_URL}/Category/{categoryId}`,
    getByID: (categoryId: number) =>
      `${BASE_URL}/Category/{categoryId}?categoryId=${categoryId}`,
    post: `${BASE_URL}/Category`,
    deleteById: (categoryId: number) => `${BASE_URL}/Category/${categoryId}`,
    updateById: (categoryId: number) => `${BASE_URL}/Category/${categoryId}`,
  },
  brandsData: {
    getAll: `${BASE_URL}/Brand/{brandId}`,
    getByID: (brandId: number) =>
      `${BASE_URL}/Brand/{brandId}?brandId=${brandId}`,
    post: `${BASE_URL}/Brand`,
    deleteById: (brandId: number) => `${BASE_URL}/Brand/${brandId}`,
    updateById: (brandId: number) => `${BASE_URL}/Brand/${brandId}`,
  },
  currencyData: {
    getAll: `${BASE_URL}/Currency/{currencyId}`,
    getById: (currencyId: number) =>
      `${BASE_URL}/Currency/{currencyId}?currencyId=${currencyId}`,
    post: `${BASE_URL}/Currency`,
    deleteById: (currencyId: number) => `${BASE_URL}/Currency/${currencyId}`,
    updateById: (currencyId: number) => `${BASE_URL}/Currency/${currencyId}`,
  },
  tenantSettings: {
    get: `${BASE_URL}/TenantSettings`,
    update: `${BASE_URL}/TenantSettings`,
    /*
   logoToDownload = 1-> Logo Path
  logoToDownload =  2-> Invoice Logo Path
  logoToDownload =  3-> Fav Icon Path
    */
    downloadLogo: (logoToDownload: number) =>
      `${BASE_URL}/TenantSettings/DownloadPicture/${logoToDownload}`,

    /*
   logoToDownload = 1-> Logo Path
  logoToDownload =  2-> Invoice Logo Path
  logoToDownload =  3-> Fav Icon Path
    */
    uploadLogo: (logoToDownload: number) =>
      `${BASE_URL}/TenantSettings/UploadNewPicture/${logoToDownload}`,

    getAutoIncreamentNumbersData: (accountantScopeId: number)=>
      `${BASE_URL}/TenantSettings/GetAutoIncreamentNumbersData/${accountantScopeId}`,
    updateAutoIncreamentNumbersData: (accountantScopeId: number)=>
      `${BASE_URL}/TenantSettings/UpdateAutoIncreamentNumbersData/${accountantScopeId}`,
  },
  adminData: {
    get: `${BASE_URL}/AdminData`,
  },
  servicesData: {
    getAll: `${BASE_URL}/Service/{serviceId}`,
    getById: (serviceId: number) =>
      `${BASE_URL}/Service/{serviceId}?serviceId=${serviceId}`,
    getProductsByCategory: (categoryId:number) => 
      `${BASE_URL}/Service/productsByCategory/${categoryId}`,
    post: `${BASE_URL}/Service`,
    deleteById: (serviceId: number) => `${BASE_URL}/Service/${serviceId}`,
    updateById: (serviceId: number) => `${BASE_URL}/Service/${serviceId}`,
  },
  paymentData: {
    getAll: `${BASE_URL}/PaymentType/{paymentTypeId}`,
    getById: (paymentTypeId: number) =>
      `${BASE_URL}/PaymentType/{paymentTypeId}?paymentTypeId=${paymentTypeId}`,
    post: `${BASE_URL}/PaymentType`,
    deleteById: (paymentTypeId: number) =>
      `${BASE_URL}/PaymentType/${paymentTypeId}`,
    updateById: (paymentTypeId: number) =>
      `${BASE_URL}/PaymentType/${paymentTypeId}`,
  },
  customer: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Customer/{customerId}`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (customerId: number) =>
      `${BASE_URL}/Customer/{customerId}?customerId=${customerId}`,
    post: `${BASE_URL}/Customer`,
    updateById: (customerId: number) => `${BASE_URL}/Customer/${customerId}`,
    deleteById: (customerId: number) => `${BASE_URL}/Customer/${customerId}`,
  },

  invoices: {
    getAllWithoutInvoiceLines: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Invoice`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getUnpaidInvoicesByCustomer: (customerId: number, pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Invoice/unpaid/${customerId}`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) {
        url += `?${params.join('&')}`;
      }
      return url;
    },

    getByIdWithInvoiceLines: (invoiceId: number) =>
      `${BASE_URL}/Invoice/get/${invoiceId}`,
    post: `${BASE_URL}/Invoice`,
    deleteById: (invoiceId: number) => `${BASE_URL}/Invoice/${invoiceId}`,
    updateById: (invoiceId: number) => `${BASE_URL}/Invoice/${invoiceId}`,
    sendEmail:`${BASE_URL}/Account/SendEmail`
  },
  serviceInvoiceData:{
    get: `${BASE_URL}/ServiceInvoiceData`
  },
  paymentReceived: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/PaymentReceived`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (paymentReceivedId: number) =>
      `${BASE_URL}/PaymentReceived/${paymentReceivedId}`,
    post: `${BASE_URL}/PaymentReceived`,
    updateById: (paymentReceivedId: number) => `${BASE_URL}/PaymentReceived/${paymentReceivedId}`,
    deleteById: (paymentReceivedId: number) => `${BASE_URL}/PaymentReceived/${paymentReceivedId}`,
  },


  supplier: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Supplier/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (supplierId: number) =>
      `${BASE_URL}/Supplier/${supplierId}`,
    post: `${BASE_URL}/Supplier`,
    updateById: (supplierId: number) => `${BASE_URL}/Supplier/${supplierId}`,
    deleteById: (supplierId: number) => `${BASE_URL}/Supplier/${supplierId}`,
  },

  userManagement: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/UserManagement/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (userId: string) => `${BASE_URL}/UserManagement/${userId}`,
    deleteById: (userId: string) => `${BASE_URL}/UserManagement/${userId}`,
    updateById: (userId: string) => `${BASE_URL}/UserManagement/${userId}`,
    sendMailById: (userId: string) => `${BASE_URL}/UserManagement/SendMailActions/${userId}`,
    post: `${BASE_URL}/UserManagement`,
    getDropdownData: `${BASE_URL}/UserManagement/DropdownData`
  },
  order: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Order/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (orderId: number) => `${BASE_URL}/Order/${orderId}`,
    deleteById: (orderId: number) => `${BASE_URL}/Order/${orderId}`,
    updateById: (orderId: number) => `${BASE_URL}/Order/${orderId}`,
    post: `${BASE_URL}/Order`,
  },
  orderData:{
    getOrderData: `${BASE_URL}/OrderData`
  },
  purchaseReceives: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/PurchaseReceived/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (id: number) => `${BASE_URL}/PurchaseReceived/${id}`,
    deleteById: (id: number) => `${BASE_URL}/PurchaseReceived/${id}`,
    updateById: (id: number) => `${BASE_URL}/PurchaseReceived/${id}`,
    post: `${BASE_URL}/PurchaseReceived`,
    getUnreceivedOrdersBySupplier:(supplierId: number) => `${BASE_URL}/PurchaseReceived/unreceivedOrders/${supplierId}`,
    getUnreceivedOrderItems:(supplierId: number,orderId: number) => `${BASE_URL}/PurchaseReceived/unreceivedOrderItems/${supplierId}/${orderId}`,
  },
  bills: {
    getAll: (pageNumber?: number, pageSize?: number) => {
      let url = `${BASE_URL}/Bill/`;
      const params = [];
      if (pageNumber) params.push(`PageNumber=${pageNumber}`);
      if (pageSize) params.push(`PageSize=${pageSize}`);
      if (params.length) url += `?${params.join('&')}`;
      return url;
    },
    getById: (id: number) => `${BASE_URL}/Bill/${id}`,
    deleteById: (id: number) => `${BASE_URL}/Bill/${id}`,
    updateById: (id: number) => `${BASE_URL}/Bill/${id}`,
    post: `${BASE_URL}/Bill`,
    getReicevesBySupplier: (supplierId: number) => `${BASE_URL}/Bill/unBilledPurchaseReceives/${supplierId}`,
  },
  vatData: {
    getAll: `${BASE_URL}/Vat/{VatId}`,
    getById: (vatId: number) =>
      `${BASE_URL}/Vat/{VatId}?VatId=${vatId}`,
    post: `${BASE_URL}/Vat`,
    deleteById: (vatId: number) => `${BASE_URL}/Vat/${vatId}`,
    updateById: (vatId: number) => `${BASE_URL}/Vat/${vatId}`,
  },
  equipmentCategoriesData: {
    getAll: `${BASE_URL}/EquipmentCategory/{equipmentCategoryId}`,
    getByID: (equipmentCategoryId: number) =>
      `${BASE_URL}/EquipmentCategory/{equipmentCategoryId}?equipmentCategoryId=${equipmentCategoryId}`,
    post: `${BASE_URL}/EquipmentCategory`,
    deleteById: (equipmentCategoryId: number) => `${BASE_URL}/EquipmentCategory/${equipmentCategoryId}`,
    updateById: (equipmentCategoryId: number) => `${BASE_URL}/EquipmentCategory/${equipmentCategoryId}`,
  },
  dashboardData: {
    getEmployeesCountByJobTitle: (startDate: string,endDate:string) => `${BASE_URL}/Dashboard/GetEmployeesCountByJobTitle?startDate=${startDate}&endDate=${endDate}`,
    getEquipmentStatusByCategory: `${BASE_URL}/Dashboard/GetEquipmentStatusByCategory`,
    

  },
};
