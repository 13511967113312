import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierService } from '@services/supplier.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { HResourceDataService } from '@services/resourceData.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-supplier',
  templateUrl: './new-supplier.component.html',
  styleUrls: ['./new-supplier.component.css'],
})
export class NewSupplierComponent implements OnInit {
  supplierForm!: FormGroup;
  countriesMap: { [id: number]: string } = this.hResourceService.countries;
  regionsMap: { [id: number]: string } = this.hResourceService.regions;
  constructor(
    private fb: FormBuilder,
    private supplierService: SupplierService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceService: HResourceDataService
  ) {
    this.supplierForm = this.fb.group({
      contactName: ['', Validators.required],
      contactTitle: ['', Validators.required],
      taxNumber: ['', Validators.required],
      companyName: [''],
      postalCode: [''],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: [''],
      city: ['', Validators.required],
      regionId: ['', Validators.required],
      countryId: ['', Validators.required]
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.supplierForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.supplierForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {}

  onSubmit() {
    if(this.supplierForm.invalid){
      Object.values(this.supplierForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.supplierForm.getRawValue();
    const dataToSend = {
      contactName: formValue.contactName,
      contactTitle: formValue.contactTitle,
      taxNumber: formValue.taxNumber,
      companyName: formValue.companyName,
      postalCode: formValue.postalCode,
      phone: formValue.phone,
      email: formValue.email,
      website: formValue.website || '',
      city: formValue.city,
      regionId: Number(formValue.regionId) || null,
      countryId: Number(formValue.countryId) || null
    };

    this.dataHelper.addNewRecord(
      this.supplierForm,
      dataToSend,
      (data) => this.supplierService.addSupplier(data),
      'Supplier created successfully',
      '/purchases/suppliers'
    );
  }

  cancel() {
    this.router.navigate(['/purchases/suppliers']);
  }
}
