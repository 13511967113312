import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
   <h2 mat-dialog-title>{{title | translate}}</h2>
<mat-dialog-content>{{message | translate}}</mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button (click)="onNoClick()">{{'No' | translate}}</button>
      <button mat-button [mat-dialog-close]="true">{{'Yes' | translate}}</button>
    </mat-dialog-actions>
  `
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.message = data.message;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
