import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvoiceLines } from '@interfaces/InvoiceLines.interface';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';

@Component({
  selector: 'app-edit-invoice-line',
  templateUrl: './edit-invoice-line.component.html',
  styleUrls: ['./edit-invoice-line.component.css'],
})
export class EditInvoiceLineComponent implements OnInit {
  
  invoiceLineForm: FormGroup;
  serviceMap: { [id: number]: any } = {};
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}
  invoiceLine!: InvoiceLines;
  grandTotal: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private dialogRef: MatDialogRef<EditInvoiceLineComponent>
  ) {
    this.invoiceLine = this.data.invoiceLine;

    this.invoiceLineForm = this.fb.group({
      id: [this.invoiceLine.id],
      serviceId: [this.invoiceLine.serviceId,[Validators.required]],
      quantity: [this.invoiceLine.quantity,[Validators.required,Validators.min(1)]],
      description: [this.invoiceLine.description],
      total: [this.invoiceLine.total],
      discount: [this.invoiceLine.discount, [Validators.min(0), Validators.max(100)]],
    });
  }

  ngOnInit(): void {
    this.loadDataMaps();   
    this.setupFormChanges();
    this.calculateGrandTotal();
  }

  loadDataMaps(): void {
    this.serviceMap = this.serviceInvoiceDataService.services;
  }

  setupFormChanges(): void {
    // Subscribe to form field changes
    this.invoiceLineForm.valueChanges.subscribe(val => {
      this.calculateGrandTotal();
    });
  }

  calculateGrandTotal(): void {
    const quantity = this.invoiceLineForm.get('quantity')?.value || 0;
    const total = this.invoiceLineForm.get('total')?.value || 0;
    const discount = this.invoiceLineForm.get('discount')?.value || 0;
    
    this.grandTotal = (quantity * total) - (discount / 100 * quantity * total);
  }

  onServiceSelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const serviceId = selectElement.value;

    if (serviceId !== null) {
      const selectedService = this.serviceMap[+serviceId];
      
      if (selectedService) {
        this.invoiceLineForm.patchValue({
          total: Number(selectedService.charge),
        });
      } else {
        console.log('Selected service not found:', serviceId);
      }
    }
  }

  saveChanges() {
    this.invoiceLine.quantity = this.invoiceLineForm.value.quantity;
    this.invoiceLine.description = this.invoiceLineForm.value.description;
    this.invoiceLine.total = this.invoiceLineForm.value.total;
    this.invoiceLine.discount = this.invoiceLineForm.value.discount;
    this.dialogRef.close(this.invoiceLineForm.value);
  }    

  cancelAction(): void {
    this.dialogRef.close();
  }
}
