import { Injectable } from '@angular/core';
import { EmailData } from '@interfaces/EmailData.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailDataService {
  private emailDataSource = new BehaviorSubject<EmailData | null>(null);
  currentEmailData = this.emailDataSource.asObservable();

  constructor() {}

  setEmailData(data: EmailData) {
    this.emailDataSource.next(data);
  }

  clearEmailData() {
    this.emailDataSource.next(null);
  }
  getCurrentEmailDataValue(): EmailData | null {
    return this.emailDataSource.value; 
  }
  }
