import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { Brand } from '@interfaces/Brand.interface';

@Injectable({
  providedIn: 'root',
})
export class BrandService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<Brand[]> {
    return this.http.get<Brand[]>(backend.brandsData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getById(brandId: number): Observable<Brand> {
    return this.http.get<Brand>(backend.brandsData.getByID(brandId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  createBrand(brandData: Brand): Observable<Brand> {
    return this.http.post<Brand>(backend.brandsData.post, brandData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  updateBrand(brandId: number, updatedBrandData: Brand): Observable<Brand> {
    return this.http.put<Brand>(backend.brandsData.updateById(brandId), updatedBrandData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  deleteBrand(brandId: number): Observable<void> {
    return this.http.delete<void>(backend.brandsData.deleteById(brandId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
