import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { EquipmentResponse } from '@interfaces/EquipmentResponse.interface';
import { GetEquipment } from '@interfaces/GetEquipment.interface';
import { Equipment } from '@interfaces/Equipment.interface';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(private http: HttpClient) {}

  getEquipments(categoryId:number, pageNumber: number, pageSize: number): Observable<EquipmentResponse> {
    return this.http.get<EquipmentResponse>(backend.equipmentData.getAll(categoryId, pageNumber, pageSize)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  addEquipment(equipment: Equipment): Observable<any> {
    return this.http.post<any>(backend.equipmentData.post, equipment).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }



  deleteEquipment(equipmentId: number): Observable<void> {
    return this.http.delete<void>(`${backend.equipmentData.deleteById(equipmentId)}`).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  getEquipmentById(equipmentId: number): Observable<Equipment> {
    return this.http.get<Equipment>(`${backend.equipmentData.getById(equipmentId)}`).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  updateEquipmentById(equipmentId: number, equipment: Equipment): Observable<any> {
    return this.http.put(backend.equipmentData.updateById(equipmentId), equipment, {
      responseType: 'text',
    }).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getEquipmentByCategory(categoryId: number): Observable<any> {
    return this.http.get<any>(backend.equipmentData.getEquipmentByCategory(categoryId)).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }
}
