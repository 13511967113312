import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Department } from '@interfaces/Department.interface';
import { backend } from '@apis/api-endpoints';
import { EquipmentCategoryStatus } from '@interfaces/EquipmentCategoryStatus.interface';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  GetEmployeesCountByJobTitle(startDate: string,endDate: string): Observable<any> {
    return this.http
      .get<any>(backend.dashboardData.getEmployeesCountByJobTitle(startDate,endDate))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  GetEquipmentStatusByCategory(): Observable<EquipmentCategoryStatus[]> {
    return this.http
      .get<EquipmentCategoryStatus[]>(
        backend.dashboardData.getEquipmentStatusByCategory
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
