import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GetInvoice } from '@interfaces/GetInvoice.interface';
import { Invoice } from '@interfaces/Invoice.interface';
import { InvoiceResponse } from '@interfaces/InvoiceResponse.interface';
import { PaymentReceived } from '@interfaces/PaymentReceived.interface';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '@services/invoices.service';
import { PaymentReceivedService } from '@services/paymentReceived.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Observable, concat, firstValueFrom, forkJoin } from 'rxjs';
import { DataHelper } from '../../../../helpers/data-helper';
import { PaginationData } from '../../../../helpers/pagination-data';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-payment-received',
  templateUrl: './new-payment-received.component.html',
  styleUrls: ['./new-payment-received.component.css'],
})
export class NewPaymentReceivedComponent {
  paymentReceivedForm!: FormGroup;
  today = new Date();
  customerMap: { [id: number]: any } = {};
  paymentTypesMap: { [id: number]: string } = {};
  selectedCustomerId!: number;
  unpaidInvoices: GetInvoice[] = [];
  invoicePaymentAmounts: number[] = [];
  invoicePaymentMap: { [id: number]: number } = {};
  paymentAmount: number = 0;
  totalDueAmount: number = 0;
  wrongTotal: boolean = true;
  currentTotalPayment:number =0;
  constructor(
    private fb: FormBuilder,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private invoiceService: InvoiceService,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private paymentReceivedService: PaymentReceivedService,
    private tenantSettingsService: TenantSettingsService,
    private route: ActivatedRoute
  ) {
    this.initializeForm();
    // Initialize the map
    this.unpaidInvoices.forEach((invoice) => {
      this.invoicePaymentMap[invoice.id] = 0;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.selectedCustomerId = params['customerId'];
    });
    this.loadDataMaps();
    this.loadAutoIncreamentNumber();
  }

  loadDataMaps(): void {
    this.customerMap = this.serviceInvoiceDataService.customers;
    this.paymentTypesMap = this.serviceInvoiceDataService.paymentTypes;
  }

  loadAutoIncreamentNumber(): void {
    //'4' represents the payments received prefix in the lookup table of the backend (accountant_scopes)
    this.tenantSettingsService.getAutoIncreamentNumbersData(4)
      .subscribe((response: string) => {
        this.paymentReceivedForm.patchValue({ 
          paymentNumber: response
        });
      }, error => {
        console.error('Failed to load payments received number:', error);
      });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.paymentReceivedForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.paymentReceivedForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalInvoices: number = 0;

  fetchinvoices(
    customerId: number = this.selectedCustomerId,
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.invoiceService.getUnpaidInvoicesByCustomer(customerId, page, size),
      (response: InvoiceResponse) => {
        this.unpaidInvoices = response.items;
        this.totalInvoices = response.totalItems;
        this.totalDueAmount = this.unpaidInvoices.reduce((sum, invoice) => sum + invoice.due, 0);
      }
    );
  }

  checkAmount(event: any) {
    const inputValue = (event.target as HTMLInputElement).value;    
    
    if ( parseFloat(inputValue) <= 0) {
      this.wrongTotal=true;
        Swal.fire({
            title: 'Invalid Amount',
            text: 'Please enter a value greater than 0',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        return;
    }
    else if ( parseFloat(inputValue) > this.totalDueAmount) {
      this.wrongTotal=true;
      Swal.fire({
          title: 'Invalid Amount',
          text: `Amount cannot be greater than total due amount (${this.totalDueAmount})` ,
          icon: 'error',
          confirmButtonText: 'OK'
      });
      return;
    }
    if(parseFloat(inputValue)<this.currentTotalPayment && this.currentTotalPayment!=0){
      this.wrongTotal=true;
      Swal.fire({
          title: 'Invalid Amount',
          text: `Amount cannot be less than total payments (${this.currentTotalPayment})` ,
          icon: 'error',
          confirmButtonText: 'OK'
      });
      return;
    }
    else  if(parseFloat(inputValue)>this.currentTotalPayment && this.currentTotalPayment!=0){
      this.wrongTotal=true;
      Swal.fire({
          title: 'Invalid Amount',
          text: `Amount cannot be greater than total payments (${this.currentTotalPayment})` ,
          icon: 'error',
          confirmButtonText: 'OK'
      });
      return;
    }
    this.wrongTotal=false;

}

initializeForm() {
  this.paymentReceivedForm = this.fb.group({
    customerId: ['', Validators.required],
    amountReceived: [{value: '', disabled: true},[Validators.required, Validators.min(1)]],
    date: [{value: this.today.toISOString(), disabled: true}],
    paymentNumber: [{value: '', disabled: true}, Validators.required],
    paymentTypeId: [{value: '', disabled: true}, Validators.required],
    invoiceId: [{value: '0', disabled: true}, Validators.required],
  });
}

onCustomerSelected() {
  if (this.selectedCustomerId) {
    this.paymentReceivedForm.enable(); // Enable all form controls
    this.paymentReceivedForm.get('customerId')?.enable(); // Ensure customer ID field remains enabled
    this.fetchinvoices();
  } else {
    this.paymentReceivedForm.disable(); // Disable all form controls
    this.paymentReceivedForm.get('customerId')?.enable(); // Ensure customer ID field remains enabled
  }
}

updatePaymentAmount(invoiceId: number, event: any) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.paymentAmount = inputValue !== '' ? parseFloat(inputValue) : 0;

    if (!this.unpaidInvoices || this.unpaidInvoices.length === 0) {
      Swal.fire('Error', 'No unpaid invoices found.', 'error');
      this.wrongTotal = true;
      return;
    }

    const invoice = this.unpaidInvoices.find((i) => i.id === invoiceId);

    if (!invoice) {
      Swal.fire('Error', 'Invoice not found for id: ' + invoiceId, 'error');
      this.wrongTotal = true;
      return;
    }

    if (
      isNaN(this.paymentAmount) ||
      this.paymentAmount < 0 ||
      this.paymentAmount > invoice.due
    ) {
      Swal.fire(
        'Error',
        'Invalid payment amount: ' + this.paymentAmount,
        'error'
      );

      // Replace the invalid value with 0 in the payment map
      this.wrongTotal = true;
      return;
    }

    // Update the payment map with the entered value
    this.invoicePaymentMap[invoiceId] = this.paymentAmount;

    // Check if the sum of payment amounts exceeds amountReceived
     this.currentTotalPayment = Object.values(this.invoicePaymentMap).reduce(
      (total, amount) => total + amount,
      0
    );
    console.log(this.currentTotalPayment)
    console.log(this.invoicePaymentMap)
    const amountReceived =
      this.paymentReceivedForm.get('amountReceived')?.value;
    if (this.currentTotalPayment > amountReceived && amountReceived>0) {
      Swal.fire('Error', 'Total payment exceeds amount received.', 'error');

      // Replace the invalid value with 0 in the payment map
      this.wrongTotal = true;
      return;
    }
    if (
      (this.currentTotalPayment < amountReceived &&
        Object.keys(this.invoicePaymentMap).length ===
          this.unpaidInvoices.length) ||
      (Object.keys(this.invoicePaymentMap).length !=
        this.unpaidInvoices.length &&
        this.currentTotalPayment != amountReceived)
    ) {
      Swal.fire(
        'Error',
        'Total payment cant be less than amount received.',
        'error'
      );
      this.wrongTotal = true;
      return;
    }
    
    this.wrongTotal = false; 
  }

  cancel() {
    this.router.navigate(['/sales/paymentsReceived']);
  }

  async onSubmit() {
    if(this.paymentReceivedForm.invalid){
      Object.values(this.paymentReceivedForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.paymentReceivedForm.getRawValue();
    let initialPaymentNumber = formValue.paymentNumber;
    const payments: PaymentReceived[] = [];

    for (const invoiceId of Object.keys(this.invoicePaymentMap)) {
        const numericInvoiceId = +invoiceId;
        const paymentAmount = this.invoicePaymentMap[numericInvoiceId];

        if (paymentAmount > 0) {
            const payment: PaymentReceived = {
                id: 0,
                customerId: formValue.customerId,
                amountReceived: paymentAmount,
                paymentDate: this.today.toISOString(),
                paymentTypeId: formValue.paymentTypeId,
                paymentNumber: `${initialPaymentNumber}`,
                invoiceId: numericInvoiceId,
            };
            payments.push(payment);

            try {
                //'4' represents the payments received prefix in the lookup table of the backend (accountant_scopes)
                await firstValueFrom(this.tenantSettingsService.updatAutoIncreamentNumbersData(4));
                const response: string = await firstValueFrom(this.tenantSettingsService.getAutoIncreamentNumbersData(4));
                initialPaymentNumber = response; // Update for the next payment
            } catch (error) {
                console.error('Failed to update or load payments received number:', error);
            }
        }
    }
  
    const addPaymentsObservables = payments.map(payment => 
      this.paymentReceivedService.addPaymentReceived(payment)
    );
  
    forkJoin(addPaymentsObservables).subscribe(
      () => {
        // After successful payment, fetch and update invoices
        const getInvoicesObservables = payments.map(payment => 
          this.invoiceService.getByIdWithInvoiceLines(payment.invoiceId)
        );
  
        forkJoin(getInvoicesObservables).subscribe(
          (invoices: Invoice[]) => {
            const updateInvoiceObservables = invoices.map((invoice, index) => {
              invoice.paidAmount += payments[index].amountReceived;
              invoice.due = invoice.netTotal - invoice.paidAmount;
              invoice.status = invoice.due === 0 ? 'PAID' : 'PARTIALLY PAID';
  
              return this.invoiceService.updateInvoiceById(invoice.id, invoice);
            });
  
            forkJoin(updateInvoiceObservables).subscribe(
              () => {
                Swal.fire('Success', 'Invoices updated successfully.', 'success')
                .then(() => {
                  this.paymentReceivedForm.markAsPristine();
                  this.paymentReceivedForm.markAsUntouched();
                  this.paymentReceivedForm.reset();
                  this.router.navigate(['/sales/paymentsReceived'])
                });
              },
              error => Swal.fire('Error', error , 'error')
            );
          },
          error => Swal.fire('Error', error , 'error')
          );
      },
      error => Swal.fire('Error', error , 'error')
      );
  }
  }