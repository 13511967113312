import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from '@services/departments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from 'src/app/helpers/data-helper';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { Department } from '@interfaces/Department.interface';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.css'],
})
export class EditDepartmentComponent implements OnInit {
  departmentForm!: FormGroup;
  departmentId!: number;
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: any | null = null;


  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.departmentForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.departmentForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.departmentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.departmentId = Number(params.get('departmentId')); // Extract departmentId from the route

      if (!isNaN(this.departmentId)) {
        this.departmentService
          .getDepartment(this.departmentId)
          .subscribe((department) => {
            console.log('Department Data:', department); // Log the fetched department data
            if (Array.isArray(department) && department.length > 0) {
              // If department is an array, patch the form with the first element of the array
              this.departmentForm.patchValue(department[0]);
              this.initialFormData = { ...department[0] };

            } else if (department) {
              // If department is a single object, patch the form with it
              this.departmentForm.patchValue(department);
              this.initialFormData = { ...department };
            }
            this.breadcrumbService.set('@departmentName', Array.isArray(department) ? department[0].name : department.name);
          });
      } else {
        console.error('Invalid departmentId');
      }
    });
  }

  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  onSubmit() {
    if(!this.departmentForm.valid){
      Object.values(this.departmentForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.departmentForm.valid) {
      // Clone the form values
      const updatedDepartment = this.departmentForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this department? Some changes may affect major functionalities',
        () =>
          this.departmentService.updateDepartment(
            this.departmentId,
            updatedDepartment
          ),
        'Department updated successfully',
        () => {
          this.departmentForm.markAsPristine();
          this.departmentForm.markAsUntouched();
          this.departmentForm.reset();
          this.router.navigate(['/masters/departments']);
        }
      );
    }
  }

  cancel() {
    if (this.departmentForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.departmentForm.patchValue(this.initialFormData);
    }
    this.departmentForm.markAsPristine();
    this.departmentForm.markAsUntouched();
    this.isEditMode = false;
  }



  exitView(){
    this.router.navigate(['/masters/departments']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this department? Some changes may affect major functionalities',
      () => this.departmentService.deleteDepartment(this.departmentId),
      'Department deleted successfully',
      () => this.router.navigate(['/masters/departments']),
      () => null
    );
  }
}
