import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { Service } from '@interfaces/Service.interface';
@Injectable({
  providedIn: 'root',
})
export class ServicesService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<Service[]> {
    return this.http.get<Service[]>(backend.servicesData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  getById(serviceId: number): Observable<Service> {
    return this.http.get<Service>(backend.servicesData.getById(serviceId)).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  getProductsByCategory(categoryId: number): Observable<any> {
    return this.http.get<any>(backend.servicesData.getProductsByCategory(categoryId)).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  createService(servicesData: Service): Observable<Service> {
    return this.http.post<Service>(backend.servicesData.post, servicesData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  updateService(serviceId: number, updatedservicesData: Service): Observable<Service> {
    return this.http.put<Service>( backend.servicesData.updateById(serviceId), updatedservicesData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  deleteService(serviceId: number): Observable<void> {
    return this.http.delete<void>( backend.servicesData.deleteById(serviceId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
