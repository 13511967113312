import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@services/product.service'; // Replace with your product service
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from 'src/app/helpers/data-helper'; // Replace with your helper
import { InventoryDataService } from '@services/inventoryData.service';
import { HResourceDataService } from '@services/resourceData.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { PostProduct } from '@interfaces/PostProduct.interface';
import { GetProduct } from '@interfaces/GetProduct.interface';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css'],
})
export class EditProductComponent implements OnInit {
  productForm!: FormGroup;
  productId!: number;
  brandMap: { [id: number]: string } = this.inventoryDataService.brands;
  categoryMap: { [id: number]: string } = this.inventoryDataService.categories;
  departmentMap: { [id: number]: string } =
    this.hResourceDataService.departments;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }
  fileName: string = '';
  isEditMode: boolean = false;
  categoryName: string | null = null;
  initialFormData: GetProduct | null = null;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService, // Replace with your product service
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
    private hResourceDataService: HResourceDataService,
    private inventoryDataService: InventoryDataService,
    private breadCrumbService: BreadcrumbService
  ) {
    this.productForm = this.fb.group({
      code: [''],
      serialNumber: ['', Validators.required],
      name: ['', Validators.required],
      details: [''],
      model: [''],
      price: [''],
      retailPrice: [''],
      inStock: ['0', Validators.required],
      isProductActive: [true],
      brandId: ['', Validators.required],
      categoryId: ['', Validators.required],
      departmentId: ['', Validators.required],
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.productForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.productForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    console.log('Entering ngOnInit');

    this.route.paramMap.subscribe(
      (params) => {
        console.log('Inside paramMap subscription', params.keys);
        this.categoryName = params.get('categoryName');
        const id = params.get('id');
        console.log('Retrieved ID from params:', id);

        if (id !== null) {
          this.productId = +id;
          console.log('Making API call for product ID:', this.productId);

          this.productService.getProductById(this.productId).subscribe({
            next: (response) => {
              console.log('API call successful:', response);
              if (response) {
                const productData = response;
                this.productForm.patchValue(productData);
                this.initialFormData = { ...response };
                console.log('Form Values:', this.productForm.value);
              } else {
                console.error('No product data found in response');
              }
              this.breadCrumbService.set('@productName', response.name);
            },
            error: (error) => {
              console.error('Error during API call:', error);
            },
          });
        } else {
          console.log('ID is null or not found in the route');
        }
      },
      (error) => {
        console.error('Error during paramMap subscription:', error);
      }
    );
  }

  onSubmit() {
    if(this.productForm.invalid){
      Object.values(this.productForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.productForm.valid) {
      const updatedProduct = { ...this.productForm.getRawValue() };
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this product? Some changes may affect major functionalities',
        () =>
          this.productService.updateProductById(this.productId, updatedProduct), // Implement updateProductById in your product service
        'Product updated successfully',
        () => {
          this.productForm.markAsPristine();
          this.productForm.markAsUntouched();
          this.productForm.reset();
          this.router.navigate(['/inventory/products',this.categoryName]); // Navigate to the products page or wherever you want
        }
      );
    }
  }
  cancel() {
    if (this.productForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.productForm.patchValue(this.initialFormData);
    }
    this.productForm.markAsPristine();
    this.productForm.markAsUntouched();
    this.isEditMode = false;
  }

  onEdit() {
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this product? Some changes may affect major functionalities',
      () => this.productService.deleteProduct(this.productId),
      'Product deleted successfully',
      () => this.router.navigate([`/inventory/products/${this.categoryName}`]),
      () => null
    );
  }}
