import { Component, OnInit } from '@angular/core';
import { VatService } from '@services/vat.service';
import { Vat } from '@interfaces/Vat.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-vat',
  templateUrl: './vat.component.html',
  styleUrls: ['./vat.component.css'],
})
export class VatComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'value', header: this.translate.instant('Value') },
];

  vats: Vat[] = [];
  filteredVats: Vat[] = [];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  constructor(
    private translate: TranslateService,
    private vatSerive: VatService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchVats();
  }

  fetchVats(): void {
    this.dataHelper.fetchItems(
      this.vatSerive.getAll(),
      (data: Vat[]) => {
        console.log(data)
        this.vats = data;
        this.filteredVats = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.filteredVats = this.vats.filter(vat =>
      vat.name.toLowerCase().includes(filterValue)
    );
  }

  deleteVat(element: Vat): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this Vat? Some changes may affect major functionalities',
      () => this.vatSerive.deleteVat(element.id),
      'Vat deleted successfully',
      () => this.fetchVats(),
      () => null
    );
  }

  addVat() {
    this.router.navigate(['/masters/vats/new_vat']);
  }

  editVat(element: Vat): void {
    this.router.navigate(['/masters/vats/edit_vat', element.id]);
  }
}
