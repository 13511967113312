import { Component, OnInit } from '@angular/core';
import { CategoryService } from '@services/category.service';
import { Category } from '@interfaces/Category.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  displayedColumns:  Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
  ];
  categories: Category[] = [];
  filteredCategories: Category[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private categoryService: CategoryService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  ngOnInit(): void {
    this.fetchCategories();
  }

  fetchCategories(): void {
    this.dataHelper.fetchItems(
      this.categoryService.getAll(),
      (data: Category[]) => {
        this.categories = data;
        this.filteredCategories = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredCategories = this.categories.filter(
      (category) =>
        category.name.toLowerCase().includes(filterValue)
    );
  }

  deleteCategory(element: Category): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this category? Some changes may affect major functionalities',
      () => this.categoryService.deleteCategory(element.id),
      'Category deleted successfully',
      () => this.fetchCategories(),
      () => null
    );
  }

  addCategory() {
    this.router.navigate(['/masters/categories/new_category']);
  }

  editCategory(element: Category): void {
    this.router.navigate(['/masters/categories/edit_category', element.id]);
  }
}
