import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { backend } from '@apis/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class AdminDataService {
  private currenciesMap: { [id: number]: string } = {};
  private vatsMap: { [id: number]: { name: string, value: number } } = {};
  
  // BehaviorSubject to track if data has been loaded
  private dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.loadData();
  }

  private loadData(): void {
    if (!this.dataLoaded$.getValue()) {
      this.getAdminData().subscribe(data => {
        this.createMap(data.currencies, this.currenciesMap);
        this.createVatMap(data.vats, this.vatsMap);
        this.dataLoaded$.next(true); // Indicate that data has been loaded
      });
    }
  }

  private createMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach(item => {
      mapRef[item.id] = item.name;
    });
  }

  private createVatMap(data: any[], mapRef: { [id: number]: { name: string, value: number } }): void {
    data.forEach(item => {
      mapRef[item.id] = { name: item.name, value: item.value };
    });
  }
  

  get currencies(): { [id: number]: string } {
    return this.currenciesMap;
  }

  get vats(): { [id: number]: { name: string, value: number } } {
    return this.vatsMap;
  }

  getAdminData(): Observable<any> {
    return this.http.get<any>(backend.adminData.get).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
