import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderItems } from '@interfaces/OrderItems.interface';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'app-edit-order-item',
  templateUrl: './edit-order-item.component.html',
  styleUrls: ['./edit-order-item.component.css'],
})
export class EditOrderItemComponent implements OnInit {
  @Output() orderItemUpdated = new EventEmitter<OrderItems>();
  
  orderItemForm: FormGroup;
  productMap: { [id: number]: any } = {};
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}
  orderItem!: OrderItems;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<EditOrderItemComponent>
  ) {
    this.orderItem = this.data.orderItem;

    this.orderItemForm = this.fb.group({
      id: [this.orderItem.id], // Include the id field
      productId: [this.orderItem.productId.toString(), Validators.required],
      quantity: [this.orderItem.quantity,[Validators.required,Validators.min(1)]],
      total: [this.orderItem.total, Validators.required],
    });
    
    
  }

  ngOnInit(): void {
    this.loadDataMaps();   
  }

  loadDataMaps(): void {
    this.productMap = this.orderService.products;
  }

  onProductSelectionChange(event: any): void {
    const productId = event.target.value;
    if (productId !== null) {
      const selectedProduct = this.productMap[productId];
      console.log(selectedProduct)
      if (selectedProduct) {
        this.orderItemForm.patchValue({
          total: Number(selectedProduct.price),
        });
      } else {
        console.log('Selected product not found:', productId);
      }
    }
  }

  saveChanges() {
    this.orderItem.quantity = this.orderItemForm.value.quantity;
    this.orderItem.total = this.orderItemForm.value.total;
    this.dialogRef.close(this.orderItemForm.value);
  }

  cancelAction(): void {
    this.dialogRef.close();
  }
}
