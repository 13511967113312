import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '@services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../../helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { NewServiceItemComponent } from '../service-item/new-service-item/new-service-item.component';
import { OrderService } from '@services/order.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { UdpateServiceItemComponent } from '../service-item/udpate-service-item/udpate-service-item.component';
import { Observable, Subscription } from 'rxjs';
import { ServiceItem } from '@interfaces/ServiceItem.interface';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-new-service',
  templateUrl: './new-service.component.html',
  styleUrls: ['./new-service.component.css'],
})
export class NewServiceComponent implements OnInit {
  servicesForm!: FormGroup;
  filteredProductsMap: { [lineIndex: number]: any[] } = {};

  private formChangesSubscription!: Subscription;

  serviceItemsDataSource: MatTableDataSource<ServiceItem>;

  productsMap: { [id: number]: any } = {};
  categoriesMap: { [id: number]: any } = {};
  constructor(
    private fb: FormBuilder,
    private serviceService: ServicesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private orderService: OrderService,
    private cdr: ChangeDetectorRef,
    private serviceInvoiceDataService: ServiceInvoiceDataService
  ) {
    this.serviceItemsDataSource = new MatTableDataSource<ServiceItem>([]);
    this.servicesForm = this.fb.group({
      name: ['', Validators.required],
      description: ['',Validators.required],
      charge: ['', [Validators.required, Validators.min(0)]],
      tax: ['', Validators.required],
      serviceItems: this.fb.array([]),
    });
  }

  get serviceItems() {
    return this.servicesForm.get('serviceItems') as FormArray;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.servicesForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.servicesForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.productsMap = this.orderService.products;
    this.categoriesMap = this.serviceInvoiceDataService.categories;
    this.serviceItems.push(this.createServiceItem());
  }

  createServiceItem(): FormGroup {
    const serviceLineFormGroup = this.fb.group({
      categoryId: ['0'],
      productId: [{ value: '0', disabled: true }, Validators.required],
      quantity: [
        { value: '1', disabled: true },
        [Validators.required, Validators.min(1)],
      ],
    });
    return serviceLineFormGroup;
  }

  addServiceLine(): void {
    this.serviceItems.push(this.createServiceItem());
  }

  loadProducts(selectedCategoryId: number, lineIndex: number) {
    this.dataHelper.fetchItems(
      this.serviceService.getProductsByCategory(selectedCategoryId),
      (data: any) => {
        if (data && data.products) {
          // Update the filtered products for this line
          this.filteredProductsMap[lineIndex] = data.products;
          console.log(
            'Filtered products for line',
            lineIndex,
            ':',
            data.products
          );
          this.cdr.detectChanges();
        }
      }
    );
  }

  onCategorySelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const categoryId = selectElement.value;
    const selectedCategory = this.categoriesMap[+categoryId];

    if (selectedCategory) {
      this.serviceItems.at(index).patchValue({
        categoryId: categoryId,
      });
      this.loadProducts(+categoryId, index);

      // Enable other controls
      this.serviceItems.at(index).get('productId')?.enable();
      this.serviceItems.at(index).get('quantity')?.enable();
    }
  }

  onProductSelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;
    const selectedProduct = this.productsMap[+productId];
    if (selectedProduct) {
      this.serviceItems.at(index).patchValue({
        serviceId: productId,
        quantity: 1, // Default
      });
      if (index === this.serviceItems.length - 1) {
        this.addServiceLine();
      }
    }
  }

  removeServiceItem(index: number): void {
    // Only remove if more than one invoice line exists
    if (this.serviceItems.length > 1) {
      this.serviceItems.removeAt(index);
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.serviceItemsDataSource.data = this.serviceItems.controls.map(
      (control) => control.value
    );
  }
  onSubmit() {
    if(this.servicesForm.invalid || this.serviceItems.length === 1){
      Object.values(this.servicesForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.servicesForm.getRawValue();
    // the first row of invoice lines is always the template row which we dont want to be put in the database
    if (this.serviceItems.length === 1) {
      formValue.serviceItems = [];
    } else {
      // Exclude the last row from submission if length is bigger than 1 because the last is always the template row in this case
      formValue.serviceItems = formValue.serviceItems.slice(0, -1);
    }
    const dataToSend = {
      name: formValue.name || null,
      description: formValue.description || null,
      charge: formValue.charge || Number,
      tax: formValue.tax || Number,
      serviceItems: formValue.serviceItems || null,
    };

    this.dataHelper.addNewRecord(
      this.servicesForm,
      dataToSend,
      (data) => this.serviceService.createService(data),
      'Currency created successfully',
      '/masters/services'
    );
  }

  cancel() {
    this.router.navigate(['/masters/services']);
  }
}
