import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { ServicesService } from '@services/services.service';
import { NewServiceItemComponent } from '../new-service-item/new-service-item.component';
import { DataHelper } from 'src/app/helpers/data-helper';
import { ServiceItem } from '@interfaces/ServiceItem.interface';
import { GetProduct } from '@interfaces/GetProduct.interface';

@Component({
  selector: 'app-udpate-service-item',
  templateUrl: './udpate-service-item.component.html',
  styleUrls: ['./udpate-service-item.component.css']
})
export class UdpateServiceItemComponent {

  serviceItemForm: FormGroup;
  products: any;
  categoriesMap: { [id: number]: any } = {};
  selectedCategoryId!: number;
  serviceItem!: ServiceItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private serviceService: ServicesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewServiceItemComponent>,

  ) {
    this.serviceItem = this.data.serviceItem;
    this.serviceItemForm = this.fb.group({
      id: [this.serviceItem.id],
      categoryId: [this.serviceItem.categoryId.toString(), Validators.required],
      productId: [this.serviceItem.productId.toString(), Validators.required], // Notice the double commas and misplaced validators
      quantity: [this.serviceItem.quantity, [Validators.required, Validators.min(1)]],
    });
    
  }

  ngOnInit(): void {
    this.loadCategoriesMap();
  }

  loadCategoriesMap(): void {
    this.categoriesMap = this.serviceInvoiceDataService.categories;
    console.log(this.categoriesMap)
    this.loadProducts(this.serviceItem.categoryId);
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  loadProducts(selectedCategoryId: number) {
    this.dataHelper.fetchItems(
      this.serviceService.getProductsByCategory(selectedCategoryId),
      (data: any) => {
        if (data && data.products) {
          this.products = data.products;
          console.log("Converted products:", this.products);
          // Check if the current serviceItem's productId exists in the loaded products
          const existingProduct = this.products.find((p:GetProduct) => p.id.toString() === this.serviceItem.productId.toString());
          // If the existing product is found among the loaded products, use its ID; otherwise, handle accordingly
          this.serviceItemForm.patchValue({
            productId: existingProduct ? existingProduct.id.toString() : (this.products.length > 0 ? this.products[0].id.toString() : ''),
          });
        }
      }
    );
   }
   
  
  

  onCategorySelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCategoryId = +selectElement.value;

    if (this.selectedCategoryId && this.selectedCategoryId !== null) {
      this.loadProducts(this.selectedCategoryId);
    }
  }



  onProductSelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;

    if (productId !== null) {
      const selectedProduct = this.products[+productId];

      if (selectedProduct) {
        this.serviceItemForm.patchValue({
          productId: productId,
          quantity: 1,
        });
      } else {
        console.log('Selected service not found:', productId);
      }
    }
  }

  updateValues(){
    this.dialogRef.close(this.serviceItemForm.value);
  }

  cancelAction(): void {
    this.dialogRef.close();
  }
}

