import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly router: Router
  ) {}

  logout() {
    this.keycloak.logout();
  }
}
