import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_URL } from '@apis/api-endpoints';
import { faArrowLeftRotate, faPen, faTrashCan,faPrint } from '@fortawesome/free-solid-svg-icons';
import { Invoice } from '@interfaces/Invoice.interface';
import { PaymentReceived } from '@interfaces/PaymentReceived.interface';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '@services/invoices.service';
import { PaymentReceivedService } from '@services/paymentReceived.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { concatMap } from 'rxjs';
import { DataHelper } from '../../../../helpers/data-helper';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { EmailDataService } from '@services/emailData.service';
import { EmailData } from '@interfaces/EmailData.interface';

@Component({
  selector: 'app-preview-invoice',
  templateUrl: './preview-invoice.component.html',
  styleUrls: ['./preview-invoice.component.css']
})
export class PreviewInvoiceComponent {
  today = new Date();
  invoiceId = 0;
  invoice!: Invoice;
  customerMap: { [id: number]: any } = {};
  employeeMap: { [id: number]: string } = {};
  paymentMap: { [id: number]: string } = {};
  serviceMap: { [id: number]: any } = {};
  logoUrl!: string ;
  companyName!: string;
  companyEmail!: string;
  companyAddress!: string;
  companyPhone!:string;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}
faEdit = faPen
faDelete = faTrashCan
faExit = faArrowLeftRotate
faPrint = faPrint

  constructor(
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private invoiceService: InvoiceService,
    private paymentReceivedService: PaymentReceivedService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
    private tenantSettingsService: TenantSettingsService,
    private breadcrumbService: BreadcrumbService,
    private emailDataService: EmailDataService,
  ) {

  }

  ngOnInit(): void {
    this.loadDataMaps();
    this.loadFromTenantSettings();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.invoiceId = +id;
        this.invoiceService.getByIdWithInvoiceLines(this.invoiceId)
          .subscribe((response) => {
            console.log(response)
            this.invoice = response;
            this.breadcrumbService.set('@invoiceNumber', response.invoiceNumber);
          });
          console.log(this.invoiceId)
      }
    });
  }


  loadFromTenantSettings(): void {
    this.dataHelper.fetchItems(
      this.tenantSettingsService.getTenantSettings(),
      (response: TenantSettings) => {
        this.logoUrl = `${BASE_URL}/${response.invoiceLogoPath}`;
        this.companyEmail = response.companyEmail;
        this.companyName = response.companyName;
        this.companyAddress = response.address
        this.companyPhone = response.phone
      })}


  loadDataMaps(): void {
    this.customerMap = this.serviceInvoiceDataService.customers;
    this.employeeMap = this.serviceInvoiceDataService.employees;
    this.paymentMap = this.serviceInvoiceDataService.paymentTypes;
    this.serviceMap = this.serviceInvoiceDataService.services;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  exitView(){
    this.router.navigate(['/sales/invoices']);
  }

  onSentInvoiceViaEmail() {
    const invoiceElement = document.getElementById('InvoicePreview');
    if (invoiceElement) { // Ensure the element is not null
      html2canvas(invoiceElement).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jsPDF('p', 'mm', 'a4');
        var width = pdf.internal.pageSize.getWidth();
        var height = canvas.height * width / canvas.width;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
        // pdf.save(`${this.invoice.invoiceNumber}.pdf`); // Generates PDF in client-side, uncomment if required
        this.sendEmailWithPdf(pdf.output('blob')); // Prepare to send this PDF via email
      });
    } else {
      console.error('Element #InvoicePreview not found');
    }
  }


  async sendEmailWithPdf(pdfBlob: Blob) {
    const emailData: EmailData = {
      from: this.companyEmail,
      emailAddress: this.customerMap[this.invoice.customerId].email,
      subject: `${this.invoice.invoiceNumber}.pdf`,
      body: 
      `Dear ${this.customerMap[this.invoice.customerId].fullname},
        \nPlease find the attached invoice.
        \nYou can email us at ${this.companyEmail} or call us at ${this.companyPhone} for any clarifications.
        \nRegards,\n${this.companyName}`,
      pdfBlob: pdfBlob,
      pdfTitle: this.invoice.invoiceNumber
    };
  
    this.emailDataService.setEmailData(emailData); // Set the data in the service
    this.router.navigate(
      ['/helpers/email'], 
      { queryParams: { invoiceId: this.invoiceId, invoice: this.invoice,recipientName: this.customerMap[this.invoice.customerId].fullname  } 
    }); // Navigate to the email component with query params
  }

  

  onMarkSent(){
    console.log("invoice before" , this.invoice)
    Swal.fire({
      title: 'Are you sure?',
      text: "Invoice status will be changed to sent!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm!'
      
    }).then((result) => {
      if (result.isConfirmed) {
        this.invoice.status = "SENT";
        this.invoiceService.updateInvoiceById(this.invoiceId,this.invoice).subscribe(
          () => {
            Swal.fire(
              'Success!',
              'Invoice updated successfully',
              'success'
            );
            this.router.navigate(['/sales/invoices/preview_invoice', this.invoiceId]);
          },
        );
      }
    })
  }

  recordPayment() {
    // Swal.fire({
    //   title: 'Submit the amount received from payment',
    //   html:
    //     '<input id="amoundReceived" class="swal2-input" placeholder="Amount">' +
    //     '<input id="paymentNumber" class="swal2-input" placeholder="Payment Number">',
    //   showCancelButton: true,
    //   confirmButtonText: 'Submit',
    //   showLoaderOnConfirm: true,
    //   preConfirm: () => {
    //     const amoundReceivedElement = Swal.getPopup()?.querySelector('#amoundReceived') as HTMLInputElement;
    //     const paymentNumberElement = Swal.getPopup()?.querySelector('#paymentNumber') as HTMLInputElement;
    
    //     const amoundReceivedValue = amoundReceivedElement.value;
    //     const paymentNumberValue = paymentNumberElement.value;
    
    //     // Validate or process the input values as needed
    
    //     return { amoundReceived: amoundReceivedValue, paymentNumber: paymentNumberValue };
    //   },
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     if (result.value.amoundReceived>this.invoice.due) {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Invalid Amount',
    //         text: 'Please enter ammount less than the balance due.',
    //       });
    //       return;
    //     }
    //     const paymentAmount = parseFloat(result.value.amoundReceived);
    //     if (isNaN(paymentAmount)) {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Invalid Amount',
    //         text: 'Please enter a valid number.',
    //       });
    //       return;
    //     }
    //     const payment: PaymentReceived = {
    //       id: 0,
    //       customerId: this.invoice.customerId,
    //       amountReceived: paymentAmount,
    //       paymentDate: this.today.toISOString(),
    //       paymentTypeId: this.invoice.paymentTypeId,
    //       paymentNumber: result.value.paymentNumber,
    //       invoiceId: this.invoiceId
    //     };
        
    //     this.paymentReceivedService.addPaymentReceived(payment)
    //       .subscribe(
    //         () => {
    //           // This block will be executed after the paymentReceivedService is successful
    //           Swal.fire({
    //             title: 'Check Payments Received',
    //           });
        
    //           this.invoice.paidAmount += paymentAmount;
    //     this.invoice.due = this.invoice.netTotal - this.invoice.paidAmount;
    //     if (this.invoice.due === 0) {
    //       this.invoice.status = "PAID";
    //     }
    //     else if (this.invoice.due > 0 && paymentAmount>0) {
    //       this.invoice.status = "PARTIALLY PAID";
    //     }
    //           this.invoiceService.updateInvoiceById(this.invoiceId, this.invoice)
    //             .subscribe(
    //               () => {
    //                 // This block will be executed after the updateInvoiceById is successful
    //                 this.router.navigate(['/sales/preview_invoice', this.invoiceId]);
    //               },
    //               (updateError) => {
    //                 // Handle update error and show SweetAlert
    //                 console.error('Error updating invoice:', updateError);
    //                 Swal.fire({
    //                   title: 'Error',
    //                   text: updateError,
    //                   icon: 'error',
    //                 });
    //               }
    //             );
    //         },
    //         (paymentError) => {
    //           // Handle payment error and show SweetAlert
    //           console.error('Error adding payment received:', paymentError);
    //           Swal.fire({
    //             title: 'Error',
    //             text: paymentError,
    //             icon: 'error',
    //           });
    //         }
    //       );           
    //   }
    // });
    this.router.navigate(['/sales/paymentsReceived/new_payment_received'], { queryParams: { customerId: this.invoice.customerId } });
  }
  

  onEdit(){
    this.router.navigate(['/sales/invoices/edit_invoice', this.invoice.id]);
  }

  onDelete(){
      this.dataHelper.withConfirmation(
        'Delete Confirmation',
        'Do you confirm the deletion of this invoice? Some changes may affect major functionalities',
        () => this.invoiceService.deleteInvoice(this.invoiceId),
        'Invoice deleted successfully',
        () => this.router.navigate(['/sales/invoices']),
        () => null
      );
    }

}
