import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JobTitle } from '@interfaces/JobTitle.interface'; // Adjust the import as per your project structure
import { backend } from '@apis/api-endpoints'; // Adjust the import as per your project structure

@Injectable({
  providedIn: 'root',
})
export class JobTitleService {
  constructor(private http: HttpClient) {}

  getjobTitle(
    jobTitleId: number | null
  ): Observable<JobTitle | JobTitle[]> {
    if (!jobTitleId) {
      return this.http.get<JobTitle[]>(backend.jobTitleData.getAll).pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
    }
    return this.http
      .get<JobTitle>(backend.jobTitleData.get(jobTitleId))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  createjobTitle(jobTitle: any): Observable<any> {
    return this.http
      .post(backend.jobTitleData.post, jobTitle, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updatejobTitle(
    jobTitleId: number | null,
    jobTitle: any
  ): Observable<any> {
    return this.http
      .put(backend.jobTitleData.put(jobTitleId), jobTitle, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deletejobTitle(jobTitleId: number | null): Observable<void> {
    return this.http
      .delete<void>(backend.jobTitleData.delete(jobTitleId))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
