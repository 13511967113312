import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { OrderItems } from '@interfaces/OrderItems.interface';
import Swal from 'sweetalert2';
import { OrderService } from '@services/order.service';
import { NewOrderItemComponent } from '../order-item/new-order-item/new-order-item.component';
import { EditOrderItemComponent } from '../order-item/edit-order-item/edit-order-item.component';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Observable, Subscription, pairwise, startWith, tap } from 'rxjs';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { AdminDataService } from '@services/adminData.service';
import { Order } from '@interfaces/Order.interface';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css'],
})
export class NewOrderComponent implements OnInit {
  today = new Date();
  defaultVat: number = 0;
  previousDiscount: number = 0;
  private formChangesSubscription!: Subscription;

  orderForm: FormGroup;
  orderItemsDataSource: MatTableDataSource<OrderItems>;


  displayedColumns: string[] = [
    'productId', 
    'quantity', 
    'total', 
    'options'
  ];

  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  vatsMap: { [id: number]: { name: string; value: number } } = {}; 
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.orderForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.orderForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private orderService: OrderService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private tenantSettingsService: TenantSettingsService,
    private cdRef: ChangeDetectorRef,
    private adminDataService: AdminDataService,

  ) {
    this.orderItemsDataSource = new MatTableDataSource<OrderItems>([]);
    this.orderForm = this.fb.group({
      purchaseOrderNumber: ['', Validators.required],
      supplierId: ['', Validators.required],
      date: [this.today, Validators.required],
      expectedDeliveryDate: [''],
      customerNotes: [''],
      orderItems: this.fb.array([]),
      subTotal: [''], //grand total
      totalDiscount: [''],
      total: [''], //net total
      totalTax: [''],
      status: ['DRAFT'],
      isReceived: [0],
      isBilled: [0],
    });
  }

  ngOnInit(): void {
    this.tenantSettingsService
    .getTenantSettings()
    .pipe(
      tap((response: TenantSettings) => {
        this.defaultVat = response.vatId;
        this.orderItems.push(this.createOrderItem());
        this.loadDataMaps();
        this.loadAutoIncreamentNumber();
        this.setupFormChanges();
      })
    )
    .subscribe();
  }

  get orderItems() {
    return this.orderForm.get('orderItems') as FormArray;
  }


  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
    this.vatsMap = this.adminDataService.vats;
  }

  loadAutoIncreamentNumber(): void {
    //'1' represents the orders prefix in the lookup table of the backend (accountant_scopes)
    this.tenantSettingsService.getAutoIncreamentNumbersData(1)
      .subscribe((response: string) => {
        this.orderForm.patchValue({ 
          purchaseOrderNumber: response
        });
      }, error => {
        console.error('Failed to load order number:', error);
      });
  }

  createOrderItem(): FormGroup {
    const orderItemFormGroup = this.fb.group({
      productId: [0, Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      productCharge: [0, [Validators.min(0)]],
      discountType: ['%', Validators.required], // Default to percentage
      discount: [0, [Validators.min(0), Validators.max(100)]], // Appropriate for percentage
      vatId: [this.defaultVat, Validators.min(0)],
      total: [{value: 0, disabled: true}],
      description: [''],
    });
    this.setupDiscountTypeListener(orderItemFormGroup); 
    return orderItemFormGroup;
  }

  getProductNameById(item: any): string {
    return this.  productsMap[item.get('productId')?.value].name || '';
  }

  addOrderItem(): void {
    this.orderItems.push(this.createOrderItem());
  }

  onProductSelectionChange(index: number, event: any): void {
    
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;
    const selectedProduct = this.productsMap[+productId];
   
    if (selectedProduct) {
      this.orderItems.at(index).patchValue({
        productId: productId,
        quantity: 1, // Default
        discount: 0, // Default
        discountType: '%', //Default
        productCharge: Number(selectedProduct.price),
        vatId: this.defaultVat || 0,
        // Automatically calculate total in calculateAndSetAmount
      });
     
      // Enable other controls
      this.orderItems.at(index).get('quantity')?.enable();
      this.orderItems.at(index).get('discount')?.enable();
      this.orderItems.at(index).get('discountType')?.enable();
      this.orderItems.at(index).get('productCharge')?.enable();
      this.orderItems.at(index).get('vatId')?.enable();
      this.orderItems.at(index).get('description')?.enable();
      this.orderItems.at(index).get('total')?.enable();

      if (index === this.orderItems.length - 1) {
        this.addOrderItem();
      }

      this.calculateAndSetAmount(index);
    }
  }

  onVatChange(event: any, index: number): void {
    this.orderItems.at(index).get('vatId')?.setValue(event.target.value);
    this.calculateAndSetAmount(index);
    this.cdRef.detectChanges();
  }

  // Method to calculate and update the amount for a specific Order item
  calculateAndSetAmount(index: number): void {
    const orderItem = this.orderItems.at(index);
    const quantity = orderItem.get('quantity')?.value;
    const productCharge = orderItem.get('productCharge')?.value;
    const discount = orderItem.get('discount')?.value;
    const discountType = orderItem.get('discountType')?.value;
  
    let total = productCharge * quantity;
    
    if (discountType === '%') {
      total -= total * (discount / 100);
    } else {
      total -= discount; // Assuming discount is a fixed amount here
    }
  
    orderItem.patchValue({
      total: Number(total.toFixed(2)),
    });
    this.calculateTotals();
  }

  

  setupFormChanges(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe(); // Ensure no memory leaks
    }

    this.formChangesSubscription = this.orderItems.valueChanges
      .pipe(startWith([]), pairwise())
      .subscribe(([prevItems, currentItems]) => {
        currentItems.forEach(
          (
            item: OrderItems,
            index: number
          ) => {
            if (
              !prevItems[index] ||
              item.quantity !== prevItems[index].quantity ||
              item.discount !== prevItems[index].discount ||
              item.discountType !== prevItems[index].discountType ||
              item.productCharge !== prevItems[index].productCharge ||
              item.vatId !== prevItems[index].vatId
            ) {
              this.calculateAndSetAmount(index);
            }
          }
        );
      });
  }

  setupDiscountTypeListener(orderItemFormGroup: FormGroup): void {
    orderItemFormGroup.get('discountType')?.valueChanges.subscribe((type) => {
      const discountControl = orderItemFormGroup.get('discount');
      const maxValueAllowed = orderItemFormGroup.get('total')?.value;
      // Preserve the current discount value
      const currentValue = discountControl?.value;
  
      // Apply both min and conditionally max validators together
      let validators = [Validators.min(0)]; // Minimum discount is always 0%
      if (type === '€' && maxValueAllowed) {
        validators.push(Validators.max(maxValueAllowed));
      } else if (type === '%') {
        validators.push(Validators.max(100)); // For percentage, the max can be 100%
      }
  
      discountControl?.setValidators(Validators.compose(validators));
      discountControl?.updateValueAndValidity();
  
      // Force re-validation against the new rules but keep the user's input
      discountControl?.setValue(currentValue);
  
      // Recalculate the Item total
      const index = this.orderItems.controls.indexOf(orderItemFormGroup);
      if (index !== -1) {
        this.calculateAndSetAmount(index);
      }
    });
  }

  removeOrderItem(index: number): void {
    // Only remove if more than one order item exists
    if (this.orderItems.length > 1) {
      this.orderItems.removeAt(index);
       this.calculateTotals();
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.orderItemsDataSource.data = this.orderItems.controls.map(
      (control) => control.value
    );
  }

  calculateTotals(): void {
    let netTotal = 0;
    const vats = new Map<number, number>(); // VAT rate to total amount map
    let grandTotal = 0;

    this.orderItems.controls.forEach((control) => {
      const orderItem = control.value;
      const orderItemNetAmount = parseFloat(orderItem.total) || 0;
      netTotal += orderItemNetAmount;

      const vatId = +orderItem.vatId || 0;
      if (this.vatsMap[vatId]) {
        const vatRate = this.vatsMap[vatId].value;
        if (vatRate > 0.0) {
          const vatAmount = orderItemNetAmount * (vatRate / 100);
          // Check if the vatRate is already present and sum the amounts
          if(vatAmount>0.0){
            if (vats.has(vatRate)) {
              const existingAmount = vats.get(vatRate);
              vats.set(vatRate, (existingAmount || 0) + vatAmount);
            } else {
              vats.set(vatRate, vatAmount);
            }
          }
        }
      }
    });

    let totalVatAmount = 0;
    vats.forEach((amount) => (totalVatAmount += amount));

    grandTotal = netTotal + totalVatAmount;

    this.orderForm.patchValue({
      subTotal: netTotal.toFixed(2),
      total: grandTotal.toFixed(2),
      totalTax: Array.from(vats.entries()),
    });
  }


  onSubmit() {
    if(this.orderForm.invalid || this.orderItems.length === 1){
      Object.values(this.orderForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.orderForm.getRawValue();
    // the first row of orderItems is always the template row which we dont want to be put in the database
    if (this.orderItems.length === 1) {
      formValue.orderItems = [];
    } else {
      // Exclude the last row from submission if length is bigger than 1 because the last is always the template row in this case
      formValue.orderItems = formValue.orderItems.slice(0, -1);
    }
    if(formValue.totalTax){
      let totalTax:number = 0;
      for (let taxEntry of formValue.totalTax) {
            totalTax+=taxEntry[1];
      }
      formValue.totalTax = null;
      formValue.totalTax = totalTax;
    }
    // Adjust orderItems to include calculated VAT amount instead of vatId
    formValue.orderItems.forEach((item:any) => {
      const vatRate = this.vatsMap[item.vatId]?.value || 0;
      const vatAmount = item.total * (vatRate / 100);
      item.vat = vatAmount; // Assign calculated VAT amount to Item
    });

    const orderData = {
      supplierId: formValue.supplierId || null,
      purchaseOrderNumber: formValue.purchaseOrderNumber || null,
      date: new Date(formValue.date).toISOString(),
      expectedDeliveryDate: new Date(
        formValue.expectedDeliveryDate
      ).toISOString(),
      customerNotes: formValue.customerNotes || 'no notes',
      subTotal: formValue.subTotal || null,
      totalDiscount: formValue.totalDiscount || 0,
      total: formValue.total || null,
      totalTax: formValue.totalTax || null,
      status: formValue.status || null,
      isReceived: 0,
      isBilled: 0,
      orderItems: formValue.orderItems || [],
    };

    console.log(orderData);

    this.dataHelper.addNewRecord(
      this.orderForm,
      orderData,
      (orderData) => this.orderService.addOrder(orderData),
      'Order created successfully',
      '/purchases/orders',
      () => {
        //'1' represents the orders in the lookup table of the backend (accountant_scopes)
        this.tenantSettingsService.updatAutoIncreamentNumbersData(1).subscribe({
          next: (updateResponse) => {
            console.log('Auto-increment number updated successfully', updateResponse);
          },
          error: (updateError) => {
            console.error('Error updating auto-increment number', updateError);
          }
        });
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/purchases/orders']);
  }

  ngOnDestroy(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
