import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { PurchaseReceiveService } from '@services/purchaseReceive.service';
import { PurchaseReceivesItem } from '@interfaces/PurchaseReceivesItem.interface';
import { OrderService } from '@services/order.service';
import { GetOrder } from '@interfaces/GetOrder.interface';
import { ExtendedOrderItems } from '@interfaces/ExtendedOrderItems.interface';
import { PurchaseReceive } from '@interfaces/PurchaseReceive.interface';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-purchase-receive',
  templateUrl: './new-purchase-receives.component.html',
  styleUrls: ['./new-purchase-receives.component.css'],
})
export class NewPurchaseReceivesComponent implements OnInit {
  purchaseReceiveForm: FormGroup;
  selectedSupplierId!: number;
  selectedOrderId: number = 0;
  selectedOrderNumber!: string;
  displayedColumns: string[] = ['productId', 'quantity', 'total', 'options'];
  purchaseReceivedItemsDataSource: MatTableDataSource<PurchaseReceivesItem>;
  unreceivedOrders: GetOrder[] = [];
  unreceivedOrderItems: ExtendedOrderItems[] = [];
  today = new Date();
  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  wrongQuantities: boolean = true;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private purchaseReceiveService: PurchaseReceiveService,
    private orderService: OrderService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private tenantSettingsService: TenantSettingsService
  ) {
    this.purchaseReceivedItemsDataSource =
      new MatTableDataSource<PurchaseReceivesItem>([]);
    this.purchaseReceiveForm = this.fb.group({
      purchaseReceivedNumber: [
        { value: '', disabled: true },
        Validators.required,
      ],
      supplierId: ['', Validators.required], // supplierId remains enabled
      orderId: [{ value: '', disabled: true }, Validators.required],
      receivedDate: [{ value: '', disabled: true }, Validators.required],
      internalNotes: [{ value: '', disabled: true }],
      purchaseReceivedItems: [
        { value: this.fb.array([]), disabled: true },
        Validators.required,
      ],
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.purchaseReceiveForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.purchaseReceiveForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.loadDataMaps();
    this.loadAutoIncreamentNumber();
  }

  loadAutoIncreamentNumber(): void {
    //3' represents the purchase  receives prefix in the lookup table of the backend (accountant_scopes)
    this.tenantSettingsService.getAutoIncreamentNumbersData(3)
      .subscribe((response: string) => {
        this.purchaseReceiveForm.patchValue({ 
          purchaseReceivedNumber: response
        });
      }, error => {
        console.error('Failed to load invoice number:', error);
      });
  }



  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
  }

  get purchaseReceivedItems() {
    return this.purchaseReceiveForm.get('purchaseReceivedItems') as FormArray;
  }

  fetchOrders(supplierId: number = this.selectedSupplierId): void {
    this.dataHelper.fetchItems(
      this.purchaseReceiveService.getUnreceivedOrdersBySupplier(supplierId),
      (response: GetOrder[]) => {
        this.unreceivedOrders = response;

        if (!this.unreceivedOrders.length) {
          this.purchaseReceiveForm.get('orderId')?.disable();
        } else {
          this.purchaseReceiveForm.get('orderId')?.enable();
        }
      }
    );
  }

  fetchOrderItems(
    supplierId: number = this.selectedSupplierId,
    orderId: number = this.selectedOrderId
  ): void {
    this.dataHelper.fetchItems(
      this.purchaseReceiveService.getUnreceivedOrderItems(supplierId, orderId),
      (response: ExtendedOrderItems[]) => {
        this.unreceivedOrderItems = response;
        console.log(this.unreceivedOrderItems);
      }
    );
  }

  onSupplierSelected() {
    if (this.selectedSupplierId) {
      this.fetchOrders();
      this.selectedOrderNumber = '';
      this.purchaseReceiveForm.get('orderId')?.enable();
    } else {
      this.selectedOrderNumber = '';
      this.purchaseReceiveForm.get('orderId')?.disable();
      this.unreceivedOrders = [];
    }
  }

  onOrderSelected() {
    if (this.selectedOrderNumber) {
      const selectedOrder = this.unreceivedOrders.find(
        (order) => order.purchaseOrderNumber === this.selectedOrderNumber
      );
      if (selectedOrder) {
        this.selectedOrderId = selectedOrder.id;
      
      this.purchaseReceiveForm.enable(); // Enable all form controls
      this.purchaseReceiveForm.get('orderId')?.enable(); // Ensure customer ID field remains enabled
      this.purchaseReceiveForm.get('supplierId')?.enable();
      this.fetchOrderItems();
      console.log(this.selectedOrderNumber);
      }
    } else {
      this.unreceivedOrderItems = [];
      this.purchaseReceiveForm.disable(); // Disable all form controls
      this.purchaseReceiveForm.get('orderId')?.enable(); // Ensure customer ID field remains enabled
      this.purchaseReceiveForm.get('supplierId')?.enable();
    }
  }

  updateItemQuantities(itemId: number, event: any) {
    const quantityToReceiveInput = event.target as HTMLInputElement;
    const quantityToReceive = parseFloat(quantityToReceiveInput.value);
  
    if (itemId) {
      const item = this.unreceivedOrderItems.find(i => i.id === itemId);
      if (!quantityToReceive) {
        this.wrongQuantities = true;
        Swal.fire(
          'Error',
          'Received quantities cannot be empty',
          'error'
        );
        return;
      }
      if (item && quantityToReceive) {
        const remainingQuantity = item.quantity - item.receivedQuantity;
  
        if (quantityToReceive < 1) {
          this.wrongQuantities = true;
          Swal.fire(
            'Error',
            'Received quantities cannot be less than 1',
            'error'
          );
          return;
        }
        if (quantityToReceive > remainingQuantity) {
          this.wrongQuantities = true;
          Swal.fire(
            'Error',
            'Received quantities cannot exceed remaining quantity.',
            'error'
          );
          return;
        }
        this.wrongQuantities = false;
      }
    }
  }


  onSubmit() {
    if(this.purchaseReceiveForm.invalid || this.wrongQuantities){
      Object.values(this.purchaseReceiveForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.purchaseReceiveForm.getRawValue();
  
    // Initialize an empty array to store purchaseReceivedItems
    const purchaseReceivedItems: any[] = [];
  
    // Iterate through unreceivedOrderItems and gather the input values
    for (const item of this.unreceivedOrderItems) {
      const quantityToReceiveInput = document.getElementById(`quantityToReceive-${item.id}`) as HTMLInputElement;
      const quantityToReceive = parseFloat(quantityToReceiveInput.value) || 0;
  
      // Create an item object based on the input values
      const purchaseReceivedItem = {
        id: 0,
        receivedQuantity: quantityToReceive,
        orderItemId: item.id,
      };
  
      // Push the item object to the purchaseReceivedItems array
      purchaseReceivedItems.push(purchaseReceivedItem);
    }
  
    // Create the purchaseReceiveData object with the gathered data
    const purchaseReceiveData: PurchaseReceive = {
      id: 0,
      supplierId: +this.selectedSupplierId || 0,
      orderId: this.selectedOrderId || 0,
      purchaseReceivedNumber: formValue.purchaseReceivedNumber || '',
      receivedDate: new Date(formValue.receivedDate).toISOString(),
      internalNotes: formValue.internalNotes || 'no notes',
      purchaseReceivedItems: purchaseReceivedItems,
    };
  
    console.log(purchaseReceiveData);
  
    this.purchaseReceiveService.addPurchaseReceive(purchaseReceiveData)
      .subscribe(
        () => {
            //'3' represents the purchase receives prefix in the lookup table of the backend (accountant_scopes)
            this.tenantSettingsService.updatAutoIncreamentNumbersData(3).subscribe({
              next: (updateResponse) => {
                Swal.fire('Success', 'Purchase Receive created successfully.', 'success')
                .then(() => {
                  this.purchaseReceiveForm.markAsPristine();
                  this.purchaseReceiveForm.markAsUntouched();
                  this.purchaseReceiveForm.reset();
                  this.router.navigate(['/purchases/purchaseReceives'])
                });              
              },
              error: (updateError) => {
                Swal.fire('Error', updateError , 'error')              
              }
            });
          
         
        },
        error => Swal.fire('Error', error , 'error')
      );
  }
  
  

  onCancel(): void {
    this.router.navigate(['/purchases/purchaseReceives']);
  }
}
