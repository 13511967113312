import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandService } from '@services/brand.service'; // Adjust the import as per your project structure
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-brand',
  templateUrl: './new-brand.component.html',
  styleUrls: ['./new-brand.component.css'],
})
export class NewBrandComponent implements OnInit {
  brandForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private brandService: BrandService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.brandForm = this.fb.group({
      name: ['', Validators.required],
    });
  }
  
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.brandForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.brandForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {}

  onSubmit() {
    if(!this.brandForm.valid){
      Object.values(this.brandForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }

    const formValue = this.brandForm.getRawValue();
    const dataToSend = {
      name: formValue.name || 'string',
    };

    this.dataHelper.addNewRecord(
      this.brandForm,
      dataToSend,
      (data) => this.brandService.createBrand(data),
      'Brand created successfully',
      '/masters/brands'
    );
  }

  cancel() {
    this.router.navigate(['/masters/brands']);
  }
}
