import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@services/order.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { ServicesService } from '@services/services.service';
import { DataHelper } from 'src/app/helpers/data-helper';

@Component({
  selector: 'app-new-service-item',
  templateUrl: './new-service-item.component.html',
  styleUrls: ['./new-service-item.component.css'],
})
export class NewServiceItemComponent {
  serviceItemForm: FormGroup;
  products: any;
  categoriesMap: { [id: number]: any } = {};
  selectedCategoryId!: number;

  constructor(
    private fb: FormBuilder,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private serviceService: ServicesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewServiceItemComponent>,

  ) {
    this.serviceItemForm = this.fb.group({
      categoryId: [''],
      productId: ['', Validators.required],
      quantity: ['0', [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit(): void {
    this.loadCategoriesMap();
  }

  loadCategoriesMap(): void {
    this.categoriesMap = this.serviceInvoiceDataService.categories;
    console.log(this.categoriesMap)
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  loadProducts(selectedCategoryId: number) {
    this.dataHelper.fetchItems(
      this.serviceService.getProductsByCategory(selectedCategoryId),
      (data: any) => {
        // Access the products array directly
        if (data && data.products) {
          this.products = data.products;
          console.log("Converted products:", this.products);
        }
      }
    );
  }
  
  

  onCategorySelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCategoryId = +selectElement.value;

    if (this.selectedCategoryId && this.selectedCategoryId !== null) {
      this.loadProducts(this.selectedCategoryId);
    }
  }



  onProductSelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;

    if (productId !== null) {
      const selectedProduct = this.products[+productId];

      if (selectedProduct) {
        this.serviceItemForm.patchValue({
          productId: productId,
          quantity: 1,
        });
      } else {
        console.log('Selected service not found:', productId);
      }
    }
  }

  updateValues(){
    this.dialogRef.close(this.serviceItemForm.value);
  }

  cancelAction(): void {
    this.dialogRef.close();
  }
}
