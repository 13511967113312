import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { PaymentType } from '@interfaces/PaymentType.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(backend.paymentData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getById(paymentTypeId: number): Observable<PaymentType> {
    return this.http.get<PaymentType>(backend.paymentData.getById(paymentTypeId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  createPayment(paymentData: PaymentType): Observable<PaymentType> {
    return this.http.post<PaymentType>(backend.paymentData.post, paymentData).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  updatePayment(paymentTypeId: number, updatedPaymentData: PaymentType): Observable<PaymentType> {
    return this.http.put<PaymentType>(backend.paymentData.updateById(paymentTypeId), updatedPaymentData).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  deletePayment(paymentTypeId: number): Observable<void> {
    return this.http.delete<void>(backend.paymentData.deleteById(paymentTypeId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
