import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { PaymentReceivedResponse } from '@interfaces/PaymentReceivedResponse.interface';
import { PaymentReceived } from '@interfaces/PaymentReceived.interface';
import { GetPaymentReceived } from '@interfaces/GetPaymentReceived.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentReceivedService {
  constructor(private http: HttpClient) {}

  getPaymentsReceived(pageNumber: number,pageSize: number): Observable<PaymentReceivedResponse> {
    return this.http.get<PaymentReceivedResponse>(backend.paymentReceived.getAll(pageNumber,pageSize)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  addPaymentReceived(payment: PaymentReceived): Observable<any> {
    return this.http
      .post(backend.paymentReceived.post, payment, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deletePaymentReceived(paymentReceivedId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.paymentReceived.deleteById(paymentReceivedId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getPaymentReceivedById(paymentReceivedId: number): Observable<GetPaymentReceived> {
    return this.http
      .get<GetPaymentReceived>(`${backend.paymentReceived.getById(paymentReceivedId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updatePaymentReceivedById(paymentReceivedId: number, payment: PaymentReceived): Observable<any> {
    return this.http
      .put(backend.paymentReceived.updateById(paymentReceivedId), payment, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
