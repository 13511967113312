import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { PaymentType } from '@interfaces/PaymentType.interface';
import { PaymentDataService } from '@services/paymentData.service';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.css'],
})
export class PaymentTypeComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
];

  payments: PaymentType[] = [];
  filteredPayments: PaymentType[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private paymentDataService: PaymentDataService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchPayments();
  }

  fetchPayments(): void {
    this.dataHelper.fetchItems(
      this.paymentDataService.getAll(),
      (data: PaymentType[]) => {
        this.payments = data;
        this.filteredPayments = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredPayments = this.payments.filter((payment) =>
      payment.name.toLowerCase().includes(filterValue)
    );
  }

  deletePayment(element: PaymentType): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this payment type? Some changes may affect major functionalities',
      () => this.paymentDataService.deletePayment(element.id),
      'Payment Type deleted successfully',
      () => this.fetchPayments(),
      () => null
    );
  }

  addPayment() {
    this.router.navigate(['/masters/paymentTypes/new_payment_type']);
  }

  editPayment(element: PaymentType): void {
    this.router.navigate(['/masters/paymentTypes/edit_payment_type', element.id]);
  }
}
