import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { CustomersResponse } from '@interfaces/CustomerResponse.interfaece';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomers(
    pageNumber: number,
    pageSize: number
  ): Observable<CustomersResponse> {
    return this.http
      .get<CustomersResponse>(backend.customer.getAll(pageNumber, pageSize))
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addCustomer(customer: any): Observable<any> {
    return this.http
      .post(backend.customer.post, customer, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteCustomer(customerId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.customer.deleteById(customerId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getCustomerById(customerId: number): Observable<CustomersResponse> {
    return this.http
      .get<CustomersResponse>(`${backend.customer.getById(customerId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateCustomerById(customerId: number, customer: any): Observable<any> {
    return this.http
      .put(backend.customer.updateById(customerId), customer, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
