import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'app-new-order-item',
  templateUrl: './new-order-item.component.html',
  styleUrls: ['./new-order-item.component.css'],
})
export class NewOrderItemComponent {
  orderItemForm: FormGroup;
  productMap: { [id: number]: any } = {};

  constructor(
    private fb: FormBuilder,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<NewOrderItemComponent>
  ) {
    this.orderItemForm = this.fb.group({
      quantity: ['0', [Validators.required, Validators.min(1)]],
      total: [0,[Validators.required, Validators.min(1)]],
      productId: [''],
    });
  }

  ngOnInit(): void {
    this.loadDataMaps();
    console.log(this.productMap)
  }

  loadDataMaps(): void {
    this.productMap = this.orderService.products;
  }

  onProductSelectionChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const productId = selectElement.value;

    if (productId !== null) {
      const selectedProduct = this.productMap[+productId];

      if (selectedProduct) {
        this.orderItemForm.patchValue({
          quantity: 1,
          total: Number(selectedProduct.price),
        });
      } else {
        console.log('Selected service not found:', productId);
      }
    }
  }

  updateValues(){
    this.dialogRef.close(this.orderItemForm.value);
  }

  cancelAction(): void {
    this.dialogRef.close();
  }
}
