// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scrollable-container {
    overflow-x: auto;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/app/components/equipment/equipment.component.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;EAClB","sourcesContent":["\r\n.scrollable-container {\r\n    overflow-x: auto;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
