import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Column } from '@interfaces/Column.interface';
import { SearchService } from '@services/search.service';
import { Subscription } from 'rxjs';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
/**
 * re-usable component for tables
 * Usage:
 * <app-reusable-table
  [data]="data"
  [columns]="displayedColumns"
  [searchableFields]="['col1', 'col2', 'col3']"
  (edit)="edit($event)"
  (delete)="delete($event)"
  (add)="add()"
  [showPdfButton]="true"
  (downloadPdf)="downloadPdf($event)"
  [paginator]="true"
  [totalItems]="totalInvoices"
  [itemsPerPage]="pageSize"
  [currentPage]="currentPage"
  (pageChange)="pageChanged($event)"
>
</app-reusable-table>

 */
@Component({
  selector: 'app-reusable-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.css']
})
export class PaginatedTableComponent implements OnInit, OnDestroy {
  private _data: any[] = [];

  @Input() 
  set data(value: any[]) {
    this._data = value;
    // Apply the filter whenever new data is set
    this.filteredData = this.dynamicFilter(this._data, this.searchService.currentSearchTerm, this.searchableFields);
  }

  get data(): any[] {
    return this._data;
  }

  @Input() columns: Column[] = [];
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 10; // this must be the same with the pagination-data.ts (static pageSize )
  @Input() pageSizeOptions: number[] = [5, 10, 25, 100];
  @Input() isLoading: boolean = false;
  @Input() searchableFields: string[] = []; 
  @Input() itemsPerPage: number = 0; 
  @Input() currentPage: number = 0;
  @Input() paginator: boolean = false;
  @Input() showEditButton = true;
  @Input() showDeleteButton = true;
  @Input() showPdfButton = false
  @Input() Title: string | null = "";
  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() add: EventEmitter<void> = new EventEmitter();
  @Output() downloadPdf: EventEmitter<any> = new EventEmitter();
  @Output() showInDashboardToggle: EventEmitter<any> = new EventEmitter();

  filteredData: any[] = [];
  columnPropsWithActions: string[] = [];

  faAddNew = faPlus
  faSearch = faMagnifyingGlass
  
  private subscriptions = new Subscription();

  constructor(private searchService: SearchService,private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    // Subscribe to the search service and update filtered data accordingly
    this.subscriptions.add(
      this.searchService.searchObservable$.subscribe(term => {
        this.filteredData = this.dynamicFilter(this._data, term, this.searchableFields);
        this.cd.detectChanges();
      })
    );
    this.setColumnPropsWithActions();
  }

  ngOnDestroy(): void {
    // Clean up subscriptions
    this.subscriptions.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchService.updateSearchTerm(filterValue);
  }

  dynamicFilter(dataArray: any[], filterValue: string, searchableFields: string[]): any[] {
    const lowerCaseFilterValue = filterValue.toLowerCase();
  
    return dataArray.filter(item => {
      return searchableFields.some(field => {
        // If the field is a special 'mapped' field, we need to handle it differently
        const column = this.columns.find(col => col.prop === field);
        if (column && column.isMap && column.mapData) {
          // Get the display value using the map
          const displayValue = column.mapData[item[field]];
          return displayValue && displayValue.toLowerCase().includes(lowerCaseFilterValue);
        } else {
          // Existing logic for regular fields
          const fieldValue = item[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            return fieldValue.toString().toLowerCase().includes(lowerCaseFilterValue);
          }
        }
        return false;
      });
    });
  }
  

  private setColumnPropsWithActions() {
    this.columnPropsWithActions = this.columns.map(column => column.prop);
    // this.columnPropsWithActions.push('actions'); // assuming 'actions' is the fixed name of your actions column
  }

  getImage(row: any) {
    if(row.picturePath === ''){
      return false;
    }
    return `<img src="${row.picturePath}" alt="Profile Picture" width="50" height="50">`;
}

  onPageChange(event: any) {
    this.page.emit(event);
  }

  onEdit(item: any) {
    this.edit.emit(item);
  }

  onDelete(item: any) {
    this.delete.emit(item);
  }

// Update this method to accept the new toggle value as a parameter
onShowInDashboardToggle(item: any, newValue: boolean) {
  // Emit an event with both the item and the new value
  this.showInDashboardToggle.emit({ item, newValue });
}


  onAdd() {
    this.add.emit();
  }

  onDownloadPdf(item: any){
    this.downloadPdf.emit(item);
  }
  getCircleClass(value: number): string {
    if (value === 1) {
      return 'half-blue';
    } else if (value === 2) {
      return 'blue';
    } else {
      return 'grey';    }
  }
  
  
}
