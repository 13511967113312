import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { Vat } from '@interfaces/Vat.interface';

@Injectable({
  providedIn: 'root',
})
export class VatService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<Vat[]> {
    return this.http.get<Vat[]>(backend.vatData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getById(VatId: number): Observable<Vat> {
    return this.http.get<Vat>(backend.vatData.getById(VatId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  createVat(vatData: Vat): Observable<Vat> {
    return this.http.post<Vat>(backend.vatData.post, vatData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  updateVat(VatId: number, updatedVatData: Vat): Observable<Vat> {
    return this.http.put<Vat>( backend.vatData.updateById(VatId), updatedVatData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  deleteVat(VatId: number): Observable<void> {
    return this.http.delete<void>( backend.vatData.deleteById(VatId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
