import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userRoleSubject = new BehaviorSubject<string | null>(null);
  userRole$ = this.userRoleSubject.asObservable();

  private userNameSubject = new BehaviorSubject<string | null>(null);
  userName$ = this.userNameSubject.asObservable();

  private tenantIdSubject = new BehaviorSubject<number | null>(null);
  tenantId$ = this.tenantIdSubject.asObservable();

  private emailVerifiedSubject = new BehaviorSubject<boolean | null>(null);
  emailVerified$ = this.emailVerifiedSubject.asObservable();

  private nameSubject = new BehaviorSubject<string | null>(null);
  name$ = this.nameSubject.asObservable();

  private preferredUsernameSubject = new BehaviorSubject<string | null>(null);
  preferredUsername$ = this.preferredUsernameSubject.asObservable();

  private localeSubject = new BehaviorSubject<string | null>(null);
  locale$ = this.localeSubject.asObservable();

  private givenNameSubject = new BehaviorSubject<string | null>(null);
  givenName$ = this.givenNameSubject.asObservable();

  private familyNameSubject = new BehaviorSubject<string | null>(null);
  familyName$ = this.familyNameSubject.asObservable();

  private emailSubject = new BehaviorSubject<string | null>(null);
  email$ = this.emailSubject.asObservable();

  private rolesSubject = new BehaviorSubject<string[] | null>(null);
  roles$ = this.rolesSubject.asObservable();

  setUserName(userName: string) {
    this.userNameSubject.next(userName);
  }

  setUserRole(role: string) {
    this.userRoleSubject.next(role);
  }

  setTenantId(tenantId: number) {
    this.tenantIdSubject.next(tenantId);
  }

  setEmailVerified(emailVerified: boolean) {
    this.emailVerifiedSubject.next(emailVerified);
  }

  setName(name: string) {
    this.nameSubject.next(name);
  }

  setPreferredUsername(preferredUsername: string) {
    this.preferredUsernameSubject.next(preferredUsername);
  }

  setLocale(locale: string) {
    this.localeSubject.next(locale);
  }

  setGivenName(givenName: string) {
    this.givenNameSubject.next(givenName);
  }

  setFamilyName(familyName: string) {
    this.familyNameSubject.next(familyName);
  }

  setEmail(email: string) {
    this.emailSubject.next(email);
  }

  setRoles(roles: string[]) {
    this.rolesSubject.next(roles);
  }
}
