import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '@services/category.service'; // Adjust the import as per your project structure
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../../helpers/data-helper';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { Category } from '@interfaces/Category.interface';
import { EquipmentCategoryService } from '@services/equipmentCategory.service';
import { EquipmentCategory } from '@interfaces/EquipmentCategory.interface';

@Component({
  selector: 'app-edit-equipment-category',
  templateUrl: './edit-equipment-category.component.html',
  styleUrls: ['./edit-equipment-category.component.css'],
})
export class EditEquipmentCategoryComponent implements OnInit {
  categoryForm!: FormGroup;
  categoryId!: number;
  categoryName: string = ''; 
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: EquipmentCategory | null = null;
  
  constructor(
    private fb: FormBuilder,
    private categoryService: EquipmentCategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.categoryForm = this.fb.group({
      name: ['', Validators.required],
    });
  }
  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.categoryForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.categoryForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.categoryId = Number(params.get('id')); // Extract categoryId from the route
      console.log(this.categoryId)
      if (!isNaN(this.categoryId)) {
        this.categoryService
          .getById(this.categoryId)
          .subscribe((category) => {
            console.log(category)
            if (Array.isArray(category) && category.length > 0) {
              // If category is an array, get the name from the first element of the array
              this.categoryName = category[0].name;
              
              this.categoryForm.patchValue({ name: this.categoryName}); // Prefill the "Name" field
              this.initialFormData = { ...category[0] };

            } else if (category) {
              // If category is a single object, get the name from it
              this.categoryName = category.name;
              this.categoryForm.patchValue({ name: this.categoryName}); // Prefill the "Name" field
              this.initialFormData = { ...category };
            }
            this.breadcrumbService.set('@categoryName', this.categoryName);
          });
      } else {
        console.error('Invalid categoryId');
      }
    });
  }


  onSubmit() {
    if(!this.categoryForm.valid){
      Object.values(this.categoryForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.categoryForm.valid) {
      const updatedCategory = this.categoryForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this category? Some changes may affect major functionalities',
        () =>
          this.categoryService.updateCategory(this.categoryId, updatedCategory),
        'Category updated successfully',
        () => {
          this.categoryForm.markAsPristine();
          this.categoryForm.markAsUntouched();
          this.categoryForm.reset();
          this.router.navigate(['/masters/equipmentCategories']);
        }
      );
    }
  }

  cancel() {
    if (this.categoryForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.categoryForm.patchValue(this.initialFormData);
    }
    this.categoryForm.markAsPristine();
    this.categoryForm.markAsUntouched();
    this.isEditMode = false;
  }


  exitView(){
    this.router.navigate(['/masters/equipmentCategories']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this category? Some changes may affect major functionalities',
      () => this.categoryService.deleteCategory(this.categoryId),
      'Category deleted successfully',
      () => this.router.navigate(['/masters/equipmentCategories']),
      () => null
    );
  }
}
