import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { OrderService } from '@services/order.service';
import { BillLines } from '@interfaces/BillLines.interface';
import { BillService } from '@services/bill.service';
import { Bill } from '@interfaces/Bill.interface';
import { ExtendedPurchaseReiceve } from '@interfaces/ExtendedPurchaseReiceive.interface';
import { ExtendedPurchaseReceiveItem } from '@interfaces/ExtendedPurchaseReceiveItem.interface';
import { GetOrder } from '@interfaces/GetOrder.interface';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-bill-receive',
  templateUrl: './edit-bill.component.html',
  styleUrls: ['./edit-bill.component.css'],
})
export class EditBillComponent implements OnInit {
  billForm!: FormGroup;
  order!: GetOrder;
  billId!: number;
  bill!: Bill;
  billLinesDataSource: MatTableDataSource<BillLines>;
  purchaseReceives: ExtendedPurchaseReiceve[] = [];
  selectedReceive: ExtendedPurchaseReiceve | null;
  selectedPurchaseReceiveId!: number;
  selectedPurchaseReceiveItems: ExtendedPurchaseReceiveItem[] = [];
  initialFormData: Bill | null = null;


  today = new Date();
  productsMap: { [id: number]: any } = {};
  suppliersMap: { [id: number]: string } = {};
  wrongQuantities: boolean = true;
  isEditMode: boolean = false;

  totalAmount: number = 0;
  balanceDue: number = 0;

  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private billService: BillService,
    private orderService: OrderService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.billLinesDataSource = new MatTableDataSource<BillLines>([]);
    this.billForm = this.fb.group({
      billNumber: [ '', Validators.required],
      supplierId: [''], // supplierId remains enabled
      orderId: [ ''],
      billDate: [ '', Validators.required],
      dueDate: [ '', Validators.required],
      notes: [ ''],
      paymentTerms: [ ''],
      balanceDue: [ ''],
      ammount: [ ''],

      billLines: [
         this.fb.array([]),
        Validators.required,
      ],
    });    this.selectedReceive = null;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.billForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.billForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.fetchBill();
  }

  fetchBill(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.billId = +id;
        this.billService
          .getBillById(this.billId)
          .subscribe((response) => {
            response.billDate = this.dataHelper.formatDate(response.billDate),
            response.dueDate = this.dataHelper.formatDate(response.billDate)
            this.bill = response;
            console.log(response)
            this.billForm.patchValue(response);
            this.initialFormData = { ...response };
            this.breadcrumbService.set(
              '@billNumber',
              this.bill.billNumber
            );
            this.loadDataMaps();
            this.balanceDue = response.balanceDue;
            this.totalAmount = response.ammount;
          });
      }
    });
  }

  loadDataMaps(): void {
    this.productsMap = this.orderService.products;
    this.suppliersMap = this.orderService.suppliers;
  }

  get billLines() {
    return this.billForm.get('billLines') as FormArray;
  }

  getProductNamesByOrderItemId(orderItemId: number): string {
    if (!this.order || !this.order.orderItems) {
      return 'Product Not Found';
    }

    const orderItem = this.order.orderItems.find(
      (item) => item.id === orderItemId
    );
    if (orderItem) {
      return orderItem.product;
    } else {
      return 'Product Not Found';
    }
  }

  updateTotals() {
    if(this.bill.billLines){
    this.totalAmount = this.bill.billLines.reduce((sum, line) => sum + line.ammount + (line.ammount * (line.tax / 100)), 0);
    this.balanceDue = this.totalAmount;}
  }
  
  updateTax(taxValue: string, item: BillLines, index: number) {
    const tax = parseFloat(taxValue);
    if (isNaN(tax) || tax < 0) {
      Swal.fire({
        title: 'Invalid Tax',
        text: 'Tax must be a positive number',
        icon: 'error',
        confirmButtonText: 'OK',
      });      item.tax = 0; // Reset tax value in the data model
    } else {
      item.tax = tax; // Update tax value in the data model
      this.updateTotals(); // Recalculate totals
    }
  }
  
  
  onSubmit() {
    if(this.billForm.invalid){
      Object.values(this.billForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.billForm.getRawValue();
  const billLines: BillLines[] = [];

  if (this.bill.billLines) {
    this.bill.billLines.forEach((item, index) => {
      const taxInput = document.getElementById(`tax-${index}`) as HTMLInputElement;
      const taxDto = parseFloat(taxInput.value) || 0;

      const billLine: BillLines = {
        billId: this.billId,
        orderItemId: item.orderItemId,
        item: item.item,
        quantity: item.quantity,
        ammount: item.ammount,
        rate: item.rate,
        tax: taxDto,
      };

      billLines.push(billLine);
    });
  }
    // Create the billData object with the gathered data
    const billData: Bill = {
      id: this.billId,
      billNumber: formValue.billNumber || '',
      orderId: this.bill.orderId || 0,
      billDate: new Date(formValue.billDate).toISOString(),
      dueDate: new Date(formValue.dueDate).toISOString(),
      paymentTerms: formValue.paymentTerms,
      notes: formValue.notes,
      balanceDue: this.balanceDue,
      ammount: this.totalAmount,
      status: this.bill.status,
      billLines: billLines,
    };

    console.log(billData);

    this.billService.updateBillById(this.billId,billData)
      .subscribe(
        () => {
          Swal.fire('Success', 'Bill updated successfully.', 'success')
            .then(() =>{ 
              this.billForm.markAsPristine();
              this.billForm.markAsUntouched();
              this.billForm.reset();
              this.router.navigate(['/purchases/bills'])
            });
        },
        error => Swal.fire('Error', error , 'error')
      );
  }

  onCancel() {
    if (this.billForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.billForm.patchValue(this.initialFormData);
    }
    this.billForm.markAsPristine();
    this.billForm.markAsUntouched();
    this.isEditMode = false;
  }



  onEdit() {
    this.isEditMode = true;
  }

  onDelete() {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this bill?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.billService
          .deleteBill(this.billId)
          .subscribe(
            () => {
              Swal.fire(
                'Success',
                'Purchase Receive deleted successfully.',
                'success'
              ).then(() => this.router.navigate(['/purchases/bills']));
            },
            (error) => Swal.fire('Error', error, 'error')
          );
      }
    });
  }
}
