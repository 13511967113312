import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { OrderResponse } from '@interfaces/OrderResponse.interface';
import { Order } from '@interfaces/Order.interface';
import { OrderData } from '@interfaces/OrderData.interface';
import { GetProduct } from '@interfaces/GetProduct.interface';
import { GetSupplier } from '@interfaces/GetSupplier.interface';
import { GetOrder } from '@interfaces/GetOrder.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private productMap: { [id: number]: any } = {};
  private supplierMap: { [id: number]: string } = {};
  private dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.loadMaps();
  }

  private loadMaps(): void {
    if (!this.dataLoaded$.getValue()) {
      this.getOrderData().subscribe(data => {
        this.createProductMap(data.products,this.productMap);
        this.createSupplierMap(data.suppliers);
        this.dataLoaded$.next(true); // Indicate that data has been loaded
      });
    }
  }

  private createProductMap(products: GetProduct[],mapRef: { [id: number]: any }): void {
    products.forEach(product => {
      mapRef[product.id] = {
        name: product.name,
        price: product.price,
        category: product.category,
        categoryId: product.categoryId,
      };
    });
  }

  private createSupplierMap(suppliers: GetSupplier[]): void {
    suppliers.forEach(supplier => {
      this.supplierMap[supplier.id] = supplier.contactName;
    });
  }

  get products(): { [id: number]: string } {
    return this.productMap;
  }

  get suppliers(): { [id: number]: string } {
    return this.supplierMap;
  }

  getOrderData(): Observable<OrderData> {
    return this.http.get<OrderData>(backend.orderData.getOrderData).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getAllWithoutOrderLines(
    pageNumber: number,
    pageSize: number
  ): Observable<OrderResponse> {
    return this.http
      .get<OrderResponse>(
        backend.order.getAll(pageNumber, pageSize)
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getByIdWithOrderLines(orderId: number): Observable<GetOrder> {
    return this.http
      .get<GetOrder>(
        `${backend.order.getById(orderId)}`
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addOrder(order: Order): Observable<any> {
    return this.http
      .post(backend.order.post, order, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteOrder(orderId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.order.deleteById(orderId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateOrderById(orderId: number, order: Order): Observable<any> {
    return this.http
      .put(backend.order.updateById(orderId), order, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
