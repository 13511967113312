import { Component, OnInit } from '@angular/core';
import { ProductService } from '@services/product.service';
import { GetProduct } from '@interfaces/GetProduct.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { HResourceDataService } from '@services/resourceData.service';
import { InventoryDataService } from '@services/inventoryData.service';
import { ProductsResponse } from '@interfaces/ProductResponse.interface';
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { Module } from '@interfaces/Module.interface';
import { LeftNavService } from '@services/left-nav.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {

  
  products: GetProduct[] = [];
  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;  
  totalProducts: number = 0;
  error: string | null = null;
  modules!: Module[];
  categoryName: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  displayedColumns:  Column[] = [
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'model', header: this.translate.instant('Model') },
    { prop: 'inStock', header: this.translate.instant('In Stock') },
    { prop: 'isProductActive', header: this.translate.instant('Is product available') },
    { prop: 'brand', header: this.translate.instant('Brand')},
    { prop: 'category', header: this.translate.instant('Category') },
    { prop: 'department', header: this.translate.instant('Department') },
  ];

  constructor(
    private translate: TranslateService,
    private productService: ProductService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private hResourceDataService: HResourceDataService,
    private inventoryDataService: InventoryDataService,
    private route: ActivatedRoute,
    private leftNavService: LeftNavService,
    private breadCrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.fetchProducts();
  }



  fetchProducts(page: number = this.currentPage, size: number = this.pageSize): void {
    this.route.paramMap.subscribe(params => {
      this.categoryName = params.get('categoryName');
      this.leftNavService.getData().subscribe((response: Module[]) => {
        this.modules = response;
        console.log("HERE")
        this.modules.forEach((module)=>{
          if(module.name.toLowerCase() == "inventory"){
            if(module.childModules){
              module.childModules.forEach((child)=>{
                if(child.name.toLowerCase() == this.categoryName?.toLowerCase()){
                  this.dataHelper.fetchItems(
                    this.productService.getProducts(child.orderBy,page, size),
                    (response: ProductsResponse) => {
                      this.products = response.items;
                      this.totalProducts = response.totalItems;
                     
                    }
                  );  
                }
              })
            }
          }
        })
          });
      }, error => {
        console.error('Failed to load invoice number:', error);
      });
        
      
     
    
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; // MatPaginator is 0-based, but typically APIs are 1-based.
    this.pageSize = event.pageSize;
    this.fetchProducts(this.currentPage, this.pageSize);
  }

  deleteProduct(element: GetProduct): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this product? Some changes may affect major functionalities',
      () => this.productService.deleteProduct(element.id),
      'Product deleted successfully',
      () => this.fetchProducts(),
      () => null
    );
  }

  addProduct() {
    this.router.navigate(['/inventory/products', this.categoryName, 'new_product']); // Navigate to the new product route
  }

  editProduct(element: GetProduct): void {
    this.router.navigate(['/inventory/products',this.categoryName, 'edit_product', element.id]); // Adjust the route as per your routing configuration
  }
}
