import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@services/product.service'; // Adjust the import as per your project structure
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { HResourceDataService } from '@services/resourceData.service';
import { InventoryDataService } from '@services/inventoryData.service';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { EquipmentService } from '@services/equipment.service';
import { BaseEquipment, Equipment } from '@interfaces/Equipment.interface';
import { EquipmentDataService } from '@services/equipmentData.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-new-equipment',
  templateUrl: './new-equipment.component.html',
  styleUrls: ['./new-equipment.component.css'],
})
export class NewEquipmentComponent implements OnInit {
  equipmentForm!: FormGroup;
  equipmentCategoryMap: { [id: number]: string } =
    this.equipmentDropdownData.equipmentCategories;
  equipmentCategoryName: string | null = null;
  additionalEquipmentDataSource: MatTableDataSource<BaseEquipment>;
  filteredEquipmentMap: { [lineIndex: number]: any[] } = {};

  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceDataService: HResourceDataService,
    private equipmentDropdownData: EquipmentDataService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.additionalEquipmentDataSource = new MatTableDataSource<BaseEquipment>(
      []
    );
    this.equipmentForm = this.fb.group({
      serialNumber: [''],
      name: ['', Validators.required],
      status: [''],
      chargePerDay: [''],
      equipmentCategoryId: ['', Validators.required],
      ehrId: [''],
      additionalEquipment: this.fb.array([]),
    });
  }

  get additionalEquipment() {
    return this.equipmentForm.get('additionalEquipment') as FormArray;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage(
      $event,
      this.equipmentForm.dirty
    );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.equipmentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.equipmentCategoryName = params.get('equipmentCategoryName');
    });
    const categoryId = Object.keys(this.equipmentCategoryMap).find(
      (key) =>
        this.equipmentCategoryMap[+key].toLowerCase() ===
        this.equipmentCategoryName?.toLowerCase()
    );
    const categoryIdNumber = categoryId ? Number(categoryId) : null;

    if (categoryIdNumber) {
      this.equipmentForm.get('equipmentCategoryId')?.setValue(categoryIdNumber);
    }

    this.additionalEquipment.push(this.createItem());
  }

  createItem(): FormGroup {
    const additionalEquipmentItemFormGroup = this.fb.group({
      equipmentCategoryId: ['0'],
      status: [''],
      name: [''],
      id: [{ value: '0', disabled: true }, Validators.required],
    });
    return additionalEquipmentItemFormGroup;
  }

  addServiceLine(): void {
    this.additionalEquipment.push(this.createItem());
  }

  loadEquipment(selectedCategoryId: number, lineIndex: number) {
    this.dataHelper.fetchItems(
      this.equipmentService.getEquipmentByCategory(selectedCategoryId),
      (data: any) => {
        if (data && data.equipment) {
          this.filteredEquipmentMap[lineIndex] = data.equipment;
          this.cdr.detectChanges();
        }
      }
    );
  }

  onCategorySelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const categoryId = selectElement.value;
    const selectedCategory = this.equipmentCategoryMap[+categoryId];

    if (selectedCategory) {
      this.additionalEquipment.at(index).patchValue({
        equipmentCategoryId: categoryId,
      });
      this.loadEquipment(+categoryId, index);

      this.additionalEquipment.at(index).get('id')?.enable();
    }
  }

  onEquipmentSelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const equipmentId = selectElement.value;

    const equipmentArray = this.filteredEquipmentMap[index];

    const selectedEquipment = equipmentArray?.find(
      (equipment) => equipment.id.toString() === equipmentId
    );

    if (selectedEquipment) {
      this.additionalEquipment.at(index).patchValue({
        id: selectedEquipment.id,
        name: selectedEquipment.name,
      });

      if (index === this.additionalEquipment.length - 1) {
        this.addServiceLine();
      }
    }
  }

  updateDataSource(): void {
    this.additionalEquipmentDataSource.data =
      this.additionalEquipment.controls.map((control) => control.value);
  }

  removeItem(index: number): void {
    if (this.additionalEquipment.length > 1) {
      this.additionalEquipment.removeAt(index);
      this.updateDataSource();
    }
  }

  onSubmit() {
    if (this.equipmentForm.invalid) {
      Object.values(this.equipmentForm.controls).forEach((control) => {
        if (control instanceof FormGroup || control instanceof FormArray) {
          control.markAsTouched({ onlySelf: true });
        } else {
          control.markAsTouched();
        }
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.',
      });
      return;
    }
    const formValue = this.equipmentForm.getRawValue();
    if (this.additionalEquipment.length === 1) {
      formValue.additionalEquipment = [];
    } else {
      formValue.additionalEquipment = formValue.additionalEquipment.slice(0,-1);
    }
    const dataToSend: Equipment = {
      id: 0,
      serialNumber: formValue.serialNumber || null,
      name: formValue.name,
      status: formValue.status,
      chargePerDay: formValue.chargePerDay || null,
      equipmentCategoryId: formValue.equipmentCategoryId,
      ehrId: 0,
      additionalEquipment: formValue.additionalEquipment || null,
    };
    this.equipmentService.addEquipment(dataToSend).subscribe({
      next: (mainEquipment) => {
        const mainEquipmentId = mainEquipment.id;
        this.dataHelper.Toast.fire({
          icon: 'success',
          title: 'Equipment created successfully',
        });
        if (formValue.additionalEquipment.length > 0) {
          this.updateAdditionalEquipment(mainEquipmentId, dataToSend);
        } else {
          this.postUpdateActions();
        }
      },
      error: (error) => {
        // Handle any errors here
        console.error('Error adding main equipment:', error);
      },
    });
  }

  updateAdditionalEquipment(parentEquipmentId: number, parent: Equipment) {
    const validEquipmentItems = this.additionalEquipment.controls.slice(0, -1); // Removes the last item

    const updateOperations = validEquipmentItems.map((control, index) => {
      const additionalEquipmentData = {
        ...control.value,
        parentEquipmentId: parentEquipmentId,
        status: parent.status,
      };
      return this.equipmentService
        .updateEquipmentById(
          additionalEquipmentData.id,
          additionalEquipmentData
        )
        .pipe(
          catchError((error) => {
            console.error(
              `Error updating additional equipment at index ${index}:`,
              error
            );
            return of(null);
          })
        );
    });

    forkJoin(updateOperations).subscribe({
      next: () => {
        this.postUpdateActions();
      },
      error: (error) => {
        console.error('Error updating additional equipment:', error);
      },
    });
  }

  postUpdateActions() {
    this.equipmentForm.markAsPristine();
    this.equipmentForm.markAsUntouched();
    this.equipmentForm.reset();
    this.additionalEquipment.markAsPristine();
    this.additionalEquipment.markAsUntouched();
    this.additionalEquipment.reset();
    this.router.navigate([`/equipment/${this.equipmentCategoryName}`]);
  }

  cancel() {
    this.router.navigate([`/equipment/${this.equipmentCategoryName}`]);
  }
}
