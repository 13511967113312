import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GetInvoice } from '@interfaces/GetInvoice.interface';
import { InvoiceResponse } from '@interfaces/InvoiceResponse.interface';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '@services/invoices.service';
import { DataHelper } from '../../../helpers/data-helper';
import { PaginationData } from '../../../helpers/pagination-data';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
})
export class InvoicesComponent implements OnInit {
  invoices: GetInvoice[] = [];
  filteredInvoices = [...this.invoices];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );
  displayedColumns: Column[] = [
    { prop: 'dateTime', header: this.translate.instant('Date'), transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'invoiceNumber', header: this.translate.instant('	INVOICE#') },
    { prop: 'customer', header: this.translate.instant('Customer name')},
    { prop: 'status', header: this.translate.instant('Status')},
    { prop: 'netTotal', header: this.translate.instant('Net Total')},
    { prop: 'due', header: this.translate.instant('Balance Due') },
    // { prop: 'id', header: this.translate.instant('Code') },
    // { prop: 'details', header: this.translate.instant('Name') },
    // { prop: 'shippingCost', header: this.translate.instant('Price') },
    // { prop: 'discount', header: this.translate.instant('In Stock') },
    // { prop: 'totalDiscount', header: this.translate.instant('Total Discount') },
    // { prop: 'grandTotal', header: this.translate.instant('Grand Total')},
    // { prop: 'change', header: this.translate.instant('Change') },
    // { prop: 'vat', header: this.translate.instant('VAT') },
    // { prop: 'totalTax', header: this.translate.instant('Total Tax') },
    // { prop: 'noTax', header: this.translate.instant('No Tax') },
    // { prop: 'paidAmount', header: this.translate.instant('Paid Amount') },
    // { prop: 'employee', header: this.translate.instant('Employee') },
    // { prop: 'paymentType', header: this.translate.instant('Payment Type')},
];



  constructor(
    private translate: TranslateService,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private serviceInvoiceDataService: ServiceInvoiceDataService
  ) {}
 

  ngOnInit(): void {
    this.fetchinvoices();
   
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalInvoices: number = 0;

  fetchinvoices(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.invoiceService.getAllWithoutInvoiceLines(page, size),
      (response: InvoiceResponse) => {
        console.log(response);
        this.invoices = response.items;
        this.totalInvoices = response.totalItems;
        this.filteredInvoices = [...this.invoices];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; // MatPaginator is 0-based, but typically APIs are 1-based.
    this.pageSize = event.pageSize;
    this.fetchinvoices(this.currentPage, this.pageSize);
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const filterValue = target.value.trim().toLowerCase(); // Directly access value property

    this.filteredInvoices = this.invoices.filter(
      (invoice) =>
        invoice.id.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.invoiceNumber.toLowerCase().includes(filterValue) ||
        invoice.details.toLowerCase().includes(filterValue) ||
        invoice.dateTime.toLowerCase().includes(filterValue) ||
       
        invoice.due.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.discount.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.totalDiscount
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue) ||
        invoice.grandTotal
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue) ||
        invoice.change.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.netTotal.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.vat.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.totalTax.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.noTax.toLocaleString().toLowerCase().includes(filterValue) ||
        invoice.paidAmount
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue) ||
        invoice.customer
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue) ||
        invoice.employee
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue) ||
        invoice.paymentType
          .toLocaleString()
          .toLowerCase()
          .includes(filterValue)
    );
  }

  deleteInvoice(element: GetInvoice): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this invoice? Some changes may affect major functionalities',
      () => this.invoiceService.deleteInvoice(element.id),
      'Invoice deleted successfully',
      () => this.fetchinvoices(),
      () => null
    );
  }

  addInvoice() {
    this.router.navigate(['/sales/invoices/new_invoice']); 
  }

  editInvoice(event: any){
    this.router.navigate(['/sales/invoices/preview_invoice',event.id]); 
    // this.router.navigate(['/sales/edit_invoice',event.id]); 
  }

  downloadPdf(element: GetInvoice) {
    this.invoiceService
      .getByIdWithInvoiceLines(element.id)
      .subscribe((response) => {
        console.log('API Response:', response);
        // Create a PDF using jsPDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        // Add data to the PDF
        pdf.text(`Invoice ID: ${response.id}`, 10, 10);
        pdf.text(`Inv. Number: ${response.invoiceNumber}`, 10, 20);
        pdf.text(`Details: ${response.details}`, 10, 30);
        pdf.text(`Date/Time: ${response.dateTime}`, 10, 40);
        pdf.text(`Due: ${response.due}`, 10, 60);
        pdf.text(`Discount: ${response.discount}`, 10, 70);
        pdf.text(`Total Discount: ${response.totalDiscount}`, 10, 80);
        pdf.text(`Grand Total: ${response.grandTotal}`, 10, 90);
        pdf.text(`Change: ${response.change}`, 10, 100);
        pdf.text(`Net Total: ${response.netTotal}`, 10, 110);
        pdf.text(`VAT: ${response.vat}`, 10, 120);
        pdf.text(`Total Tax: ${response.totalTax}`, 10, 130);
        pdf.text(`No Tax: ${response.noTax ? 'Yes' : 'No'}`, 10, 140);
        pdf.text(`Paid Amount: ${response.paidAmount}`, 10, 150);
        pdf.text(`Customer ID: ${response.customerId}`, 10, 160);
        pdf.text(`Employee ID: ${response.employeeId}`, 10, 170);
        pdf.text(`Payment Type ID: ${response.paymentTypeId}`, 10, 180);
        pdf.text('Invoice Line Items:', 10, 200);
        let startY = 210;
        if (response.invoiceLines) {
          // Add invoice line items header
          pdf.text('Invoice Line Items:', 10, 200);
          let startY = 220;
          for (let i = 0; i < response.invoiceLines.length; i++) {
            if (startY >= 280) {
              // Start a new page if startY exceeds the boundary
              pdf.addPage();
              startY = 10; // Reset startY for the new page
            }
            const line = response.invoiceLines[i];
            const itemNumber = i + 1;
            pdf.text(`Item ${itemNumber}:`, 10, startY);
            startY += 10;
            const description = line.description || 'N/A';
            pdf.text(`Description: ${description}`, 20, startY);
            startY += 10;
            pdf.text(`Quantity: ${line.quantity}`, 20, startY);
            startY += 10;
            pdf.text(`Discount: ${line.discount}`, 20, startY);
            startY += 10;
            pdf.text(`Total: ${line.total}`, 20, startY);
            startY += 10;
            pdf.text(`Service ID: ${line.serviceId}`, 20, startY);
            startY += 20; // Move to the next item
          }
          // Adjust startY based on the number of items for proper spacing
          startY += response.invoiceLines.length > 0 ? 10 : 0;
          }
        pdf.save(`invoice_${response.id}.pdf`);
      });
  }
}
