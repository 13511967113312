import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyService } from '@services/currency.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../../helpers/data-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { Currency } from '@interfaces/Currency.interface';

@Component({
  selector: 'app-edit-currency',
  templateUrl: './edit-currency.component.html',
  styleUrls: ['./edit-currency.component.css'],
})
export class EditCurrencyComponent implements OnInit {
  currencyForm!: FormGroup;
  currencyId!: number;
  currencyName: string = '';
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: Currency | null = null;

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.currencyForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.currencyForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.currencyForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.currencyId = Number(params.get('id')); // Extract currencyId from the route

      if (!isNaN(this.currencyId)) {
        this.currencyService
          .getById(this.currencyId)
          .subscribe((currency) => {
            if (Array.isArray(currency) && currency.length > 0) {
              // If currency is an array, get the name from the first element of the array
              this.currencyName = currency[0].name;
              this.currencyForm.patchValue({ name: this.currencyName }); // Prefill the "Name" field
              this.initialFormData = { ...currency[0] };
            } else if (currency) {
              // If currency is a single object, get the name from it
              this.currencyName = currency.name;
              this.currencyForm.patchValue({ name: this.currencyName }); // Prefill the "Name" field
              this.initialFormData = { ...currency };

            }
            this.breadcrumbService.set('@currencyName', this.currencyName);
          });
      } else {
        console.error('Invalid currencyId');
      }
    });
  }

  onSubmit() {
    if(!this.currencyForm.valid){
      Object.values(this.currencyForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.currencyForm.valid) {
      const updatedcurrency = this.currencyForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this currency? Some changes may affect major functionalities',
        () =>
          this.currencyService.updateCurrency(this.currencyId, updatedcurrency),
        'currency updated successfully',
        () => {
          this.currencyForm.markAsPristine();
          this.currencyForm.markAsUntouched();
          this.currencyForm.reset();
          this.router.navigate(['/masters/currencies']);
        }
      );
    }
  }

  cancel() {
    if (this.currencyForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.currencyForm.patchValue(this.initialFormData);
    }
    this.currencyForm.markAsPristine();
    this.currencyForm.markAsUntouched();
    this.isEditMode = false;
  }



  exitView(){
    this.router.navigate(['/masters/currencies']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this currency? Some changes may affect major functionalities',
      () => this.currencyService.deleteCurrency(this.currencyId),
      'currency deleted successfully',
      () => this.router.navigate(['/masters/currencies']),
      () => null
    );
  }
}
