import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faArrowLeftRotate,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { GetInvoice } from '@interfaces/GetInvoice.interface';
import { GetPaymentReceived } from '@interfaces/GetPaymentReceived.interface';
import { Invoice } from '@interfaces/Invoice.interface';
import { InvoiceResponse } from '@interfaces/InvoiceResponse.interface';
import { PaymentReceived } from '@interfaces/PaymentReceived.interface';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '@services/invoices.service';
import { PaymentReceivedService } from '@services/paymentReceived.service';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { Observable, concat, forkJoin } from 'rxjs';
import { DataHelper } from '../../../../helpers/data-helper';
import { PaginationData } from '../../../../helpers/pagination-data';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-view-payment-received',
  templateUrl: './view-payment-received.component.html',
  styleUrls: ['./view-payment-received.component.css'],
})
export class ViewPaymentReceivedComponent {
  paymentReceivedForm: FormGroup;
  today = new Date();
  customerMap: { [id: number]: any } = {};
  paymentTypesMap: { [id: number]: string } = {};
  selectedCustomerId!: number;
  unpaidInvoice: GetInvoice | null = null;
  invoicePaymentMap: { [id: number]: number } = {};
  paymentAmount: number = 0;
  isEditMode: boolean = false;
  paymentReceivedId: number = 0;
  paymentReceived!: GetPaymentReceived;
  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalInvoices: number = 0;
  wrongTotal: boolean = true;
  currentTotalPayment: number = 0;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map((key) => +key);
  }
  initialFormData: any | null = null;


  faEdit = faPen;
  faExit = faArrowLeftRotate;
  faDelete = faTrashCan;
  constructor(
    private fb: FormBuilder,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private invoiceService: InvoiceService,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private paymentReceivedService: PaymentReceivedService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.paymentReceivedForm = this.fb.group({
      customerId: ['', Validators.required],
      amountReceived: ['', Validators.required],
      date: [this.today.toISOString()],
      paymentNumber: ['', Validators.required],
      paymentTypeId: ['', Validators.required],
      invoiceId: ['', Validators.required],
    });

    // Initialize the map
    if (this.unpaidInvoice) {
      this.invoicePaymentMap[this.unpaidInvoice.id] = 0;
    }
  }
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.paymentReceivedForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.paymentReceivedForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit() {
    this.fetchPaymentReceived();
    this.loadDataMaps();
  }

  loadDataMaps(): void {
    this.customerMap = this.serviceInvoiceDataService.customers;
    this.paymentTypesMap = this.serviceInvoiceDataService.paymentTypes;
  }

  fetchPaymentReceived(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.paymentReceivedId = +id;
        this.paymentReceivedService
          .getPaymentReceivedById(this.paymentReceivedId)
          .subscribe((response) => {
            console.log(response);
            this.paymentReceived = response;
            this.paymentReceivedForm.patchValue(response);
            this.initialFormData = { ...response };
            this.currentTotalPayment = this.paymentReceived.amountReceived;
            this.fetchInvoice(this.paymentReceivedForm.get('invoiceId')?.value);
            this.breadcrumbService.set('@paymentReceiveNumber', this.paymentReceived.paymentNumber);
          });
      }
    });
  }

  fetchInvoice(invoiceId: number): void {
    console.log(invoiceId);
    this.dataHelper.fetchItems(
      this.invoiceService.getByIdWithInvoiceLines(invoiceId),
      (invoice: GetInvoice) => {
        console.log(invoice);
        this.unpaidInvoice = invoice;
      }
    );
  }

  checkAmount(event: any) {
    const inputValue = parseFloat(event.target.value);

    if (inputValue <= 0) {
      this.wrongTotal = true;
      Swal.fire({
        title: 'Invalid Amount',
        text: 'Please enter a value greater than 0',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    } else if (this.unpaidInvoice && inputValue > this.unpaidInvoice.due) {
      this.wrongTotal = true;
      Swal.fire({
        title: 'Invalid Amount',
        text: `Amount cannot be greater than total due amount (${this.unpaidInvoice.due})`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    if (
      inputValue < this.currentTotalPayment &&
      this.currentTotalPayment != 0
    ) {
      this.wrongTotal = true;
      Swal.fire({
        title: 'Invalid Amount',
        text: `Amount cannot be less than total payments (${this.currentTotalPayment})`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    } else if (
      inputValue > this.currentTotalPayment &&
      this.currentTotalPayment != 0
    ) {
      this.wrongTotal = true;
      Swal.fire({
        title: 'Invalid Amount',
        text: `Amount cannot be greater than total payments (${this.currentTotalPayment})`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    this.wrongTotal = false;
  }

  updatePaymentAmount(event: any) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.paymentAmount = inputValue !== '' ? parseFloat(inputValue) : 0;

    if (!this.unpaidInvoice) {
      Swal.fire('Error', 'No unpaid invoices found.', 'error');
      this.wrongTotal = true;
      return;
    }

    if (!this.unpaidInvoice.id) {
      Swal.fire(
        'Error',
        'Invoice not found for id: ' + this.unpaidInvoice.id,
        'error'
      );
      this.wrongTotal = true;
      return;
    }

    if (
      isNaN(this.paymentAmount) ||
      this.paymentAmount < 0 ||
      this.paymentAmount > this.unpaidInvoice.due
    ) {
      Swal.fire(
        'Error',
        'Invalid payment amount: ' + this.paymentAmount,
        'error'
      );

      this.wrongTotal = true;
      return;
    }

    // Update the payment map with the entered value
    this.invoicePaymentMap[this.unpaidInvoice.id] = this.paymentAmount;

    // Check if the sum of payment amounts exceeds amountReceived
    this.currentTotalPayment = Object.values(this.invoicePaymentMap).reduce(
      (total, amount) => total + amount,
      0
    );

    const amountReceived =
      this.paymentReceivedForm.get('amountReceived')?.value;
    console.log(amountReceived);
    if (this.currentTotalPayment > amountReceived && amountReceived > 0) {
      Swal.fire('Error', 'Total payment exceeds amount received.', 'error');

      this.wrongTotal = true;
      return;
    }
    if (this.currentTotalPayment < amountReceived) {
      Swal.fire(
        'Error',
        'Total payment cant be less than amount received.',
        'error'
      );
      this.wrongTotal = true;
      return;
    }

    this.wrongTotal = false;
  }

  onSubmit() {
    if(this.paymentReceivedForm.invalid){
      Object.values(this.paymentReceivedForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (!this.paymentReceivedForm.valid) {
      Swal.fire('Error', 'Please correct the errors in the form.', 'error');
      return;
    }

    const formValue = this.paymentReceivedForm.getRawValue();
    const payment: PaymentReceived = {
      id: 0,
      customerId: formValue.customerId,
      amountReceived: formValue.amountReceived,
      paymentDate: formValue.date,
      paymentTypeId: formValue.paymentTypeId,
      paymentNumber: formValue.paymentNumber,
      invoiceId: formValue.invoiceId,
    };

    if (payment.amountReceived > 0) {
      this.paymentReceivedService.addPaymentReceived(payment).subscribe(
        () => {
          this.invoiceService
            .getByIdWithInvoiceLines(payment.invoiceId)
            .subscribe(
              (invoice: Invoice) => {
                invoice.paidAmount += payment.amountReceived;
                invoice.due = invoice.netTotal - invoice.paidAmount;
                invoice.status = invoice.due === 0 ? 'PAID' : 'PARTIALLY PAID';

                this.invoiceService
                  .updateInvoiceById(invoice.id, invoice)
                  .subscribe(
                    () => {
                      Swal.fire(
                        'Success',
                        'Invoice updated successfully.',
                        'success'
                      ).then(() =>{
                        this.paymentReceivedForm.markAsPristine();
                        this.paymentReceivedForm.markAsUntouched();
                        this.paymentReceivedForm.reset();
                        this.router.navigate(['/sales/paymentsReceived'])
                      });
                    },
                    (error) => Swal.fire('Error', error, 'error')
                  );
              },
              (error) => Swal.fire('Error', error, 'error')
            );
        },
        (error) => Swal.fire('Error', error, 'error')
      );
    }
  }

  cancel() {
    if (this.paymentReceivedForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.paymentReceivedForm.patchValue(this.initialFormData);
    }
    this.paymentReceivedForm.markAsPristine();
    this.paymentReceivedForm.markAsUntouched();
    this.isEditMode = false;
  }


  onEdit() {
    this.isEditMode = true;
  }

  exitView() {
    this.router.navigate(['/sales/paymentsReceived']);
  }

  onDelete(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && this.unpaidInvoice) {
        this.invoiceService
          .getByIdWithInvoiceLines(this.unpaidInvoice.id)
          .subscribe({
            next: (invoice) => {
              this.paymentReceivedService
                .deletePaymentReceived(this.paymentReceivedId)
                .subscribe({
                  next: () => {
                    invoice.due += this.paymentReceived.amountReceived;
                    invoice.status = 'PARTIALLY PAID';
                    this.invoiceService
                      .updateInvoiceById(invoice.id, invoice)
                      .subscribe({
                        next: () => {
                          Swal.fire({
                            title: 'Deleted!',
                            text: 'Payment has been deleted.',
                            icon: 'success',
                          }).then(() => {
                            this.router.navigate(['/sales/paymentsReceived']);
                          });
                        },
                        error: (error) => {
                          Swal.fire({
                            title: 'Error',
                            text: error,
                            icon: 'error',
                            confirmButtonText: 'OK',
                          });
                        },
                      });
                  },
                  error: (error) => {
                    // Handle any errors here
                    Swal.fire({
                      title: 'Error',
                      text: error,
                      icon: 'error',
                      confirmButtonText: 'OK',
                    });
                  },
                });
            },
            error: (error) => {
              // Handle any errors here
              Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'OK',
              });
            },
          });
      }
    });
  }
}
