import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from '@services/departments.service';
import { Department } from '@interfaces/Department.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css'],
})
export class DepartmentsComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'description', header: this.translate.instant('Description') },
  ];
  
  departments: Department[] = [];
  filteredDepartments: Department[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private departmentsService: DepartmentsService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)


  ngOnInit(): void {
    this.fetchDepartments();
  }

  fetchDepartments(): void {
    this.dataHelper.fetchItems(
      this.departmentsService.getDepartment(null),
      (data: Department[]) => {
        this.departments = data;
        this.filteredDepartments = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const filterValue = target.value.trim().toLowerCase(); // Directly access value property

    this.filteredDepartments = this.departments.filter(
      (department) =>
        department.name.toLowerCase().includes(filterValue) ||
        department.description.toLowerCase().includes(filterValue)
    );
  }

  deleteDepartment(element: Department): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this department? Some changes may affect major functionalities',
      () => this.departmentsService.deleteDepartment(element.id),
      'Department deleted successfully',
      () => this.fetchDepartments(),
      () => null
    );
  }

  addDepartment() {
    this.router.navigate(['/masters/departments/new_department']);
  }

  editDepartment(element: Department): void {
    console.log(element.id);

    this.router.navigate(['/masters/departments/edit_department', element.id]);
  }
}
