import { Component, OnInit } from '@angular/core';
import { GetPurchaseReceive } from '@interfaces/GetPurchaseReceive.interface'; // Import GetPurchaseReceive interface
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { PurchaseReceiveService } from '@services/purchaseReceive.service';
import { PurchaseReceivesResponse } from '@interfaces/PurchaseReceivesResponse.interface';

@Component({
  selector: 'app-purchase-receives', // Change selector to 'app-purchase-receives'
  templateUrl: './purchase-receives.component.html', // Change template URL
  styleUrls: ['./purchase-receives.component.css'], // Change style URLs
})
export class PurchaseReceivesComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'receivedDate', header: this.translate.instant('Date'), transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'purchaseReceivedNumber', header: this.translate.instant('Purchase Receive#') },
    { prop: 'purchaseOrderNumber', header: this.translate.instant('Purchase Order#') },
    { prop: 'supplier', header: this.translate.instant('Supplier') },
    { prop: 'status', header: this.translate.instant('Status') },
  ];

  constructor(
    private translate: TranslateService,
    private purchaseReceiveService: PurchaseReceiveService, // Change to PurchaseReceiveService
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  purchaseReceives: GetPurchaseReceive[] = [];
  filteredPurchaseReceives = [...this.purchaseReceives];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchPurchaseReceives();
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalPurchaseReceives: number = 0;

  fetchPurchaseReceives(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.purchaseReceiveService.getPurchaseReceives(page, size), 
      (response: PurchaseReceivesResponse) => {
        this.purchaseReceives = response.items;
        this.totalPurchaseReceives = response.totalItems;
        this.filteredPurchaseReceives = [...this.purchaseReceives];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchPurchaseReceives(this.currentPage, this.pageSize);
  }

  addPurchaseReceive() {
    this.router.navigate(['/purchases/purchaseReceives/new_purchaseReceive']);
  }

  editPurchaseReceive(element: GetPurchaseReceive): void {
    this.router.navigate(['/purchases/purchaseReceives/edit_purchaseReceive', element.id]);
  }
}
