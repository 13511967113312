import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../helpers/data-helper';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { LogoType } from '@enums/LogoType.enum';
import { ChangeDetectorRef } from '@angular/core';
import { AdminDataService } from '@services/adminData.service';
import { BASE_URL } from '@apis/api-endpoints';
import { Observable, concat, map } from 'rxjs';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css'],
})
export class AdminSettingsComponent implements OnInit {
  tenantSettingsForm!: FormGroup;
  initialTenantSettingsFormData: any | null = null;

  numberSettingsForm!: FormGroup;
  initialNumberSettingsFormData: any | null = null;


  tenantSettingsObject!: TenantSettings;
  LogoTypeEnum = LogoType;

  isEditMode: boolean = false;

  logoUrls: { [key: number]: string | null } = {
    [LogoType.logo]: '',
    [LogoType.invoiceLogo]: '',
    [LogoType.favIcon]: '',
  };
  fileSelected: { [key in LogoType]: boolean } = {
    [LogoType.logo]: false,
    [LogoType.invoiceLogo]: false,
    [LogoType.favIcon]: false,
  };

  currenciesMap: { [id: number]: string } = this.adminDataService.currencies;
  vatsMap: { [id: number]:  { name: string, value: number } } = this.adminDataService.vats;
  objectKeys(obj: any): number[] {
    return Object.keys(obj).map(Number);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private tenantSettingsService: TenantSettingsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private adminDataService: AdminDataService
  ) {
    this.tenantSettingsForm = this.fb.group({
      companyName: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      currencyId: ['', Validators.required],
      vatId: ['', Validators.required],
      logoPath: [''],
      invoiceLogoPath: [''],
      favIconPath: [''],
    });

    this.numberSettingsForm = this.fb.group({
      invoicePrefix:[''],
      invoiceNextNumber: [''],
      paymentsReceivedPrefix: [''],
      paymentsReceivedNextNumber: [''],
      orderPrefix:[''],
      orderNextNumber: [''],
      purchaseReceivesPrefix: [''],
      purchaseReceivesNextNumber: [''],
      billsPrefix: [''],
      billsNextNumber:['']
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.tenantSettingsForm.dirty);
    this.dataHelper.getBeforeUnloadEventMessage($event, this.numberSettingsForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.tenantSettingsForm.dirty && !this.numberSettingsForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.fetchTenantSettings();
  }

  fetchTenantSettings(): void {
    this.dataHelper.fetchItems(
      this.tenantSettingsService.getTenantSettings(),
      (response: TenantSettings) => {
        console.log('Tenant settings received:', response);
        this.tenantSettingsForm.patchValue(response);
        this.initialTenantSettingsFormData= { ...response };

        this.tenantSettingsObject = response;
        [LogoType.logo, LogoType.invoiceLogo, LogoType.favIcon].forEach(
          (logoType) => {
            switch (logoType) {
              case 1:
                if (response.logoPath == null) {
                  this.logoUrls[logoType] = null;
                } else {
                  this.logoUrls[logoType] = `${BASE_URL}/${response.logoPath}`;
                  this.cdr.detectChanges();
                }
                break;
              case 2:
                if (response.invoiceLogoPath == null) {
                  this.logoUrls[logoType] = null;
                } else {
                  this.logoUrls[
                    logoType
                  ] = `${BASE_URL}/${response.invoiceLogoPath}`;
                  this.cdr.detectChanges();
                }
                break;
              case 3:
                if (response.invoiceLogoPath == null) {
                  this.logoUrls[logoType] = null;
                } else {
                  this.logoUrls[
                    logoType
                  ] = `${BASE_URL}/${response.favIconPath}`;
                  this.cdr.detectChanges();
                }
                break;
            }
          }
        );
        if(response.numberSettings){
          this.initialNumberSettingsFormData= { ...response.numberSettings };
          const numberSettingsFormValues: any = {};
        response.numberSettings.forEach(setting => {
          switch (setting.accountantScopeId) {
            case 1:
              numberSettingsFormValues.orderPrefix = setting.prefix;
              numberSettingsFormValues.orderNextNumber = setting.number.toString();
              break;
            case 2:
              numberSettingsFormValues.invoicePrefix = setting.prefix;
              numberSettingsFormValues.invoiceNextNumber = setting.number.toString();
              break;
            case 3:
              numberSettingsFormValues.purchaseReceivesPrefix = setting.prefix;
              numberSettingsFormValues.purchaseReceivesNextNumber = setting.number.toString();
              break;
            case 4:
              numberSettingsFormValues.paymentsReceivedPrefix = setting.prefix;
              numberSettingsFormValues.paymentsReceivedNextNumber = setting.number.toString();
              break;
            case 5:
              numberSettingsFormValues.billsPrefix = setting.prefix;
              numberSettingsFormValues.billsNextNumber = setting.number.toString();
              break;
          }
        });
        this.numberSettingsForm.patchValue(numberSettingsFormValues);
      }

      }
    );
  }

  onFileSelected(event: any, logoType: LogoType): void {
    const file = event.target.files[0];
    if (file) {
      const logoPathKey = LogoType[logoType] + 'Path';
      this.tenantSettingsForm.get(logoPathKey)?.setValue(file);

      // For temporary image preview
      const reader = new FileReader();
      reader.onload = () => {
        this.logoUrls[logoType] = reader.result as string;
      };
      reader.readAsDataURL(file);

      this.fileSelected[logoType] = true;
    }
  }

  onSubmit() {
    if(this.tenantSettingsForm.invalid){
      Object.values(this.tenantSettingsForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.tenantSettingsForm.valid) {
      const tenantSettingsformValues = this.tenantSettingsForm.getRawValue();
      const numberSettingsFormValues = this.numberSettingsForm.getRawValue();
      // Transform the flat number settings form values into an array of objects
      const numberSettings = [
        {
          prefix: numberSettingsFormValues.orderPrefix,
          number: parseInt(numberSettingsFormValues.orderNextNumber) || 0,
          accountantScopeId: 1,
        },
        {
          prefix: numberSettingsFormValues.invoicePrefix,
          number: parseInt(numberSettingsFormValues.invoiceNextNumber) || 0,
          accountantScopeId: 2,
        },
        {
          prefix: numberSettingsFormValues.purchaseReceivesPrefix,
          number: parseInt(numberSettingsFormValues.purchaseReceivesNextNumber) || 0,
          accountantScopeId: 3,
        },
        {
          prefix: numberSettingsFormValues.paymentsReceivedPrefix,
          number: parseInt(numberSettingsFormValues.paymentsReceivedNextNumber) || 0,
          accountantScopeId: 4,
        },
        {
          prefix: numberSettingsFormValues.billsPrefix,
          number: parseInt(numberSettingsFormValues.billsNextNumber) || 0,
          accountantScopeId: 5,
        },
      ];
        const updatedSettings = { ...tenantSettingsformValues, numberSettings: numberSettings };
  
      // Proceed with the rest of your submit logic...
      console.log(updatedSettings);
      
      
      const filesToUpload: { [key in LogoType]?: File } = {};

      // Check for each file if it has been selected.
      for (let logoType of Object.values(LogoType).filter(
        (v) => typeof v === 'number'
      ) as LogoType[]) {
        const logoPathKey = LogoType[logoType] + 'Path';
        if (
          this.fileSelected[logoType] &&
          updatedSettings[logoPathKey] instanceof File
        ) {
          filesToUpload[logoType] = updatedSettings[logoPathKey];
          delete updatedSettings[logoPathKey];
        }
      }

      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of these tenant settings? Some changes may affect major functionalities',
        () => this.tenantSettingsService.updateTenantSettings(updatedSettings),
        'Tenant settings updated successfully',
        () => {
          const uploadObservables = [];
          for (let logoType of Object.values(LogoType).filter(
              (v) => typeof v === 'number'
          ) as LogoType[]) {
              if (filesToUpload[logoType]) {
                  uploadObservables.push(
                      this.tenantSettingsService.uploadLogo(logoType, filesToUpload[logoType] as File).pipe(
                          map((uploadResponse) => {
                              console.log(`${LogoType[logoType]} uploaded successfully`, uploadResponse);
                              URL.revokeObjectURL(this.logoUrls[logoType]!);
                              this.fileSelected[logoType] = false;
                              return uploadResponse;
                          })
                      )
                  );
              }
          }

          concat(...uploadObservables).subscribe({
              error: (uploadError) => this.dataHelper.showError(uploadError)
          });
        }
      );
      this.isEditMode=false;
    }
  }

  onEdit(){
    this.isEditMode=true;

  }
  cancel() {
    if (this.tenantSettingsForm.dirty || this.numberSettingsForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialTenantSettingsFormData) {
      this.tenantSettingsForm.patchValue(this.initialTenantSettingsFormData);
    }
    
    if (this.initialNumberSettingsFormData) {
      this.tenantSettingsForm.patchValue(this.initialNumberSettingsFormData);
    }
    this.tenantSettingsForm.markAsPristine();
    this.tenantSettingsForm.markAsUntouched();

    this.numberSettingsForm.markAsPristine();
    this.numberSettingsForm.markAsUntouched();

    this.isEditMode = false;
  }

}
