// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector {
  display: flex;
  align-items: center;
}

.selected-flag {
  margin-right: 10px; /* Adjust as needed */
}
`, "",{"version":3,"sources":["webpack://./src/app/components/language-switcher/language-switcher.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,qBAAqB;AAC3C","sourcesContent":[".language-selector {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.selected-flag {\r\n  margin-right: 10px; /* Adjust as needed */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
