import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { AutoIncreamentNumberSettings } from '@interfaces/AutoIncreamentNumberSettings.interface';

@Injectable({
  providedIn: 'root',
})
export class TenantSettingsService {
  constructor(private http: HttpClient) {}

  getTenantSettings(): Observable<TenantSettings> {
    return this.http.get<TenantSettings>(backend.tenantSettings.get).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
  updateTenantSettings(updatedTenantSettings: TenantSettings): Observable<TenantSettings> {
    return this.http.put<TenantSettings>(backend.tenantSettings.update, updatedTenantSettings).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  downloadLogo(logoToDownload: number): Observable<Blob> {
    return this.http.get<Blob>(backend.tenantSettings.downloadLogo(logoToDownload), { responseType: 'blob' as 'json' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  uploadLogo(logoToUpload: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(backend.tenantSettings.uploadLogo(logoToUpload), formData)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }


  getAutoIncreamentNumbersData(accountantScopeId: number): Observable<string> {
    return this.http.get(backend.tenantSettings.getAutoIncreamentNumbersData(accountantScopeId), { responseType: 'text' }).pipe(
        catchError((error: any) => {
            console.error('Error fetching data:', error);
            return throwError('Error fetching data. Please try again later.'); // Return an error message
        })
    );
}



updatAutoIncreamentNumbersData(accountantScopeId: number): Observable<any> {
  return this.http.put<any>(backend.tenantSettings.updateAutoIncreamentNumbersData(accountantScopeId), {}).pipe(
    catchError((err) => {
      console.error(err.error);
      return throwError(() => err.error);
    })
  );
}

}
