// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td{
    text-align-last: center
  }
  th{
    vertical-align: middle;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  table{
    outline: 1px solid #94a5a6;
    border-radius: 5px;
    padding: 5px;
  }
  .form-group-inline
  {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
  }
  
  .form-row-inline
  {
    display: flex;
    flex-wrap: wrap;
  }
  .form-text-group-inline
  {
    margin: 25px 20px;
    text-decoration-line: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/purchases/bills/new-bill/new-bill.component.css"],"names":[],"mappings":"AAAA;IACI;EACF;EACA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,kBAAkB;IAClB,YAAY;EACd;EACA;;IAEE,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;EACpB;;EAEA;;IAEE,aAAa;IACb,eAAe;EACjB;EACA;;IAEE,iBAAiB;IACjB,+BAA+B;EACjC","sourcesContent":["td{\r\n    text-align-last: center\r\n  }\r\n  th{\r\n    vertical-align: middle;\r\n    padding-right: 20px;\r\n    padding-left: 20px;\r\n  }\r\n  \r\n  table{\r\n    outline: 1px solid #94a5a6;\r\n    border-radius: 5px;\r\n    padding: 5px;\r\n  }\r\n  .form-group-inline\r\n  {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    padding-left: 20px;\r\n  }\r\n  \r\n  .form-row-inline\r\n  {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n  }\r\n  .form-text-group-inline\r\n  {\r\n    margin: 25px 20px;\r\n    text-decoration-line: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
