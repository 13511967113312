import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Column } from '@interfaces/Column.interface';
import { GetPaymentReceived } from '@interfaces/GetPaymentReceived.interface';
import { PaymentReceived } from '@interfaces/PaymentReceived.interface';
import { PaymentReceivedResponse } from '@interfaces/PaymentReceivedResponse.interface';
import { TranslateService } from '@ngx-translate/core';
import { PaymentReceivedService } from '@services/paymentReceived.service';
import { DataHelper } from '../../../../helpers/data-helper';
import { PaginationData } from '../../../../helpers/pagination-data';


@Component({
  selector: 'app-payments-received',
  templateUrl: './payments-received.component.html',
  styleUrls: ['./payments-received.component.css']
})
export class PaymentsReceivedComponent {
  displayedColumns: Column[] = [
    { prop: 'paymentDate', header: this.translate.instant('Payment Date'),transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'paymentNumber', header: this.translate.instant('Payment#') },
    { prop: 'customer', header: this.translate.instant('Customer') },
    { prop: 'invoice', header: this.translate.instant('Invoice#') },
    { prop: 'paymentType', header: this.translate.instant('Payment Type') },
    { prop: 'amountReceived', header: this.translate.instant('Amount') },
];

  payments: GetPaymentReceived[] = [];
  filteredPayments: GetPaymentReceived[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private paymentReceivedService: PaymentReceivedService, // Update the service name to match your application
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );


  ngOnInit(): void {
    this.fetchPayments();
   
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalPayments: number = 0;

  fetchPayments(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.paymentReceivedService.getPaymentsReceived(page, size),
      (response: PaymentReceivedResponse) => {
        console.log(response);
        this.payments = response.items;
        this.totalPayments = response.totalItems;
        this.filteredPayments = [...this.payments];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; // MatPaginator is 0-based, but typically APIs are 1-based.
    this.pageSize = event.pageSize;
    this.fetchPayments(this.currentPage, this.pageSize);
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const filterValue = target.value.trim().toLowerCase(); // Directly access value property

    this.filteredPayments = this.payments.filter(
      (payment) =>
      payment.amountReceived.toString().toLowerCase().includes(filterValue) ||
      payment.paymentNumber.toLowerCase().includes(filterValue) ||
      payment.paymentDate.includes(filterValue)
    );
  }
  addPaymentReceived(){
    this.router.navigate(['/sales/paymentsReceived/new_payment_received']); 
  }
  editPaymentReceived(event: any){
    this.router.navigate(['/sales/paymentsReceived/view_payment_received',event.id]); 
  }
}

