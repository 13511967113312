import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

// Import Components
import { InventoryComponent } from '@components/inventory/inventory.component';
import { ProductsComponent } from '@components/inventory/products/products.component';
import { CategoriesComponent } from '@components/masters/categories/categories.component';
import { BrandsComponent } from '@components/masters/brands/brands.component';

import { HrComponent } from '@components/hr/hr.component';
import { NewEmployeeComponent } from '@components/hr/employees/new-employee/new-employee.component';
import { EmployeesComponent } from '@components/hr/employees/employees.component';
import { DepartmentsComponent } from '@components/masters/departments/departments.component';
import { JobTitlesComponent } from '@components/masters/jobTitles/jobTitles.component';

import { NewDepartmentComponent } from '@components/masters/departments/new-department/new-department.component';
import { EditDepartmentComponent } from '@components/masters/departments/edit-department/edit-department.component';
import { NewJobTitleComponent } from '@components/masters/jobTitles/new-jobTitle/new-jobTitle.component';
import { EditJobTitleComponent } from '@components/masters/jobTitles/edit-jobTitle/edit-jobTitle.component';
import { EditEmployeeComponent } from '@components/hr/employees/edit-employee/edit-employee.component';
import { NewProductComponent } from '@components/inventory/products/new-product/new-product.component';
import { EditProductComponent } from '@components/inventory/products/edit-product/edit-product.component';
import { NewCategoryComponent } from '@components/masters/categories/new-category/new-category.component';
import { EditCategoryComponent } from '@components/masters/categories/edit-category/edit-category.component';
import { NewBrandComponent } from '@components/masters/brands/new-brand/new-brand.component';
import { EditBrandComponent } from '@components/masters/brands/edit-brand/edit-brand.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CurrencyComponent } from './components/masters/currency/currency.component';
import { AdminSettingsComponent } from './components/settings/admin-settings/admin-settings.component';
import { NewCurrencyComponent } from './components/masters/currency/new-currency/new-currency.component';
import { EditCurrencyComponent } from './components/masters/currency/edit-currency/edit-currency.component';
import { SalesComponent } from './components/sales/sales.component';
import { ServicesComponent } from './components/masters/services/services.component';
import { NewServiceComponent } from './components/masters/services/new-service/new-service.component';
import { EditServiceComponent } from './components/masters/services/edit-service/edit-service.component';
import { PaymentTypeComponent } from './components/masters/payment-type/payment-type.component';
import { NewPaymentTypeComponent } from './components/masters/payment-type/new-payment-type/new-payment-type.component';
import { EditPaymentTypeComponent } from './components/masters/payment-type/edit-payment-type/edit-payment-type.component';
import { CustomerComponent } from './components/sales/customer/customer.component';
import { NewCustomerComponent } from './components/sales/customer/new-customer/new-customer.component';
import { EditCustomerComponent } from './components/sales/customer/edit-customer/edit-customer.component';
import { InvoicesComponent } from './components/sales/invoices/invoices.component';
import { NewInvoiceComponent } from './components/sales/invoices/new-invoice/new-invoice.component';
import { EditInvoiceComponent } from './components/sales/invoices/edit-invoice/edit-invoice.component';
import { MastersComponent } from '@components/masters/masters.component';
import { PreviewInvoiceComponent } from './components/sales/invoices/preview-invoice/preview-invoice.component';
import { PaymentsReceivedComponent } from './components/sales/payments-received/payments-received/payments-received.component';
import { NewPaymentReceivedComponent } from './components/sales/payments-received/new-payment-received/new-payment-received.component';
import { ViewPaymentReceivedComponent } from './components/sales/payments-received/view-payment-received/view-payment-received.component';
import { SupplierComponent } from '@components/purchases/supplier/supplier.component';
import { NewSupplierComponent } from '@components/purchases/supplier/new-supplier/new-supplier.component';
import { ViewSupplierComponent } from '@components/purchases/supplier/view-supplier/view-supplier.component';

import { LoginComponent } from '@components/login/login.component';
import { OrdersComponent } from '@components/purchases/orders/orders.component';
import { NewOrderComponent } from '@components/purchases/orders/new-order/new-order.component';
import { ViewOrderComponent } from '@components/purchases/orders/view-order/view-order.component';
import { EditOrderComponent } from '@components/purchases/orders/edit-order/edit-order.component';
import { PurchaseReceivesComponent } from '@components/purchases/purchase-receives/purchase-receives.component';
import { NewPurchaseReceivesComponent } from '@components/purchases/purchase-receives/new-purchase-receives/new-purchase-receives.component';
import { EditPurchaseReceivesComponent } from '@components/purchases/purchase-receives/edit-purchase-receives/edit-purchase-receives.component';
import { BillsComponent } from '@components/purchases/bills/bills.component';
import { NewBillComponent } from '@components/purchases/bills/new-bill/new-bill.component';
import { EditBillComponent } from '@components/purchases/bills/edit-bill/edit-bill.component';
import { UserManagementComponent } from '@components/settings/user-management/user-management.component';
import { NewUserComponent } from '@components/settings/user-management/new-user/new-user.component';
import { EditUserComponent } from '@components/settings/user-management/edit-user/edit-user.component';
import { VatComponent } from '@components/masters/vat/vat.component';
import { EditVatComponent } from '@components/masters/vat/edit-vat/edit-vat.component';
import { NewVatComponent } from '@components/masters/vat/new-vat/new-vat.component';
import { EmailComponent } from './helpers/email/email.component';
import { CanDeactivateGuard } from './canDeactivateGuard.interface';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { EquipmentCategoriesComponent } from '@components/masters/equipment-categories/equipment-categories.component';
import { NewEquipmentCategoryComponent } from '@components/masters/equipment-categories/new-equipment-category/new-equipment-category.component';
import { EditEquipmentCategoryComponent } from '@components/masters/equipment-categories/edit-equipment-category/edit-equipment-category.component';
import { EquipmentComponent } from '@components/equipment/equipment.component';
import { NewEquipmentComponent } from '@components/equipment/new-equipment/new-equipment.component';
import { EditEquipmentComponent } from '@components/equipment/edit-equipment/edit-equipment.component';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboard'  },
    children: [
      { path: '', component: DashboardComponent },
    ]
  },
  {
    path: 'masters',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: 'jobTitles',
        data: { breadcrumb: 'Job titles' },
        children: [
          { path: '', component: JobTitlesComponent },
          {
            path: 'new_jobTitle',
            component: NewJobTitleComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new job title' },
          },
          {
            path: 'edit_jobTitle/:jobTitleId',
            component: EditJobTitleComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'jobTitleName' } },
          },
        ],
      },
      {
        path: 'brands',
        data: { breadcrumb: 'Brands' },
        children: [
          { path: '', component: BrandsComponent },
          {
            path: 'new_brand',
            component: NewBrandComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new brand' },
          },
          {
            path: 'edit_brand/:id',
            component: EditBrandComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'brandName' } },
          },
        ],
      },
      {
        path: 'categories',
        data: { breadcrumb: 'Inventory categories' },
        children: [
          { path: '', component: CategoriesComponent },
          {
            path: 'new_category',
            component: NewCategoryComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new category' },
          },
          {
            path: 'edit_category/:id',
            component: EditCategoryComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'categoryName' } },
          },
        ],
      },


      {
        path: 'equipmentCategories',
        data: { breadcrumb: 'Equipment categories' },
        children: [
          { path: '', component: EquipmentCategoriesComponent },
          {
            path: 'new_equipment_category',
            component: NewEquipmentCategoryComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new equipment category' },
          },
          {
            path: 'edit_equipment_category/:id',
            component: EditEquipmentCategoryComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'categoryName' } },
          },
        ],
      },
      {
        path: 'paymentTypes',
        data: { breadcrumb: 'Payment types' },
        children: [
          { path: '', component: PaymentTypeComponent },
          {
            path: 'new_payment_type',
            component: NewPaymentTypeComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new payment type' },
          },
          {
            path: 'edit_payment_type/:id',
            component: EditPaymentTypeComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'typeName' } },
          },
        ],
      },
      {
        path: 'services',
        data: { beadcrumb: 'Services' },
        children: [
          { path: '', component: ServicesComponent },
          {
            path: 'new_service',
            component: NewServiceComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new service' },
          },
          {
            path: 'edit_service/:id',
            component: EditServiceComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'serviceName' } },
          },
        ],
      },
      {
        path: 'currencies',
        data: { breadcrumb: 'Currencies' },
        children: [
          { path: '', component: CurrencyComponent },
          {
            path: 'new_currency',
            component: NewCurrencyComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new currency' },
          },
          {
            path: 'edit_currency/:id',
            component: EditCurrencyComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'currencyName' } },
          },
        ],
      },
      {
        path: 'departments',
        data: { breadcrumb: 'Departments' },
        children: [
          { path: '', component: DepartmentsComponent },
          {
            path: 'new_department',
            component: NewDepartmentComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new department' },
          },
          {
            path: 'edit_department/:departmentId',
            component: EditDepartmentComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'departmentName' } },
          },
        ],
      },
      {
        path: 'vats',
        data: { breadcrumb: 'Vats' },
        children: [
          { path: '', component: VatComponent },
          {
            path: 'new_vat',
            component: NewVatComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new vat' },
          },
          {
            path: 'edit_vat/:id',
            component: EditVatComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'vatName' } },
          },
        ],
      },
    ],
  },
  {
    path: 'humanResources',
    canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: 'employees',
        data: { breadcrumb: 'Employees' },
        children: [
          { path: '', component: EmployeesComponent },
          {
            path: 'new_employee',
            component: NewEmployeeComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new employee' },
          },
          {
            path: 'edit_employee/:id',
            component: EditEmployeeComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'employeeName' } },
          },
        ],
      },
    ],
  },

  {
    path: 'inventory',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: 'products/:categoryName',
        data: { breadcrumb: 'Products' },
        children: [
          { path: '', component: ProductsComponent },
          {
            path: 'new_product',
            component: NewProductComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new product' },
          },
          {
            path: 'edit_product/:id',
            component: EditProductComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'productName' } },
          },
        ],
      },
    ],
  },

  
  {
    path: 'equipment',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: ':equipmentCategoryName',
        data: { breadcrumb: 'Medical equipment' },
        children: [
          { path: '', component: EquipmentComponent},
          {
            path: 'new_equipment',
            component: NewEquipmentComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new equipment' },
          },
          {
            path: 'edit_equipment/:id',
            component: EditEquipmentComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'equipmentName' } },
          },
        ],
      },
    ],
  },

  {
    path: 'sales',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: 'customers',
        data: { breadcrumb: 'Customers' },
        children: [
          { path: '', component: CustomerComponent },
          {
            path: 'new_customer',
            component: NewCustomerComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new customer' },
          },
          {
            path: 'edit_customer/:id',
            component: EditCustomerComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'customerName' } },
          },
        ],
      },

      {
        path: 'invoices',
        data: { breadcrumb: 'Invoices' },
        children: [
          { path: '', component: InvoicesComponent },
          {
            path: 'new_invoice',
            component: NewInvoiceComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new invoice' },
          },
          {
            path: 'edit_invoice/:id',
            component: EditInvoiceComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'invoiceNumber' } },
          },
          {
            path: 'preview_invoice/:id',
            component: PreviewInvoiceComponent,
            data: { breadcrumb: { alias: 'invoiceNumber' } },
          },
        ],
      },
      {
        path: 'paymentsReceived',
        data: { breadcrumb: 'Payments received' },
        children: [
          { path: '', component: PaymentsReceivedComponent },
          {
            path: 'new_payment_received',
            component: NewPaymentReceivedComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new payment receive' },
          },
          {
            path: 'view_payment_received/:id',
            component: ViewPaymentReceivedComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'paymentReceiveNumber' } },
          },
        ],
      },
    ],
  },

  {
    path: 'purchases',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: 'suppliers',
        data: { breadcrumb: 'Suppliers' },
        children: [
          { path: '', component: SupplierComponent },
          {
            path: 'new_supplier',
            component: NewSupplierComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new supplier' },
          },
          {
            path: 'view_supplier/:id',
            component: ViewSupplierComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'supplierName' } },
          },
        ],
      },
      {
        path: 'orders',
        data: { breadcrumb: 'Orders' },
        children: [
          { path: '', component: OrdersComponent },
          {
            path: 'new_order',
            component: NewOrderComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new order' },
          },
          {
            path: 'view_order/:id',
            component: ViewOrderComponent,
            data: { breadcrumb: { alias: 'orderNumber' } },
          },
          {
            path: 'edit_order/:id',
            component: EditOrderComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'orderNumber' } },
          },
        ],
      },


      {
        path: 'purchaseReceives',
        data: { breadcrumb: 'Purchase received' },
        children: [
          { path: '', component: PurchaseReceivesComponent },
          {
            path: 'new_purchaseReceive',
            component: NewPurchaseReceivesComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new purchase receive' },
          },
          {
            path: 'edit_purchaseReceive/:id',
            component: EditPurchaseReceivesComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'purchaseReceiveNumber' } },
          },
        ],
      },
      {
        path: 'bills',
        data: { breadcrumb: 'Bills' },
        children: [
          { path: '', component: BillsComponent },
          {
            path: 'new_bill',
            component: NewBillComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new bill' },
          },
          {
            path: 'edit_bill/:id',
            component: EditBillComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'billNumber' } },
          },
        ],
      },
    ],
  },

  {
    path: 'settings',
     canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      { path: 'erpSettings', component: AdminSettingsComponent, data: { breadcrumb: 'Erp settings' }, },
      {
        path: 'userManagement',
        data: { breadcrumb: 'User management' },
        children: [
          { path: '', component: UserManagementComponent },
          {
            path: 'new_user',
            component: NewUserComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: 'Add new user' },
          },
          {
            path: 'edit_user/:id',
            component: EditUserComponent,
             canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: 'userName' } },
          },
        ],
      },
    ],
  },

  {
    path: 'helpers',
    canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    children: [
      { path: 'email', component: EmailComponent, data: { breadcrumb: 'Send Email' }, },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },  { path: '**', redirectTo: '/login', data: { reuse: false } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
