import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { SuppliersResponse } from '@interfaces/SupplierResponse.interface';
import { Supplier } from '@interfaces/Supplier.interface';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private http: HttpClient) {}

  getSuppliers(
    pageNumber: number,
    pageSize: number
  ): Observable<SuppliersResponse> { 
    return this.http
      .get<SuppliersResponse>(backend.supplier.getAll(pageNumber, pageSize)) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addSupplier(supplier: Supplier): Observable<any> { 
    return this.http
      .post(backend.supplier.post, supplier, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteSupplier(supplierId: number): Observable<void> { 
    return this.http
      .delete<void>(`${backend.supplier.deleteById(supplierId)}`) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getSupplierById(supplierId: number): Observable<Supplier> { 
    return this.http
      .get<Supplier>(`${backend.supplier.getById(supplierId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateSupplierById(supplierId: number, supplier: Supplier): Observable<any> { 
    return this.http
      .put(backend.supplier.updateById(supplierId), supplier, {
        responseType: 'text',
      }) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
