import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { InvoiceResponse } from '@interfaces/InvoiceResponse.interface';
import { Invoice } from '@interfaces/Invoice.interface';
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  getUnpaidInvoicesByCustomer(
    customerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<InvoiceResponse> {
    return this.http
      .get<InvoiceResponse>(
        backend.invoices.getUnpaidInvoicesByCustomer(customerId,pageNumber, pageSize)
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getAllWithoutInvoiceLines(
    pageNumber: number,
    pageSize: number
  ): Observable<InvoiceResponse> {
    return this.http
      .get<InvoiceResponse>(
        backend.invoices.getAllWithoutInvoiceLines(pageNumber, pageSize)
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  //TODO
  getByIdWithInvoiceLines(invoiceId: number): Observable<Invoice> {
    return this.http
      .get<Invoice>(
        `${backend.invoices.getByIdWithInvoiceLines(invoiceId)}`
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addInvoice(invoice: any): Observable<any> {
    return this.http
      .post(backend.invoices.post, invoice, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deleteInvoice(invoiceId: number): Observable<void> {
    return this.http
      .delete<void>(`${backend.invoices.deleteById(invoiceId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updateInvoiceById(invoiceId: number, invoice: Invoice): Observable<any> {
    return this.http
      .put(backend.invoices.updateById(invoiceId), invoice, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  sendEmail(formData: FormData) {
    return this.http
    .post(backend.invoices.sendEmail, formData, { responseType: 'text' })
    .pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}