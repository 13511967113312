import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { PurchaseReceivesResponse } from '@interfaces/PurchaseReceivesResponse.interface';
import { PurchaseReceive } from '@interfaces/PurchaseReceive.interface';
import { ExtendedOrderItems } from '@interfaces/ExtendedOrderItems.interface';
import { GetOrder } from '@interfaces/GetOrder.interface';

@Injectable({
  providedIn: 'root',
})
export class PurchaseReceiveService {
  constructor(private http: HttpClient) {}

  getPurchaseReceives(
    pageNumber: number,
    pageSize: number
  ): Observable<PurchaseReceivesResponse> { 
    return this.http
      .get<PurchaseReceivesResponse>(backend.purchaseReceives.getAll(pageNumber, pageSize)) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  addPurchaseReceive(purchaseReceive: PurchaseReceive): Observable<any> { 
    return this.http
      .post(backend.purchaseReceives.post, purchaseReceive, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  deletePurchaseReceive(purchaseReceiveId: number): Observable<void> { 
    return this.http
      .delete<void>(`${backend.purchaseReceives.deleteById(purchaseReceiveId)}`) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getPurchaseReceiveById(purchaseReceiveId: number): Observable<PurchaseReceive> { 
    return this.http
      .get<PurchaseReceive>(`${backend.purchaseReceives.getById(purchaseReceiveId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  updatePurchaseReceiveById(purchaseReceiveId: number, purchaseReceive: PurchaseReceive): Observable<any> { 
    return this.http
      .put(backend.purchaseReceives.updateById(purchaseReceiveId), purchaseReceive, {
        responseType: 'text',
      }) 
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getUnreceivedOrdersBySupplier(supplierId: number): Observable<GetOrder[]> { 
    return this.http
      .get<GetOrder[]>(`${backend.purchaseReceives.getUnreceivedOrdersBySupplier(supplierId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  getUnreceivedOrderItems(supplierId: number, orderId: number): Observable<ExtendedOrderItems[]> { 
    return this.http
      .get<ExtendedOrderItems[]>(`${backend.purchaseReceives.getUnreceivedOrderItems(supplierId,orderId)}`)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
