import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierService } from '@services/supplier.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { HResourceDataService } from '@services/resourceData.service';
import { SuppliersResponse } from '@interfaces/SupplierResponse.interface';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Supplier } from '@interfaces/Supplier.interface';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-supplier',
  templateUrl: './view-supplier.component.html',
  styleUrls: ['./view-supplier.component.css'],
})
export class ViewSupplierComponent implements OnInit {
  supplierForm!: FormGroup;
  countriesMap: { [id: number]: string } = this.hResourceService.countries;
  regionsMap: { [id: number]: string } = this.hResourceService.regions;
  supplierId: number =0;
  isEditMode: boolean = false;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}
initialFormData: Supplier | null = null;

  faEdit = faPen;
  faDelete = faTrashCan;
  faExit = faArrowLeftRotate;

  
  constructor(
    private fb: FormBuilder,
    private supplierService: SupplierService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceService: HResourceDataService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.supplierForm = this.fb.group({
      contactName: ['', Validators.required],
      contactTitle: ['', Validators.required],
      companyName: [''],
      taxNumber: ['', Validators.required],
      postalCode: [''],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: [''],
      city: ['', Validators.required],
      regionId: ['', Validators.required],
      countryId: ['', Validators.required]
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog,
  );

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.supplierId = +id;
        this.supplierService
         .getSupplierById(this.supplierId)
          .subscribe((response: Supplier) => {
            console.log(response)
            this.supplierForm.patchValue(response);
            this.initialFormData = { ...response };
            this.breadcrumbService.set('@supplierName',response.contactName);
          });
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.supplierForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.supplierForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }


  onSubmit() {
    if(this.supplierForm.invalid){
      Object.values(this.supplierForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.supplierForm.getRawValue();
    const dataToSend = {
      contactName: formValue.contactName,
      contactTitle: formValue.contactTitle,
      taxNumber: formValue.taxNumber,
      companyName: formValue.companyName,
      postalCode: formValue.postalCode,
      phone: formValue.phone,
      email: formValue.email,
      website: formValue.website || '',
      city: formValue.city,
      regionId: Number(formValue.regionId) ,
      countryId: Number(formValue.countryId)
    };

 
    this.supplierService.updateSupplierById(this.supplierId,dataToSend).subscribe(
      () => {
        Swal.fire(
          'Success',
          'Supplier updated successfully.',
          'success'
        ).then(() => {
          this.supplierForm.markAsPristine();
          this.supplierForm.markAsUntouched();
          this.supplierForm.reset();
          this.router.navigate(['/purchases/suppliers']);
        });
      },
      (error) => Swal.fire('Error', error, 'error')
    );
     
  }

  cancel() {
    if (this.supplierForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }
  
  revertForm() {
    if (this.initialFormData) {
      this.supplierForm.patchValue(this.initialFormData);
    }
    this.supplierForm.markAsPristine();
    this.supplierForm.markAsUntouched();
    this.isEditMode = false;
    // Optionally, remove navigation if you want to stay on the page
    // this.router.navigate(['/purchases/suppliers']);
  }

  onEdit(){
   this.isEditMode=true;

  }

  onDelete(){
      this.dataHelper.withConfirmation(
        'Delete Confirmation',
        'Do you confirm the deletion of this supplier? Some changes may affect major functionalities',
        () => this.supplierService.deleteSupplier(this.supplierId),
        'Supplier deleted successfully',
        () => this.router.navigate(['/purchases/suppliers']),
        () => null
      );
    }
  

  exitView(){
    this.router.navigate(['/purchases/suppliers']);
  }
}
