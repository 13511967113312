import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '@services/locale.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css'],
})
export class LanguageSwitcherComponent implements OnInit {
  defaultLanguage!: string;

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.defaultLanguage = localStorage.getItem('selectedLanguage') || 'English';
    this.translate.use(this.defaultLanguage);
  }

  changeLanguage(language: string) {
    if (language) {
      this.localeService.updateLocale(language).subscribe({
        next: () => {
          this.translate.use(language);
          this.defaultLanguage = language;
          localStorage.setItem('selectedLanguage', language); // Store selected language
          window.location.reload();
        },
        error: (error) => {
          console.error('Error updating locale:', error);
        },
      });
    }
  }

  getFlagIcon(language: string): string {
    switch (language) {
      case 'English':
        return 'fi fi-gb';
      case 'Greek':
        return 'fi fi-gr';
      default:
        return 'fi fi-gb'; // Default to US flag
    }
  }
}
