import { Component, OnInit } from '@angular/core';
import { BillService } from '@services/bill.service';
import { Bill } from '@interfaces/Bill.interface'; 
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { BillResponse } from '@interfaces/BillResponse.interface';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css'], 
})
export class BillsComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'billDate', header: this.translate.instant('Date'), transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'billNumber', header: this.translate.instant('Bill#') },
    { prop: 'status', header: this.translate.instant('Status') },
    { prop: 'dueDate', header: this.translate.instant('Due date'), transform: (value: any) => new Date(value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })  },
    { prop: 'ammount', header: this.translate.instant('Ammount') },
    { prop: 'balanceDue', header: this.translate.instant('Balance due') }
  ];

  constructor(
    private translate: TranslateService,
    private billService: BillService, 
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  bills: Bill[] = [];
  filteredBills = [...this.bills];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchBills(); 
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalBills: number = 0; 

  fetchBills(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.billService.getBills(page, size), 
      (response: BillResponse) => { 
        this.bills = response.items; 
        this.totalBills = response.totalItems; 
        this.filteredBills = [...this.bills];
        console.log(this.bills)
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchBills(this.currentPage, this.pageSize);
  }


  addBill(): void { 
    this.router.navigate(['/purchases/bills/new_bill']);
  }
  editBill(element: Bill): void { 
    this.router.navigate(['/purchases/bills/edit_bill', element.id]);
  }
}
