import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { backend } from '@apis/api-endpoints';
import { InventoryData } from '@interfaces/InventoryData.interface';
import { EquipmentData } from '@interfaces/EquipmentData.interface';

@Injectable({
  providedIn: 'root',
})
export class EquipmentDataService {
  private equipmentCategoryMap: { [id: number]: string } = {};
  // BehaviorSubject to track if data has been loaded
  private dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.loadMaps();
  }

  private loadMaps(): void {
    if (!this.dataLoaded$.getValue()) {
      this.getEquipmentData().subscribe((data: EquipmentData) => {
        this.createMap(data.categories, this.equipmentCategoryMap);
        this.dataLoaded$.next(true); // Indicate that data has been loaded
      });
    }
  }

  private createMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach((item) => {
      mapRef[item.id] = item.name;
    });
  }

  get equipmentCategories(): { [id: number]: string } {
    return this.equipmentCategoryMap;
  }

  getEquipmentData(): Observable<EquipmentData> {
    console.log(this.http.get<EquipmentData>(backend.equipmentDropdownData.get))
    return this.http.get<EquipmentData>(backend.equipmentDropdownData.get).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }
}
