// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td{
  text-align-last: center
}
th{
  vertical-align: middle;
  padding-right: 20px;
  padding-left: 20px;
}

table{
  outline: 1px solid #94a5a6;
  border-radius: 5px;
  padding: 5px;
}
.form-group-inline
{
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}

.form-row-inline
{
  display: flex;
  flex-wrap: wrap;
}
.form-text-group-inline
{
  margin: 25px 20px;
  text-decoration-line: underline;
}
.highlight-red {
  border: 2px solid red;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/sales/payments-received/new-payment-received/new-payment-received.component.css"],"names":[],"mappings":"AAAA;EACE;AACF;AACA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,YAAY;AACd;AACA;;EAEE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;;EAEE,aAAa;EACb,eAAe;AACjB;AACA;;EAEE,iBAAiB;EACjB,+BAA+B;AACjC;AACA;EACE,qBAAqB;AACvB","sourcesContent":["td{\r\n  text-align-last: center\r\n}\r\nth{\r\n  vertical-align: middle;\r\n  padding-right: 20px;\r\n  padding-left: 20px;\r\n}\r\n\r\ntable{\r\n  outline: 1px solid #94a5a6;\r\n  border-radius: 5px;\r\n  padding: 5px;\r\n}\r\n.form-group-inline\r\n{\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  padding-left: 20px;\r\n}\r\n\r\n.form-row-inline\r\n{\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n}\r\n.form-text-group-inline\r\n{\r\n  margin: 25px 20px;\r\n  text-decoration-line: underline;\r\n}\r\n.highlight-red {\r\n  border: 2px solid red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
