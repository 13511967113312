import { Component, OnInit } from '@angular/core';
import { CurrencyService } from '@services/currency.service';
import { Currency } from '@interfaces/Currency.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
})
export class CurrencyComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
];

  currencies: Currency[] = [];
  filteredCurrencies: Currency[] = [];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  constructor(
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchCurrencies();
  }

  fetchCurrencies(): void {
    this.dataHelper.fetchItems(
      this.currencyService.getAll(),
      (data: Currency[]) => {
        this.currencies = data;
        this.filteredCurrencies = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.filteredCurrencies = this.currencies.filter(currency =>
      currency.name.toLowerCase().includes(filterValue)
    );
  }

  deleteCurrency(element: Currency): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this currency? Some changes may affect major functionalities',
      () => this.currencyService.deleteCurrency(element.id),
      'Currency deleted successfully',
      () => this.fetchCurrencies(),
      () => null
    );
  }

  addCurrency() {
    this.router.navigate(['/masters/currencies/new_currency']);
  }

  editCurrency(element: Currency): void {
    this.router.navigate(['/masters/currencies/edit_currency', element.id]);
  }
}
