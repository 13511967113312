// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.larger-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.menu-header {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  padding: 10px;
}

.profile-container {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center children vertically */
}

.username {
  margin-left: 10px; /* Add some space between the image and the username */
}
`, "",{"version":3,"sources":["webpack://./src/app/components/user-profile/user-profile.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB,EAAE,8BAA8B;EACtD,mBAAmB,EAAE,iCAAiC;EACtD,uBAAuB,EAAE,+BAA+B;EACxD,aAAa;AACf;;AAEA;EACE,aAAa,EAAE,gBAAgB;EAC/B,mBAAmB,EAAE,+BAA+B;AACtD;;AAEA;EACE,iBAAiB,EAAE,sDAAsD;AAC3E","sourcesContent":[".profile-pic {\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 50%;\r\n  cursor: pointer;\r\n}\r\n\r\n.larger-profile-pic {\r\n  width: 60px;\r\n  height: 60px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.menu-header {\r\n  display: flex;\r\n  flex-direction: column; /* Stack children vertically */\r\n  align-items: center; /* Center children horizontally */\r\n  justify-content: center; /* Center children vertically */\r\n  padding: 10px;\r\n}\r\n\r\n.profile-container {\r\n  display: flex; /* Use Flexbox */\r\n  align-items: center; /* Center children vertically */\r\n}\r\n\r\n.username {\r\n  margin-left: 10px; /* Add some space between the image and the username */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
