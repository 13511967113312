import { Component } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faCaretDown,
  faArrowRightFromBracket,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { DataHelper } from 'src/app/helpers/data-helper';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BASE_URL } from '@apis/api-endpoints';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { LocaleService } from '@services/locale.service';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
})
export class TopNavComponent {
  faUser = faUser;
  faCaretDown = faCaretDown;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faGear = faGear;
  logoUrl: string | null = null;
  defaultLanguage = this.localeService.getCurrentLocale() || 'English';
  constructor(
    private readonly keycloak: KeycloakService,
    private readonly router: Router,
    private translate: TranslateService,
    private localeService: LocaleService,

    private tenantSettingsService: TenantSettingsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.fetchTenantSettings();
    this.translate.use(this.defaultLanguage);
  }
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  logout() {
    this.keycloak.logout().then(() => {
      this.router.navigate(['/home']);
    });
  }
  changeLanguage(event: MatSelectChange) {
    const selectedValue = event.value;
    if (selectedValue) {
      this.localeService.updateLocale(selectedValue).subscribe({
        next: () => {
          this.translate.use(selectedValue);
          window.location.reload();
        },
        error: (error) => {
          console.error('Error updating locale:', error);
        },
      });
    }
  }

  fetchTenantSettings(): void {
    this.dataHelper.fetchItems(
      this.tenantSettingsService.getTenantSettings(),
      (response: TenantSettings) => {
        this.logoUrl = `${BASE_URL}/${response.logoPath}`;
      })}
}
