// Angular Modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// Third-Party Modules
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";


// App Modules
import { AppRoutingModule } from './app-routing.module';

// App Components

// Top Nav Components

// Inventory Components

// HR Components

// Services Components

// Sales Components

// App Initializers
import { initKeycloak } from './init-keycloak';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from 'src/app/helpers/error-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/helpers/confirmation.component';
import { SidebarComponent } from '@layout/left-nav/left-nav.component';
import { LanguageSwitcherComponent } from '@components/language-switcher/language-switcher.component';
import { TopNavComponent } from '@layout/top-nav/top-nav.component';
import { UserProfileComponent } from '@components/user-profile/user-profile.component';
import { LoginComponent } from '@components/login/login.component';
import { LogoutComponent } from '@components/logout/logout.component';
import { InventoryComponent } from '@components/inventory/inventory.component';
import { ProductsComponent } from '@components/inventory/products/products.component';
import { CategoriesComponent } from '@components/masters/categories/categories.component';
import { BrandsComponent } from '@components/masters/brands/brands.component';
import { HrComponent } from '@components/hr/hr.component';
import { NewEmployeeComponent } from '@components/hr/employees/new-employee/new-employee.component';
import { EmployeesComponent } from '@components/hr/employees/employees.component';
import { DepartmentsComponent } from '@components/masters/departments/departments.component';
import { JobTitlesComponent } from '@components/masters/jobTitles/jobTitles.component';
import { EditEmployeeComponent } from '@components/hr/employees/edit-employee/edit-employee.component';
import { NewDepartmentComponent } from '@components/masters/departments/new-department/new-department.component';
import { EditDepartmentComponent } from '@components/masters/departments/edit-department/edit-department.component';
import { NewJobTitleComponent } from '@components/masters/jobTitles/new-jobTitle/new-jobTitle.component';
import { EditJobTitleComponent } from '@components/masters/jobTitles/edit-jobTitle/edit-jobTitle.component';
import { NewProductComponent } from './components/inventory/products/new-product/new-product.component';
import { EditProductComponent } from './components/inventory/products/edit-product/edit-product.component';
import { EditCategoryComponent } from './components/masters/categories/edit-category/edit-category.component';
import { NewCategoryComponent } from './components/masters/categories/new-category/new-category.component';
import { NewBrandComponent } from './components/masters/brands/new-brand/new-brand.component';
import { EditBrandComponent } from './components/masters/brands/edit-brand/edit-brand.component';
import { CustomPaginatorIntl } from '@services/customPaginator.service';
import { SettingsComponent } from './components/settings/settings.component';
import { CurrencyComponent } from './components/masters/currency/currency.component';
import { AdminSettingsComponent } from './components/settings/admin-settings/admin-settings.component';
import { NewCurrencyComponent } from './components/masters/currency/new-currency/new-currency.component';
import { EditCurrencyComponent } from './components/masters/currency/edit-currency/edit-currency.component';
import { SalesComponent } from './components/sales/sales.component';
import { ServicesComponent } from './components/masters/services/services.component';
import { NewServiceComponent } from './components/masters/services/new-service/new-service.component';
import { EditServiceComponent } from './components/masters/services/edit-service/edit-service.component';
import { PaymentTypeComponent } from './components/masters/payment-type/payment-type.component';
import { NewPaymentTypeComponent } from './components/masters/payment-type/new-payment-type/new-payment-type.component';
import { EditPaymentTypeComponent } from './components/masters/payment-type/edit-payment-type/edit-payment-type.component';
import { CustomerComponent } from './components/sales/customer/customer.component';
import { NewCustomerComponent } from './components/sales/customer/new-customer/new-customer.component';
import { EditCustomerComponent } from './components/sales/customer/edit-customer/edit-customer.component';
import { InvoicesComponent } from './components/sales/invoices/invoices.component';
import { NewInvoiceComponent } from './components/sales/invoices/new-invoice/new-invoice.component';
import { PaginatedTableComponent } from './helpers/paginated-table/paginated-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditInvoiceLineComponent } from './components/sales/invoices/invoice-line/edit-invoice-line/edit-invoice-line.component';
import { NewInvoiceLineComponent } from './components/sales/invoices/invoice-line/new-invoice-line/new-invoice-line.component';
import { EditInvoiceComponent } from './components/sales/invoices/edit-invoice/edit-invoice.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MastersComponent } from './components/masters/masters.component';
import { PreviewInvoiceComponent } from './components/sales/invoices/preview-invoice/preview-invoice.component';
import {NgxPrintModule} from 'ngx-print';
import { PaymentsReceivedComponent } from './components/sales/payments-received/payments-received/payments-received.component';
import { NewPaymentReceivedComponent } from './components/sales/payments-received/new-payment-received/new-payment-received.component';
import { ViewPaymentReceivedComponent } from './components/sales/payments-received/view-payment-received/view-payment-received.component';
import { SupplierComponent } from './components/purchases/supplier/supplier.component';
import { NewSupplierComponent } from './components/purchases/supplier/new-supplier/new-supplier.component';
import { ViewSupplierComponent } from './components/purchases/supplier/view-supplier/view-supplier.component';
import { BreadcrumbActionsComponent } from './helpers/breadcrumb-actions/breadcrumb-actions.component';

import {BreadcrumbModule} from "xng-breadcrumb";
import { AuthGuard } from './auth.guard';
import { NewOrderComponent } from './components/purchases/orders/new-order/new-order.component';
import { ViewOrderComponent } from './components/purchases/orders/view-order/view-order.component';
import { OrdersComponent } from '@components/purchases/orders/orders.component';
import { NewOrderItemComponent } from './components/purchases/orders/order-item/new-order-item/new-order-item.component';
import { EditOrderItemComponent } from './components/purchases/orders/order-item/edit-order-item/edit-order-item.component';
import { EditOrderComponent } from './components/purchases/orders/edit-order/edit-order.component';
import { PurchaseReceivesComponent } from './components/purchases/purchase-receives/purchase-receives.component';
import { NewPurchaseReceivesComponent } from './components/purchases/purchase-receives/new-purchase-receives/new-purchase-receives.component';
import { EditPurchaseReceivesComponent } from './components/purchases/purchase-receives/edit-purchase-receives/edit-purchase-receives.component';
import { BillsComponent } from './components/purchases/bills/bills.component';
import { NewBillComponent } from './components/purchases/bills/new-bill/new-bill.component';
import { EditBillComponent } from './components/purchases/bills/edit-bill/edit-bill.component';
import { UserManagementComponent } from '@components/settings/user-management/user-management.component';
import { NewUserComponent } from '@components/settings/user-management/new-user/new-user.component';
import { EditUserComponent } from '@components/settings/user-management/edit-user/edit-user.component';
import { NewServiceItemComponent } from './components/masters/services/service-item/new-service-item/new-service-item.component';
import { UdpateServiceItemComponent } from './components/masters/services/service-item/udpate-service-item/udpate-service-item.component';
import { VatComponent } from './components/masters/vat/vat.component';
import { NewVatComponent } from './components/masters/vat/new-vat/new-vat.component';
import { EditVatComponent } from './components/masters/vat/edit-vat/edit-vat.component';
import { EmailComponent } from './helpers/email/email.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EquipmentCategoriesComponent } from './components/masters/equipment-categories/equipment-categories.component';
import { NewEquipmentCategoryComponent } from '@components/masters/equipment-categories/new-equipment-category/new-equipment-category.component';
import { EditEquipmentCategoryComponent } from '@components/masters/equipment-categories/edit-equipment-category/edit-equipment-category.component';
import { EquipmentComponent } from '@components/equipment/equipment.component';
import { NewEquipmentComponent } from '@components/equipment/new-equipment/new-equipment.component';
import { EditEquipmentComponent } from '@components/equipment/edit-equipment/edit-equipment.component';
import { LocaleService } from '@services/locale.service';
import { NZ_I18N, el_GR, en_US } from 'ng-zorro-antd/i18n';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

// Define the loader for translations
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HrComponent,
    ErrorDialogComponent,
    ConfirmationDialogComponent,
    SidebarComponent,
    TopNavComponent,
    UserProfileComponent,
    LanguageSwitcherComponent,
    LoginComponent,
    LogoutComponent,
    InventoryComponent,
    ProductsComponent,
    CategoriesComponent,
    BrandsComponent,
    HrComponent,
    NewEmployeeComponent,
    EmployeesComponent,
    DepartmentsComponent,
    JobTitlesComponent,
    EditEmployeeComponent,
    NewDepartmentComponent,
    EditDepartmentComponent,
    NewJobTitleComponent,
    EditJobTitleComponent,
    NewProductComponent,
    EditProductComponent,
    EditCategoryComponent,
    NewCategoryComponent,
    NewBrandComponent,
    EditBrandComponent,
    SettingsComponent,
    CurrencyComponent,
    AdminSettingsComponent,
    NewCurrencyComponent,
    EditCurrencyComponent,
    SalesComponent,
    ServicesComponent,
    NewServiceComponent,
    EditServiceComponent,
    PaymentTypeComponent,
    NewPaymentTypeComponent,
    EditPaymentTypeComponent,
    CustomerComponent,
    NewCustomerComponent,
    EditCustomerComponent,
    InvoicesComponent,
    NewInvoiceComponent,
    NewInvoiceLineComponent,
    PaginatedTableComponent,
    EditInvoiceLineComponent,
    NewInvoiceLineComponent,
    EditInvoiceComponent,
    MastersComponent,
    PreviewInvoiceComponent,
    PaymentsReceivedComponent,
    NewPaymentReceivedComponent,
    ViewPaymentReceivedComponent,
    SupplierComponent,
    NewSupplierComponent,
    ViewSupplierComponent,
    BreadcrumbActionsComponent,
    UserManagementComponent,
    NewUserComponent,
    EditUserComponent,
    OrdersComponent,
    NewOrderComponent,
    ViewOrderComponent,
    NewOrderItemComponent,
    EditOrderItemComponent,
    EditOrderComponent,
    PurchaseReceivesComponent,
    NewPurchaseReceivesComponent,
    EditPurchaseReceivesComponent,
    BillsComponent,
    NewBillComponent,
    EditBillComponent,
    NewServiceItemComponent,
    UdpateServiceItemComponent,
    VatComponent,
    NewVatComponent,
    EditVatComponent,
    EmailComponent,
    DashboardComponent,
    EquipmentCategoriesComponent,
    NewEquipmentCategoryComponent,
    EditEquipmentCategoryComponent,
    EquipmentComponent,
    NewEquipmentComponent,
    EditEquipmentComponent
  ],
  imports: [
    BreadcrumbModule,
    NgxPrintModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NzDatePickerModule,
    NzSwitchModule,
    FormsModule
  ],
  providers: [
    {
      provide: NZ_I18N,
      useFactory: provideNzI18n,
      deps: [LocaleService], // Add LocaleService as a dependency
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl,
      deps: [TranslateService],
    },
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
function provideNzI18n(localeService: LocaleService) {
  const currentLocale = localeService.getCurrentLocale();
  return currentLocale === 'English' ? en_US : el_GR;
}