import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '@services/employees.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '@services/user.service';
import { HResourceDataService } from '@services/resourceData.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from 'src/app/helpers/data-helper';
import { GetEmployee } from '@interfaces/GetEmployee.interface';
import { PostEmployee } from '@interfaces/PostEmployee.interface';
import { BASE_URL } from '@apis/api-endpoints';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css'],
})
export class EditEmployeeComponent implements OnInit {
  employeeForm!: FormGroup;
  employeeId!: number;
  jobTitleMap: { [id: number]: string } = this.hResourceDataService.jobTitles;
  departmentMap: { [id: number]: string } = this.hResourceDataService.departments;
  countriesMap: { [id: number]: string } = this.hResourceDataService.countries;
  regionsMap: { [id: number]: string } = this.hResourceDataService.regions;
  rateTypesMap: { [id: number]: string } = this.hResourceDataService.rateTypes;
  objectKeys(obj: { [key: number]: any }): number[] {
    return Object.keys(obj).map(key => +key);
}

initialFormData: PostEmployee | null = null;


  fileSelected: boolean = false;
  imagePath: string | null = '';
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private hResourceDataService: HResourceDataService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService
  ) {
    this.employeeForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      zipCode: ['', Validators.required],
      hourRateSalary: [''],
      email: ['', [Validators.email,Validators.required]],
      birthDate: [''],
      picturePath: [''],
      jobTitleId: [''],
      departmentId: [''],
      rateTypeId: [''],
      countryId: [''],
      city: ['', Validators.required],
      regionId: [''],
    });
  }

  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.employeeForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.employeeForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.isEditMode = false;
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.employeeId = +id;
        this.employeeService
          .getEmployeeById(this.employeeId)
          .subscribe((response) => {
            console.log(response)
            this.employeeForm.patchValue(response);
            this.initialFormData = { ...response };
            this.cd.detectChanges();
            this.loadEmployeePicture(response);
            this.cd.detectChanges();
            this.breadcrumbService.set('@employeeName', `${response.firstName} ${response.lastName}`);

          });
      }
    });
    console.log(this.regionsMap);
    
   }

   loadEmployeePicture(element: PostEmployee) {
  
      var response:string = BASE_URL.toString();
      if(element.picturePath){
      response+=`/${element.picturePath}`;
      element.picturePath= response;
      this.imagePath = response;
      }
      
    // this.employeeService
    //   .downloadEmployeePicture(element.id)
    //   .subscribe((response) => {
    //     if (response.body) {
    //       let url = window.URL.createObjectURL(response.body);
    //      this.imagePath = url;
    //     } else {
    //       console.error(
    //         'Received null response body while trying to load employee picture.'
    //       );
    //     }
    //   });
  }

   onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        // File is not an image, display a warning message using SweetAlert2
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Selected file is not an image. Please select an image file.',
        });
        return;
      }
        this.employeeForm.get('picturePath')?.setValue(file);

        // For temporary image preview
        const reader = new FileReader();
        reader.onload = () => {
            this.imagePath = reader.result as string;
        };
        reader.readAsDataURL(file);

        // Set fileSelected to true
        this.fileSelected = true;
    }
}

onSubmit() {
  if(this.employeeForm.invalid){
    Object.values(this.employeeForm.controls).forEach(control => {
      control.markAsTouched();
    });
    this.dataHelper.Toast.fire({
      icon: 'warning',
      title: 'Please fill in all the required fields.'
    });
    return;
  }
  if (this.employeeForm.valid) {
    const updatedEmployee = { ...this.employeeForm.getRawValue() };

    let fileToUpload: File | null = null;

    // Check if a new file has been selected.
    if (this.fileSelected && updatedEmployee.picturePath instanceof File) {
        fileToUpload = updatedEmployee.picturePath;
        delete updatedEmployee.picturePath;
    }
    console.log("HERE0")
    console.log(fileToUpload)
    this.dataHelper.withConfirmation(
      'Update Confirmation',
      'Do you confirm the update of this employee? Some changes may affect major functionalities',
      () =>this.employeeService.updateEmployeeById(this.employeeId, updatedEmployee),
      'Employee updated successfully',
      () => {
        console.log("HERE1")
        if (fileToUpload) {
          console.log("HERE2")
          this.employeeService
            .uploadEmployeePicture(this.employeeId, fileToUpload)
            .subscribe({
              next: (uploadResponse) => console.log('Picture uploaded successfully', uploadResponse),
              error: (uploadError) => this.dataHelper.showError('Error occurred while uploading picture'),
              complete: () => this.router.navigate(['/humanResources/employees'])
            });
        }
        this.employeeForm.markAsPristine();
        this.employeeForm.markAsUntouched();
        this.employeeForm.reset();
        this.router.navigate(['/humanResources/employees']);
      }
    );
  }
}


  cancel() {
    if (this.employeeForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.employeeForm.patchValue(this.initialFormData);
    }
    this.employeeForm.markAsPristine();
    this.employeeForm.markAsUntouched();
    this.isEditMode = false;
  }

  exitView(){
    this.router.navigate(['/humanResources/employees']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this employee? Some changes may affect major functionalities',
      () => this.employeeService.deleteEmployee(this.employeeId),
      'Employee deleted successfully',
      () => this.router.navigate(['/humanResources/employees']),
      () => null
    );
  }
}
