import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faArrowLeftRotate, faPen, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';


@Component({
  selector: 'app-breadcrumb-actions',
  templateUrl: './breadcrumb-actions.component.html',
  styleUrls: ['./breadcrumb-actions.component.css']
})
export class BreadcrumbActionsComponent {
  @Input() isEditMode: boolean = false;
  @Input() isDeleteShown: boolean = true;
  @Input() invoiceStatus: string | null | undefined;
  @Input() orderStatus: string | null | undefined;
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() exit = new EventEmitter<void>();
  @Output() markSent = new EventEmitter<void>();
  @Output() sentInvoiceViaEmail = new EventEmitter<void>();
  @Output() markIssued = new EventEmitter<void>();
  @Output() purchaseReceives = new EventEmitter<void>();
  @Output() payment = new EventEmitter<void>();



  constructor(    private breadcrumbService: BreadcrumbService
    ){}

  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  faPrint = faPrint

  onEdit() {
    this.edit.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  exitView() {
    this.exit.emit();
  }
  onSentInvoiceViaEmail(){
    this.sentInvoiceViaEmail.emit();

  }
  onMarkSent(){
    this.markSent.emit();

  }
  recordPayment(){
    this.payment.emit();
  }

  onMarkIssued(){
    this.markIssued.emit();

  }
  recordPurchaseReceives(){
    this.purchaseReceives.emit();
  }
}
