import { Component } from '@angular/core';
import Chart, { ChartOptions } from 'chart.js/auto';
import {
  faAirFreshener,
  faBed,
  faBuilding,
  faDroplet,
  faMaskVentilator,
  faPumpMedical,
  faTelevision,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { DashboardService } from '@services/dashboard.service';
import { EquipmentCategoryStatus } from '@interfaces/EquipmentCategoryStatus.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  faTelevision = faTelevision;
  faMaskVentilator = faMaskVentilator;
  faBed = faBed;
  faDroplet = faDroplet;
  faUserCircle= faUserCircle;

  public currentMonthIndex: number = 0; // Track the current month index
  public staffChart: any;
  public stackedSalesChart: any;
  public employeePerJobTitleData: any | null = null;
  
  equipmentStatuses: EquipmentCategoryStatus[] = [];
  dateRange!: Date[];


  constructor(private dashboardService: DashboardService) {}


  ngOnInit(): void {
    this.getEquipmentStatusByCategory();
    const startDate = new Date('2023-12-01');
    const endDate = new Date(); // Today
    this.dateRange = [startDate, endDate];
    this.onRangeChange(this.dateRange);
  }

  getEquipmentStatusByCategory(): void {
    this.dashboardService.GetEquipmentStatusByCategory().subscribe({
      next: (data) => {
        this.equipmentStatuses = data;
      },
      error: (error) => console.error('There was an error!', error),
    });
  }

  getIconForCategory(categoryName: string) {
    switch (categoryName) {
      case 'Bed': return this.faBed;
      case 'Monitors': return this.faTelevision;
      case 'Ventilators': return this.faMaskVentilator;
      case 'Infusion Pumps': return this.faDroplet;
      default: return this.faUserCircle; // Default icon
    }
  }
  

  onRangeChange(range: Date[]): void {
    if (range && range.length === 2) {
      // const diffInTime = range[1].getTime() - range[0].getTime();
      // const dateRange = Math.round(diffInTime / (1000 * 3600 * 24));
      // console.log(`Date difference: ${dateRange} day(s)`);
      const startDate = range[0].toISOString(); // Keeps the full datetime format
      const endDate = range[1].toISOString(); // Keeps the full datetime format
      this.dashboardService.GetEmployeesCountByJobTitle(startDate,endDate).subscribe({
        next: (data) => {
          this.employeePerJobTitleData = data;
          this.createStaffChart();
          this.createStackedSalesChart();
        },
        error: (error) => console.error(error),
      });
    } else {
      console.log("No range selected or range incomplete");
    }
  }

  createStaffChart() {
    if (this.staffChart) {
      this.staffChart.destroy();
    }
    this.staffChart = new Chart('StaffChart', {
      type: 'bar',
      data: this.employeePerJobTitleData,
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Human resources summary',
          },
        },
        scales: {
          x: {
              ticks: {
                  stepSize: 1, // Set step size to 1 to force integer values
              }
          },
        }
      },
    });
  }

  createStackedSalesChart() {
    if (this.stackedSalesChart) {
      this.stackedSalesChart.destroy();
    }
    const stackedSalesData = {
      labels: ['Dec','Jan', 'Feb', 'Mar'],
      datasets: [
        {
          label: 'Beds',
          data: [20, 15, 10, 15, 25, 12, 8, 5],
          backgroundColor: this.generateLinearGradient(
            'rgba(173, 216, 230, 0)',
            'rgba(173, 216, 230, 1)'
          ),
          stack: 'stack1',
        },
        {
          label: 'Monitors',
          data: [5, 7, 8, 10, 12, 5, 4, 3],
          backgroundColor: this.generateLinearGradient(
            'rgba(135, 206, 250, 0)',
            'rgba(135, 206, 250, 1)'
          ),
          stack: 'stack1',
        },
        {
          label: 'Ventilators',
          data: [2, 3, 1, 2, 4, 2, 1, 1],
          backgroundColor: this.generateLinearGradient(
            'rgba(100, 149, 237, 0)',
            'rgba(100, 149, 237, 1)'
          ),
          stack: 'stack1',
        },
        {
          label: 'Infusion Pumps',
          data: [4, 5, 3, 3, 5, 2, 2, 1],
          backgroundColor: this.generateLinearGradient(
            'rgba(70, 130, 180, 0)',
            'rgba(70, 130, 180, 1)'
          ),
          stack: 'stack1',
        },
        {
          label: 'Infiltration Machine',
          data: [4, 5, 3, 3, 5, 2, 2, 1],
          backgroundColor: this.generateLinearGradient(
            'rgba(70, 130, 180, 0)',
            'rgba(70, 130, 180, 1)'
          ),
          stack: 'stack1',
        },
      ],
    };

    this.stackedSalesChart = new Chart('StackedSalesChart', {
      type: 'bar',
      data: stackedSalesData,
      options: {
        plugins: {
          filler: {
            propagate: true,
          },
          title: {
            display: true,
            text: 'Medical resource summary',
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
      },
    });
  }

  generateLinearGradient(startColor: string, endColor: string): CanvasGradient {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) {
      throw new Error('Canvas not supported');
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1, endColor);

    return gradient;
  }

  disabledDate = (current: Date): boolean => {
    // You can implement logic to disable specific dates here
    return current && current.getTime() > Date.now();
  };

}
