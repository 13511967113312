import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from '@services/departments.service'; // Adjust the import as per your project structure
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-department',
  templateUrl: './new-department.component.html',
  styleUrls: ['./new-department.component.css'],
})
export class NewDepartmentComponent implements OnInit {
  departmentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private departmentsService: DepartmentsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private dialog: MatDialog

  ) {
    this.departmentForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }
  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.departmentForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.departmentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }


  ngOnInit(): void {
  }

  onSubmit() {
    if(!this.departmentForm.valid){
      Object.values(this.departmentForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.departmentForm.getRawValue();
    const dataToSend = {
      name: formValue.name || 'string',
      description: formValue.description || 'string',
    };
  
    this.dataHelper.addNewRecord(
      this.departmentForm,
      dataToSend,
      (data) => this.departmentsService.createDepartment(data),
      'Department created successfully',
      '/masters/departments'
    );
  }
  

  cancel() {
    this.router.navigate(['/masters/departments']);
  }

}
