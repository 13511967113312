import { Component, OnInit } from '@angular/core';
import { ServicesService } from '@services/services.service';
import { Service } from '@interfaces/Service.interface'; // Updated interface
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../../helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-service', // Update the selector if necessary
  templateUrl: './services.component.html', // Update the template URL if necessary
  styleUrls: ['./services.component.css'], // Update the styles if necessary
})
export class ServicesComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'description', header: this.translate.instant('Description') },
    { prop: 'charge', header: this.translate.instant('Charge') },
    { prop: 'tax', header: this.translate.instant('Tax') },
];

  services: Service[] = [];
  filteredServices: Service[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private serviceService: ServicesService, // Update the service name to match your application
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  ngOnInit(): void {
    this.fetchServices();
  }

  fetchServices(): void {
    this.dataHelper.fetchItems(
      this.serviceService.getAll(),
      (data: Service[]) => {
        this.services = data;
        this.filteredServices = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredServices = this.services.filter((service) =>
      service.name.toLowerCase().includes(filterValue) ||
      service.description.toLowerCase().includes(filterValue)
    );
  }

  deleteService(element: Service): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this service? Some changes may affect major functionalities',
      () => this.serviceService.deleteService(element.id),
      'Service deleted successfully',
      () => this.fetchServices(),
      () => null
    );
  }

  addService() {
    this.router.navigate(['/masters/services/new_service']);
  }

  editService(element: Service): void {
    this.router.navigate(['/masters/services/edit_service', element.id]);
  }
}
