// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#city,#region,#country{
    width: 200px;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/purchases/supplier/view-supplier/view-supplier.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd","sourcesContent":["#city,#region,#country{\r\n    width: 200px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
