import { Component, OnInit } from '@angular/core';
import { JobTitleService } from '@services/JobTitle.service'; // Adjust the import as per your project structure
import { JobTitle } from '@interfaces/JobTitle.interface'; // Adjust the import as per your project structure
import { UserService } from '@services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Column } from '@interfaces/Column.interface';

@Component({
  selector: 'app-jobTitles',
  templateUrl: './jobTitles.component.html',
  styleUrls: ['./jobTitles.component.css'],
})
export class JobTitlesComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
  ];
  jobTitles: JobTitle[] = [];
  filteredjobTitles: JobTitle[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private jobTitleService: JobTitleService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)


  ngOnInit(): void {
    this.fetchjobTitles();
  }

  fetchjobTitles(): void {
    this.dataHelper.fetchItems(
      this.jobTitleService.getjobTitle(null),
      (data: JobTitle[]) => {
        this.jobTitles = data;
        this.filteredjobTitles = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const filterValue = target.value.trim().toLowerCase(); // Directly access value property

    this.filteredjobTitles = this.jobTitles.filter((jobTitle) =>
      jobTitle.name.toLowerCase().includes(filterValue)
    );
  }

  deletejobTitle(element: JobTitle): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this Job Title? Some changes may affect major functionalities',
      () => this.jobTitleService.deletejobTitle(element.id),
      'Job Title deleted successfully',
      () => this.fetchjobTitles(), // This should be the successCallback
      () => null // This should be the errorCallback (or handle error appropriately)
    );
  }

  addjobTitle() {
    this.router.navigate(['/masters/jobTitles/new_jobTitle']); // Navigate to the new jobTitle route
  }

  editjobTitle(element: JobTitle): void {
    console.log(element.id);
    this.router.navigate(['/masters/jobTitles/edit_jobTitle', element.id]);
  }
}
