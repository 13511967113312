import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EmployeeService } from '@services/employees.service';
import { GetEmployee } from '@interfaces/GetEmployee.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { HResourceDataService } from '@services/resourceData.service';
import { EmployeesResponse } from '@interfaces/EmployeeResponse.interface';
import { PaginationData } from 'src/app/helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { BASE_URL } from '@apis/api-endpoints';
@Component({
  selector: 'app-employee',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css'],
})
export class EmployeesComponent implements OnInit {
  employees: GetEmployee[] = [];
  filteredEmployees = [...this.employees];
  error: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  displayedColumns: Column[] = [
    {
      prop: 'profilePicture',
      header: this.translate.instant('Profile'),
    },
    { prop: 'firstName', header: this.translate.instant('Name') },
    { prop: 'lastName', header: this.translate.instant('Surname') },
    {
      prop: 'department',
      header: this.translate.instant('Department'),
    },
    {
      prop: 'jobTitle',
      header: this.translate.instant('Job Title'),
    },
    { prop: 'phone', header: this.translate.instant('Telephone') },
    { prop: 'email', header: this.translate.instant('Email') },
   
 
    
    // { prop: 'address', header: this.translate.instant('Address') },
    // { prop: 'zipCode', header: this.translate.instant('Zip Code') },
    // {
    //   prop: 'hourRateSalary',
    //   header: this.translate.instant('Hourly Rate Salary'),
    // },
    // { prop: 'birthDate', header: this.translate.instant('Birth Date') },
    // { prop: 'rateType', header: this.translate.instant('Rate Type') },
    // { prop: 'country', header: this.translate.instant('Country') },
    // { prop: 'city', header: this.translate.instant('City') },
    // { prop: 'region', header: this.translate.instant('Region') },
  ];

  constructor(
    private translate: TranslateService,
    private employeeService: EmployeeService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private hResourceDataService: HResourceDataService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchEmployees();
  }

  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalEmployees: number = 0;

  fetchEmployees(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.employeeService.getEmployees(page, size),
      (response: EmployeesResponse) => {
        this.employees = response.items;
        this.totalEmployees = response.totalItems;
        this.employees.forEach((employee) => {
          this.loadEmployeePicture(employee);
        });
        this.filteredEmployees = [...this.employees];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; // MatPaginator is 0-based, but typically APIs are 1-based.
    this.pageSize = event.pageSize;
    this.fetchEmployees(this.currentPage, this.pageSize);
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
    const filterValue = target.value.trim().toLowerCase(); // Directly access value property

    this.filteredEmployees = this.employees.filter(
      (employee) =>
        employee.firstName.toLowerCase().includes(filterValue) ||
        employee.lastName.toLowerCase().includes(filterValue) ||
        employee.email.toLowerCase().includes(filterValue) ||
        employee.phone.toLowerCase().includes(filterValue)
    );
  }
  loadEmployeePicture(element: GetEmployee) {
    var response:string = BASE_URL.toString();
    if(element.picturePath){
    response+=`/${element.picturePath}`;
    element.picturePath= response
    }
    // this.employeeService
    //   .downloadEmployeePicture(element.id)
    //   .subscribe((response) => {
    //     console.log(response)
    //     if (response.body) {
       
    //       let url = window.URL.createObjectURL(response.body);
    //       this.cd.markForCheck();

    //       console.log(url);

    //       element.picturePath = url;
    //     } else {
    //       console.error(
    //         'Received null response body while trying to load employee picture.'
    //       );
    //     }
    //   });
  }

  deleteEmployee(element: GetEmployee): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this employee? Some changes may affect major functionalities',
      () => this.employeeService.deleteEmployee(element.id),
      'Employee deleted successfully',
      () => this.fetchEmployees(),
      () => null
    );
  }

  addEmployee() {
    this.router.navigate(['/humanResources/employees/new_employee']); // Navigate to the new employee route
  }

  editEmployee(element: GetEmployee): void {
    this.router.navigate(['/humanResources/employees/edit_employee', element.id]); // Adjust the route as per your routing configuration
  }
}
