import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ServiceInvoiceDataService } from '@services/serviceInvoiceData.service';
import { InvoiceService } from '@services/invoices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DataHelper } from '../../../../helpers/data-helper';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvoiceLines } from '@interfaces/InvoiceLines.interface';
import { TenantSettingsService } from '@services/tenantSettings.service';
import { Observable, Subscription, pairwise, startWith, tap } from 'rxjs';
import { AdminDataService } from '@services/adminData.service';
import { TenantSettings } from '@interfaces/TenantSettings.interface';
import { Invoice } from '@interfaces/Invoice.interface';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.css'],
})
export class EditInvoiceComponent implements OnInit {
  today = new Date();
  defaultVat: number = 0;
  previousDiscount: number = 0;
  invoiceId:number = 0;
  private formChangesSubscription!: Subscription;
  initialFormData: Invoice | null = null;


  invoiceForm: FormGroup;
  invoiceLinesDataSource: MatTableDataSource<InvoiceLines>;

  displayedColumns: string[] = [
    'serviceId',
    'serviceName',
    'quantity',
    'serviceCharge',
    'discount',
    'options',
  ];
  
  customerMap: { [id: number]: any } = {};
  employeeMap: { [id: number]: string } = {};
  paymentMap: { [id: number]: string } = {};
  serviceMap: { [id: number]: any } = {};
  vatsMap: { [id: number]: { name: string; value: number } } = {}; 
  objectKeys(obj: any): number[] {
    return Object.keys(obj).map(Number);
  }


  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.invoiceForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.invoiceForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private serviceInvoiceDataService: ServiceInvoiceDataService,
    private invoiceService: InvoiceService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private tenantSettingsService: TenantSettingsService,
    private adminDataService: AdminDataService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.invoiceLinesDataSource = new MatTableDataSource<InvoiceLines>([]);
    this.invoiceForm = this.fb.group({
      invoiceNumber: ['', Validators.required],
      customerId: ['', Validators.required],
      employeeId: ['', Validators.required],
      paymentTypeId: ['', Validators.required],
      date: ['', Validators.required],
      invoiceLines: this.fb.array([]),
      discount: [''],
      due: [''],
      dueDate: ['', Validators.required],
      totalDiscount: [''],
      grandTotal: [''],
      change: [''],
      vat: [''],
      netTotal: [''],
      totalTax: [''],
      paidAmount: [''],
      details: ['Thanks for your business.'],
      subject: [''],
      termsConditions: [''],
    });
  }

  ngOnInit(): void {
      this.loadDataMaps();
      this.route.paramMap.subscribe((params) => {
        const id = params.get('id');
        if (id !== null) {
          this.invoiceId = +id;
          this.invoiceService.getByIdWithInvoiceLines(this.invoiceId)
            .subscribe((response) => {
              this.invoiceForm.patchValue(response);
              this.initialFormData = { ...response };
              if (response.invoiceLines) {
                response.invoiceLines.forEach((line: any) => {
                  let invoiceLineFormGroup = this.fb.group({
                    serviceId: [line.serviceId, Validators.required],
                    quantity: [line.quantity, [Validators.required, Validators.min(1)]],
                    serviceCharge: [line.serviceCharge, [Validators.min(0)]],
                    discountType: [line.discountType, Validators.required], 
                    discount: line.discountType==='€'? [line.discount, [Validators.min(0), Validators.max(line.serviceCharge)]]  : [line.discount, [Validators.min(0), Validators.max(100)]],
                    vatId: [line.vatId, Validators.min(0)],
                    total: [{value: line.total, disabled: true}],
                    description: [line.description],
                  });
                  this.setupDiscountTypeListener(invoiceLineFormGroup);
                  this.invoiceLines.push(invoiceLineFormGroup);
                });
                this.setupFormChanges();
                this.updateDataSource();
                this.tenantSettingsService
                .getTenantSettings()
                .pipe(
                  tap((response: TenantSettings) => {
                    this.defaultVat = response.vatId;
                    this.invoiceLines.push(this.createInvoiceLine());
                    this.setupFormChanges();
                  })
                )
                .subscribe();
              }
            });
        }
      });
  }
  
  get invoiceLines() {
    return this.invoiceForm.get('invoiceLines') as FormArray;
  }

  loadDataMaps(): void {
    this.customerMap = this.serviceInvoiceDataService.customers;
    this.employeeMap = this.serviceInvoiceDataService.employees;
    this.paymentMap = this.serviceInvoiceDataService.paymentTypes;
    this.serviceMap = this.serviceInvoiceDataService.services;
    this.vatsMap = this.adminDataService.vats;
  }


  createInvoiceLine(): FormGroup {
    const invoiceLineFormGroup = this.fb.group({
      serviceId: [0, Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      serviceCharge: [0, [Validators.min(0)]],
      discountType: ['%', Validators.required], // Default to percentage
      discount: [0, [Validators.min(0), Validators.max(100)]], // Appropriate for percentage
      vatId: [this.defaultVat, Validators.min(0)],
      total: [{value: 0, disabled: true}],
      description: [''],
    });
    this.setupDiscountTypeListener(invoiceLineFormGroup); 
    return invoiceLineFormGroup;
  }

  getServiceNameById(id: string): string {
    return this.serviceMap[+id]?.name || '';
  }

  addInvoiceLine(): void {
    this.invoiceLines.push(this.createInvoiceLine());
  }

  onServiceSelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const serviceId = selectElement.value;
    const selectedService = this.serviceMap[+serviceId];

    if (selectedService) {
      this.invoiceLines.at(index).patchValue({
        serviceId: serviceId,
        quantity: 1, // Default
        discount: 0, // Default
        discountType: '%', //Default
        serviceCharge: Number(selectedService.charge),
        vatId: this.defaultVat || 0,
        // Automatically calculate total in calculateAndSetAmount
      });

      // Enable other controls
      this.invoiceLines.at(index).get('quantity')?.enable();
      this.invoiceLines.at(index).get('discount')?.enable();
      this.invoiceLines.at(index).get('discountType')?.enable();
      this.invoiceLines.at(index).get('serviceCharge')?.enable();
      this.invoiceLines.at(index).get('vatId')?.enable();
      this.invoiceLines.at(index).get('description')?.enable();
      this.invoiceLines.at(index).get('total')?.enable();

      if (index === this.invoiceLines.length - 1) {
        this.addInvoiceLine();
      }

      this.calculateAndSetAmount(index);
    }
  }

  onVatChange(event: any, index: number): void {
    this.invoiceLines.at(index).get('vatId')?.setValue(event.target.value);
    this.calculateAndSetAmount(index);
    this.cdRef.detectChanges();
  }

// Method to calculate and update the amount for a specific invoice line
calculateAndSetAmount(index: number): void {
  const invoiceLine = this.invoiceLines.at(index);
  const quantity = invoiceLine.get('quantity')?.value;
  const serviceCharge = invoiceLine.get('serviceCharge')?.value;
  const discount = invoiceLine.get('discount')?.value;
  const discountType = invoiceLine.get('discountType')?.value;

  let total = serviceCharge * quantity;
  
  if (discountType === '%') {
    total -= total * (discount / 100);
  } else {
    total -= discount; // Assuming discount is a fixed amount here
  }

  invoiceLine.patchValue({
    total: Number(total.toFixed(2)),
  });
  this.calculateTotals();
}

  setupFormChanges(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe(); // Ensure no memory leaks
    }

    this.formChangesSubscription = this.invoiceLines.valueChanges
      .pipe(startWith([]), pairwise())
      .subscribe(([prevLines, currentLines]) => {
        currentLines.forEach(
          (
            line: InvoiceLines,
            index: number
          ) => {
            if (
              !prevLines[index] ||
              line.quantity !== prevLines[index].quantity ||
              line.discount !== prevLines[index].discount ||
              line.discountType !== prevLines[index].discountType ||
              line.serviceCharge !== prevLines[index].serviceCharge ||
              line.vatId !== prevLines[index].vatId
            ) {
              this.calculateAndSetAmount(index);
            }
          }
        );
      });
  }

  setupDiscountTypeListener(invoiceLineFormGroup: FormGroup): void {
    invoiceLineFormGroup.get('discountType')?.valueChanges.subscribe((type) => {
      const discountControl = invoiceLineFormGroup.get('discount');
      const maxValueAllowed = invoiceLineFormGroup.get('total')?.value;
      // Preserve the current discount value
      const currentValue = discountControl?.value;
  
      // Apply both min and conditionally max validators together
      let validators = [Validators.min(0)]; // Minimum discount is always 0%
      if (type === '€' && maxValueAllowed) {
        validators.push(Validators.max(maxValueAllowed));
      } else if (type === '%') {
        validators.push(Validators.max(100)); // For percentage, the max can be 100%
      }
  
      discountControl?.setValidators(Validators.compose(validators));
      discountControl?.updateValueAndValidity();
  
      // Force re-validation against the new rules but keep the user's input
      discountControl?.setValue(currentValue);
  
      // Recalculate the Item total
      const index = this.invoiceLines.controls.indexOf(invoiceLineFormGroup);
      if (index !== -1) {
        this.calculateAndSetAmount(index);
      }
    });
  }

  removeInvoiceLine(index: number): void {
    // Only remove if more than one invoice line exists
    if (this.invoiceLines.length > 1) {
      this.invoiceLines.removeAt(index);
       this.calculateTotals();
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.invoiceLinesDataSource.data = this.invoiceLines.controls.map(
      (control) => control.value
    );
  }

  calculateTotals(): void {
    let netTotal = 0;
    const vats = new Map<number, number>(); // VAT rate to total amount map
    let grandTotal = 0;

    this.invoiceLines.controls.forEach((control) => {
      const invoiceLine = control.getRawValue();
      const invoiceLineNetAmount = parseFloat(invoiceLine.total) || 0;
      netTotal += invoiceLineNetAmount;

      const vatId = +invoiceLine.vatId || 0;
      if (this.vatsMap[vatId]) {
        const vatRate = this.vatsMap[vatId].value;
        if (vatRate > 0.0) {
          const vatAmount = invoiceLineNetAmount * (vatRate / 100);
          // Check if the vatRate is already present and sum the amounts
          if (vats.has(vatRate)) {
            const existingAmount = vats.get(vatRate);
            vats.set(vatRate, (existingAmount || 0) + vatAmount);
          } else {
            vats.set(vatRate, vatAmount);
          }
        }
      }
    });

    let totalVatAmount = 0;
    vats.forEach((amount) => (totalVatAmount += amount));

    grandTotal = netTotal + totalVatAmount;

    this.invoiceForm.patchValue({
      grandTotal: grandTotal.toFixed(2),
      netTotal: netTotal.toFixed(2),
      totalTax: Array.from(vats.entries()),
    });
  }

  onSubmit() {
    if(this.invoiceForm.invalid || this.invoiceLines.length === 1){
      Object.values(this.invoiceForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.invoiceForm.getRawValue();
    // the first row of invoice lines is always the template row which we dont want to be put in the database
    if (this.invoiceLines.length === 1) {
      formValue.invoiceLines = [];
    } else {
      // Exclude the last row from submission if length is bigger than 1 because the last is always the template row in this case
      formValue.invoiceLines = formValue.invoiceLines.slice(0, -1);
    }
    if(formValue.totalTax){
      let totalTax:number = 0;
      for (let taxEntry of formValue.totalTax) {
            totalTax+=taxEntry[1];
      }
      formValue.totalTax = null;
      formValue.totalTax = totalTax;
    }
    // Adjust invoice lines to include calculated VAT amount instead of vatId
    formValue.invoiceLines.forEach((line:any) => {
      const vatRate = this.vatsMap[line.vatId]?.value || 0;
      const vatAmount = line.total * (vatRate / 100);
      line.vat = vatAmount; // Assign calculated VAT amount to line
      // delete line.vatId; // Remove vatId as it's no longer needed in the final submission
    });
   const invoiceData: Invoice = {
      id: this.invoiceId,
      invoiceNumber: formValue.invoiceNumber || 'No Invoice Number',
      details: formValue.details || 'Thanks for your business.',
      dateTime: new Date(formValue.date).toISOString() || '',
      due: formValue.grandTotal || 0,
      dueDate : new Date(formValue.dueDate).toISOString()|| '',
      discount: formValue.discount || 0,
      totalDiscount: formValue.totalDiscount || 0,
      grandTotal: formValue.grandTotal || 0,
      change: formValue.change || 0,
      netTotal: formValue.netTotal || 0,
      vat: formValue.vat || 0,
      totalTax: formValue.totalTax || 0,
      noTax: true,
      paidAmount: formValue.paidAmount || 0,
      customerId: formValue.customerId || 0,
      employeeId: formValue.employeeId || 0,
      paymentTypeId: formValue.paymentTypeId || 0,
      subject: formValue.subject || null,
      status: null,
      termsConditions: formValue.termsConditions || null,
      invoiceLines: formValue.invoiceLines || [],
    };

    console.log(invoiceData);


    this.dataHelper.withConfirmation(
      'Update Confirmation',
      'Do you confirm the update of this invoice? Some changes may affect major functionalities',
      () => this.invoiceService.updateInvoiceById(this.invoiceId,invoiceData),
      'Invoice updated successfully',
      () => {
        this.invoiceForm.markAsPristine();
        this.invoiceForm.markAsUntouched();
        this.invoiceForm.reset();
        this.router.navigate(['/sales/invoices/preview_invoice',this.invoiceId]);
      }
    );
  }


  onCancel() {
    if (this.invoiceForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.invoiceForm.patchValue(this.initialFormData);
    }
    this.invoiceForm.markAsPristine();
    this.invoiceForm.markAsUntouched();
    this.router.navigate(['/sales/invoices']);
  }



  ngOnDestroy(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
