import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { backend } from '@apis/api-endpoints';
import { HResourceData } from '@interfaces/HResourceData.interface';

@Injectable({
  providedIn: 'root',
})
export class HResourceDataService {
  private jobTitleMap: { [id: number]: string } = {};
  private departmentMap: { [id: number]: string } = {};
  private countriesMap: { [id: number]: string } = {};
  private regionsMap: { [id: number]: string } = {};
  private rateTypesMap: { [id: number]: string } = {};
  // BehaviorSubject to track if data has been loaded
  private dataLoaded$ = new BehaviorSubject<boolean>(false);
  
  constructor(private http: HttpClient) {
    this.loadMaps();
  }

  private loadMaps(): void {
    if (!this.dataLoaded$.getValue()) {
      this.getHResourceData().subscribe(data => {
        this.createMap(data.jobTitles, this.jobTitleMap);
        this.createMap(data.departments, this.departmentMap);
        this.createMap(data.countries, this.countriesMap);
        this.createMap(data.regions, this.regionsMap);
        this.createMap(data.rateTypes, this.rateTypesMap);
        
        this.dataLoaded$.next(true); // Indicate that data has been loaded
      });
    }
  }

  private createMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach(item => {
      mapRef[item.id] = item.name;
    });
  }

  get jobTitles(): { [id: number]: string } {
    return this.jobTitleMap;
  }

  get departments(): { [id: number]: string } {
    return this.departmentMap;
  }


  get countries(): { [id: number]: string } {
    return this.countriesMap;
  }

  get regions(): { [id: number]: string } {
    return this.regionsMap;
  }

  get rateTypes(): { [id: number]: string } {
    return this.rateTypesMap;
  }

  getHResourceData(): Observable<HResourceData> {
    return this.http.get<HResourceData>(backend.hResourceData.get).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
