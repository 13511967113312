import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { ServiceInvoiceData } from '@interfaces/ServiceInvoiceData.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceInvoiceDataService {
    private customerMap: { [id: number]: any } = {};
    private employeeMap: { [id: number]: string } = {};
    private categoriesMap: { [id: number]: string } = {};
    private serviceMap: { [id: number]: any } = {};
    private paymentTypesMap: { [id: number]: string } = {};
  // BehaviorSubject to track if data has been loaded
  private dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.loadMaps();
  }

  private loadMaps(): void {
    if (!this.dataLoaded$.getValue()) {
      this.getServiceInvoiceData().subscribe(data => {
        this.createServiceMap(data.service, this.serviceMap);
        this.createPaymentTypesMap(data.paymentType, this.paymentTypesMap);
        this.createCustomerMap(data.customer, this.customerMap);
        this.createEmployeeMap(data.employees, this.employeeMap);
        this.createCategoriesMap(data.categories,this.categoriesMap)
        this.dataLoaded$.next(true); // Indicate that data has been loaded
      });
    }
  }

  private createCategoriesMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach(item => {
      mapRef[item.id] = item.name;
    });
  }

  private createServiceMap(data: any[], mapRef: { [id: number]: any }): void {
    data.forEach(item => {
      mapRef[item.id] = {
        name: item.name,
        description: item.description,
        charge: item.charge,
        tax: item.tax,
      };
    });
  }
  
  private createPaymentTypesMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach(item => {
      mapRef[item.id] = item.name;
    });
  }

  private createCustomerMap(data: any[], mapRef: { [id: number]: { fullname: string, email: string } }): void {
    data.forEach(item => {
      console.log("Customer:" , item)
      mapRef[item.id] = {
        fullname: `${item.firstName} ${item.lastName}`,
        email: item.email
      };
    });
  }
  

  private createEmployeeMap(data: any[], mapRef: { [id: number]: string }): void {
    data.forEach(item => {
      mapRef[item.id] = `${item.firstName} ${item.lastName}`; 
    });
  }

  get services(): { [id: number]: any } {
    return this.serviceMap;
  }

  get paymentTypes(): { [id: number]: string } {
    return this.paymentTypesMap;
  }

  get customers(): { [id: number]: string } {
    return this.customerMap;
  }

  get employees(): { [id: number]: string } {
    return this.employeeMap;
  }

  get categories(): { [id: number]: any } {
    return this.categoriesMap;
  }

  getServiceInvoiceData(): Observable<ServiceInvoiceData> {
    return this.http.get<ServiceInvoiceData>(backend.serviceInvoiceData.get).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
