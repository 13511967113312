import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Column } from '@interfaces/Column.interface';
import { UserManagementService } from '@services/userManagement.service';
import { UserManagement } from '@interfaces/UserManagement.interface';
import { UserManagementResponse } from '@interfaces/UserManagementResponse.interface';
import { PaginationData } from '../../../helpers/pagination-data';
import { DataHelper } from 'src/app/helpers/data-helper';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  displayedColumns: Column[] = [
    { prop: 'username', header: this.translate.instant('Username') },
    { prop: 'firstName', header: this.translate.instant('First Name') },
    { prop: 'lastName', header: this.translate.instant('Last Name') },
    { prop: 'email', header: this.translate.instant('Email') },
    { prop: 'phoneNumber', header: this.translate.instant('Phone Number') },
    { prop: 'locale', header: this.translate.instant('Language') },
    { prop: 'roles', header: this.translate.instant('Role') },
  ];
  users: UserManagement[] = [];
  filteredUsers: UserManagement[] = [];
  error: string | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private userManagementService: UserManagementService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}
  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );
  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;
  totalUsers: number = 0;
  ngOnInit(): void {
    this.fetchUsers();
  }

  fetchUsers(
    page: number = this.currentPage,
    size: number = this.pageSize
  ): void {
    this.dataHelper.fetchItems(
      this.userManagementService.getUsers(page, size),
      (response: UserManagementResponse) => {
        this.users = response.items;
        this.totalUsers = response.totalItems;
        this.filteredUsers = [...this.users];
      }
    );
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchUsers(this.currentPage, this.pageSize);
  }
  

  addUser() {
    this.router.navigate(['/settings/userManagement/new_user']); // Navigate to the new user route
  }
  editUser(element: UserManagement): void {
    this.router.navigate(['/settings/userManagement/edit_user', element.id]);
  }
}
