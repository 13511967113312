import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '@services/employees.service';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/helpers/error-dialog.component';
import { Router } from '@angular/router';
import { HResourceDataService } from '@services/resourceData.service';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.css'],
  providers: [DatePipe],
})
export class NewEmployeeComponent implements OnInit {
  

  
  employeeForm!: FormGroup;
  jobTitleMap: { [id: number]: string } = this.hResourceService.jobTitles;
  departmentMap: { [id: number]: string } = this.hResourceService.departments;
  countriesMap: { [id: number]: string } = this.hResourceService.countries;
  regionsMap: { [id: number]: string } = this.hResourceService.regions;
  rateTypesMap: { [id: number]: string } = this.hResourceService.rateTypes;

  fileName: string = '';
  imagePath: string | null = '';

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private hResourceService: HResourceDataService

  ) {
    this.employeeForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      zipCode: ['', Validators.required],
      hourRateSalary: [''],
      email: ['', [Validators.email,Validators.required]],
      birthDate: [''],
      picturePath: [''],
      jobTitleId: [''],
      departmentId: [''],
      rateTypeId: [''],
      countryId: [''],
      city: ['', Validators.required],
      regionId: [''],
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.employeeForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.employeeForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }


  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  ngOnInit(): void {
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        // File is not an image, display a warning message using SweetAlert2
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Selected file is not an image. Please select an image file.',
        });
        return;
      }
      this.fileName = file.name;
      this.employeeForm.get('picturePath')?.setValue(file);
     // For temporary image preview
     const reader = new FileReader();
     reader.onload = () => {
         this.imagePath = reader.result as string;
     };
     reader.readAsDataURL(file);

    }
  }

  onSubmit() {
    if(this.employeeForm.invalid){
      Object.values(this.employeeForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.employeeForm.valid) {
      Object.keys(this.employeeForm.controls).forEach((key) => {
        const control = this.employeeForm.controls[key];
        if (!control.valid) {
          console.log('Invalid Control:', key, control.errors);
        }
      });
      const formValue = this.employeeForm.getRawValue();

      const dataToSend = {
        firstName: formValue.firstName || null,
        lastName: formValue.lastName || null,
        phone: formValue.phone || null,
        address: formValue.address || null,
        zipCode: formValue.zipCode || null,
        hourRateSalary: formValue.hourRateSalary || 0,
        email: formValue.email || null,
        birthDate:
          this.datePipe.transform(formValue.birthDate, 'yyyy-MM-dd') ||
          '2023-09-26',
        picturePath: '',
        jobTitleId: Number(formValue.jobTitleId) || null,
        departmentId: Number(formValue.departmentId) || null,
        rateTypeId: Number(formValue.rateTypeId) || null,
        countryId: Number(formValue.countryId) || null,
        city: String(formValue.city) || null,
        regionId: Number(formValue.regionId) || null,
      };
      console.log(dataToSend);
      this.employeeService.registerEmployee(dataToSend).subscribe({
        next: (response) => {
          console.log(response);

          this.snackBar.open(
            this.translate.instant('Employee registered successfully'),
            this.translate.instant('Close'),
            {
              duration: 3000,
            }
          );

          // Mark all controls as pristine and untouched to remove the red highlight
          Object.keys(this.employeeForm.controls).forEach((key) => {
            const control = this.employeeForm.get(key);
            if (control) {
              control.markAsPristine();
              control.markAsUntouched();
            }
          });
          const employeeId = Number(response);
          console.log('Employee id' + employeeId);
          const fileToUpload: File =
            this.employeeForm.get('picturePath')?.value;
          console.log('File to upload: ', fileToUpload); // Check the value here.

          console.log(fileToUpload);
          if (employeeId && fileToUpload) {
            this.employeeService
              .uploadEmployeePicture(employeeId, fileToUpload)
              .subscribe({
                next: (uploadResponse) => {
                  console.log('Picture uploaded successfully', uploadResponse);
                },
               error: (uploadError) => {
                  console.error(
                    'Error occurred while uploading picture',
                    uploadError
                  );
                }
              });
          }
          this.employeeForm.reset(); // Clear the form on successful registration
          this.router.navigate(['/humanResources/employees']);
        },

        error: (error) => {
          this.snackBar
            .open(
              this.translate.instant(error),
              this.translate.instant('Details'),
              {
                duration: 3000,
              }
            )
            .onAction()
            .subscribe(() => {
              this.dialog.open(ErrorDialogComponent, {
                data: { error: error.error || error },
              });
            });

          // Highlight the field that caused the error
          if (error.error && error.error.field) {
            this.employeeForm
              .get(error.error.field)
              ?.setErrors({ serverError: true });
          }
        }
    });
    }
  }
  cancel() {
    this.router.navigate(['/humanResources/employees']);
  }
}
