import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@services/product.service'; // Replace with your product service
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../helpers/data-helper'; // Replace with your helper
import { InventoryDataService } from '@services/inventoryData.service';
import { HResourceDataService } from '@services/resourceData.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable, catchError, forkJoin, of, switchMap, tap, throwError } from 'rxjs';
import { PostProduct } from '@interfaces/PostProduct.interface';
import { GetProduct } from '@interfaces/GetProduct.interface';
import { MatTableDataSource } from '@angular/material/table';
import { BaseEquipment, Equipment } from '@interfaces/Equipment.interface';
import { EquipmentService } from '@services/equipment.service';
import { EquipmentDataService } from '@services/equipmentData.service';

@Component({
  selector: 'app-edit-equipment',
  templateUrl: './edit-equipment.component.html',
  styleUrls: ['./edit-equipment.component.css'],
})
export class EditEquipmentComponent implements OnInit {
  equipmentForm!: FormGroup;
  equipmentCategoryMap: { [id: number]: string } =
    this.equipmentDropdownData.equipmentCategories;
  equipmentCategoryName: string | null = null;
  additionalEquipmentDataSource: MatTableDataSource<BaseEquipment>;
  filteredEquipmentMap: { [lineIndex: number]: any[] } = {};
  isEditMode: boolean = false;
  originalAdditionalEquipment!: any[];
  equipmentId!: number;
  initialFormData: Equipment | null = null;
  equipmentName: string = '';
  itemsMarkedForRemoval: any[] = [];
  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private hResourceDataService: HResourceDataService,
    private equipmentDropdownData: EquipmentDataService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService
  ) {
    this.additionalEquipmentDataSource = new MatTableDataSource<BaseEquipment>(
      []
    );
    this.equipmentForm = this.fb.group({
      serialNumber: [''],
      name: ['', Validators.required],
      status: [''],
      chargePerDay: [''],
      equipmentCategoryId: ['', Validators.required],
      ehrId: [''],
      additionalEquipment: this.fb.array([]),
    });
  }

  get additionalEquipment() {
    return this.equipmentForm.get('additionalEquipment') as FormArray;
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage(
      $event,
      this.equipmentForm.dirty
    );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.equipmentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.equipmentCategoryName = params.get('equipmentCategoryName');
      this.equipmentId = Number(params.get('id'));
      if (!isNaN(this.equipmentId)) {
        this.equipmentService
          .getEquipmentById(this.equipmentId)
          .subscribe((equipment: Equipment) => {
            console.log(equipment);
            this.initialFormData = { ...equipment };
            this.equipmentName = equipment.name;
            this.equipmentForm.patchValue({
              serialNumber: equipment.serialNumber,
              name: equipment.name,
              status: equipment.status,
              chargePerDay: equipment.chargePerDay,
              equipmentCategoryId: equipment.equipmentCategoryId,
              ehrId: equipment.ehrId,
            });
            if (equipment.additionalEquipment) {
              this.additionalEquipment.clear();
              equipment.additionalEquipment.forEach(
                (item: any, index: number) => {
                  let itemsFormGroup = this.fb.group({
                    equipmentCategoryId: item.equipmentCategoryId,
                    id: item.id,
                    name: item.name,
                    status: item.status,
                    parentEquipmentId: item.parentEquipmentId,
                  });
                  this.loadEquipment(item.equipmentCategoryId, index);
                  this.additionalEquipment.push(itemsFormGroup);
                }
              );
            }

            this.breadcrumbService.set('@equipmentName', this.equipmentName);
            if (this.isEditMode) {
              this.additionalEquipment.push(this.createItem());
            }
          });
      }
    });
    const categoryId = Object.keys(this.equipmentCategoryMap).find(
      (key) =>
        this.equipmentCategoryMap[+key].toLowerCase() ===
        this.equipmentCategoryName?.toLowerCase()
    );
    const categoryIdNumber = categoryId ? Number(categoryId) : null;

    if (categoryIdNumber) {
      this.equipmentForm.get('equipmentCategoryId')?.setValue(categoryIdNumber);
    }

    this.additionalEquipment.push(this.createItem());
  }

  createItem(): FormGroup {
    const additionalEquipmentItemFormGroup = this.fb.group({
      equipmentCategoryId: ['0'],
      status: [''],
      name: [''],
      id: [{ value: '0', disabled: true }, Validators.required],
    });
    return additionalEquipmentItemFormGroup;
  }

  addServiceLine(): void {
    this.additionalEquipment.push(this.createItem());
  }

  loadEquipment(selectedCategoryId: number, lineIndex: number) {
    this.dataHelper.fetchItems(
      this.equipmentService.getEquipmentByCategory(selectedCategoryId),
      (data: any) => {
        if (data && data.equipment) {
          this.filteredEquipmentMap[lineIndex] = data.equipment;
          this.cdr.detectChanges();
        }
      }
    );
  }

  onCategorySelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const categoryId = selectElement.value;
    const selectedCategory = this.equipmentCategoryMap[+categoryId];

    if (selectedCategory) {
      this.additionalEquipment.at(index).patchValue({
        equipmentCategoryId: categoryId,
      });
      this.loadEquipment(+categoryId, index);

      this.additionalEquipment.at(index).get('id')?.enable();
    }
  }

  onEquipmentSelectionChange(index: number, event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const equipmentId = selectElement.value;

    const equipmentArray = this.filteredEquipmentMap[index];

    const selectedEquipment = equipmentArray?.find(
      (equipment) => equipment.id.toString() === equipmentId
    );

    if (selectedEquipment) {
      this.additionalEquipment.at(index).patchValue({
        id: selectedEquipment.id,
        name: selectedEquipment.name,
      });

      if (index === this.additionalEquipment.length - 1) {
        this.addServiceLine();
      }
    }
  }

  updateDataSource(): void {
    this.additionalEquipmentDataSource.data =
      this.additionalEquipment.controls.map((control) => control.value);
  }
  removeItem(index: number): void {
    const item = this.additionalEquipment.at(index).value;
    if (item.id && item.id !== '0') {
      // Add to a separate array to track items user intends to remove.
      this.itemsMarkedForRemoval.push(item);
    }
    // Remove from the UI but keep in a way to revert if needed
    this.additionalEquipment.removeAt(index);
    this.updateDataSource();
  }


  displayValidationErrors() {
    Object.values(this.equipmentForm.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched({ onlySelf: true });
      } else {
        control.markAsTouched();
      }
    });
  
    this.dataHelper.Toast.fire({
      icon: 'warning',
      title: 'Please fill in all the required fields.',
    });
  }
  


  prepareMainEquipmentData() {
    const formValue = this.equipmentForm.getRawValue();
    if (this.additionalEquipment.length === 1) {
      formValue.additionalEquipment = [];
    } else {
      formValue.additionalEquipment = formValue.additionalEquipment.slice(0, -1);
    }
  
    // Construct and return the data object for the main equipment update
    return {
      id: this.equipmentId,
      serialNumber: formValue.serialNumber || null,
      name: formValue.name,
      status: formValue.status,
      chargePerDay: formValue.chargePerDay || null,
      equipmentCategoryId: formValue.equipmentCategoryId,
      ehrId: 0, // TODO: Adjust
      additionalEquipment: formValue.additionalEquipment || null,
    };
  }
  

  onSubmit() {
    if (this.equipmentForm.invalid) {
      this.displayValidationErrors(); // Display validation errors if any
      return;
    }
  
    const formValue = this.prepareMainEquipmentData(); //Prepare the main equipment data
  
    //Chain asynchronous operations
    this.processMarkedRemovals().pipe(
      switchMap(() => this.equipmentService.updateEquipmentById(this.equipmentId, formValue)),
      switchMap(async () => this.updateAdditionalEquipment(this.equipmentId, formValue))
    ).subscribe({
      next: () => this.postUpdateActions(), // Finalization after all operations
      error: (error) => console.error('Error during the update process:', error),
    });
  }    

  updateAdditionalEquipment(parentEquipmentId: number, parent: Equipment) {
    const validEquipmentItems = this.additionalEquipment.controls.slice(0, -1); // Removes the last item

    const updateOperations = validEquipmentItems.map((control, index) => {
      const additionalEquipmentData = {
        ...control.value,
        parentEquipmentId: parentEquipmentId,
        status: parent.status,
      };
      return this.equipmentService
        .updateEquipmentById(
          additionalEquipmentData.id,
          additionalEquipmentData
        )
        .pipe(
          catchError((error) => {
            console.error(
              `Error updating additional equipment at index ${index}:`,
              error
            );
            return of(null);
          })
        );
    });

    forkJoin(updateOperations).subscribe({
      next: () => {
        this.processMarkedRemovals();
        
      },
      error: (error) => {
        console.error('Error updating additional equipment:', error);
      },
    });
  }
  processMarkedRemovals(): Observable<any> {
    if (!this.itemsMarkedForRemoval.length) {
      return of(null); // Immediately complete if no items to process
    }
  
    const removalOperations = this.itemsMarkedForRemoval.map(item => {
      return this.equipmentService.updateEquipmentById(item.id, {
        ...item,
        parentEquipmentId: null,
      }).pipe(catchError(error => {
        console.error(`Error updating equipment ${item.id}:`, error);
        return of(null); // Handle errors as needed
      }));
    });
  
    return forkJoin(removalOperations).pipe(
      tap(() => this.itemsMarkedForRemoval = [])
    );
  }
  

  postUpdateActions() {
    this.equipmentForm.markAsPristine();
    this.equipmentForm.markAsUntouched();
    this.equipmentForm.reset();
    this.additionalEquipment.markAsPristine();
    this.additionalEquipment.markAsUntouched();
    this.additionalEquipment.reset();
    this.router.navigate([`/equipment/${this.equipmentCategoryName}`]);
  }

  cancel() {
    if (this.equipmentForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm(false);
        }
      });
    } else {
      this.revertForm(false);
    }
    this.itemsMarkedForRemoval = [];
  }

  revertForm(isUndo: boolean) {
    if (this.initialFormData) {
      this.equipmentForm.patchValue(this.initialFormData);
    }
    this.equipmentForm.markAsPristine();
    this.equipmentForm.markAsUntouched();
    this.isEditMode = isUndo? true : false;
    if (this.additionalEquipment.length > 0) {
      // Remove the last item from the array
      this.additionalEquipment.removeAt(this.additionalEquipment.length - 1);
    }
    this.itemsMarkedForRemoval = [];
    this.resetAdditionalEquipmentToOriginalState();
  }
  resetAdditionalEquipmentToOriginalState() {
    // Clear the current form array without affecting the original state.
    this.additionalEquipment.clear();
    // Repopulate the form array with the original items.
    this.originalAdditionalEquipment.forEach((item) => {
      let itemsFormGroup = this.fb.group({
        equipmentCategoryId: item.equipmentCategoryId,
        id: item.id,
        name: item.name,
        status: item.status,
        parentEquipmentId: item.parentEquipmentId,
      });
      this.additionalEquipment.push(itemsFormGroup);
    });
  }
  onEdit() {
    this.isEditMode = true;
    this.originalAdditionalEquipment = this.additionalEquipment.controls.map(
      (control) => ({
        ...control.value,
      })
    );
    this.additionalEquipment.push(this.createItem());
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this equipment? Some changes may affect major functionalities',
      () => {
        // Check if there are additional equipment items
        if (this.additionalEquipment.controls.length > 0) {
          // Proceed as before if there are additional equipment items
          const allAdditional = this.additionalEquipment.controls.map(c => c.value);
          const updateOperations = allAdditional.map(item => {
            return this.equipmentService.updateEquipmentById(item.id, {
              ...item,
              parentEquipmentId: null, // Ensure parentEquipmentId is null
            }).pipe(
              catchError((error) => {
                console.error(`Error updating additional equipment ${item.id}:`, error);
                return of(null); // Proceed despite error
              })
            );
          });
  
          // If additional equipment exists, update them first, then delete the main equipment
          return forkJoin(updateOperations).pipe(
            switchMap(() => this.equipmentService.deleteEquipment(this.equipmentId)),
            catchError((error) => {
              console.error('Error deleting main equipment:', error);
              return throwError(() => new Error('Deletion failed'));
            })
          );
        } else {
          // If no additional equipment, directly attempt to delete the main equipment
          return this.equipmentService.deleteEquipment(this.equipmentId).pipe(
            catchError((error) => {
              console.error('Error deleting main equipment:', error);
              return throwError(() => new Error('Deletion failed'));
            })
          );
        }
      },
      'Equipment deleted successfully',
      () => this.router.navigate([`/equipment/${this.equipmentCategoryName}`]),
      () => null // Adjust error handling as necessary
    );
  }
  
}
