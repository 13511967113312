import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VatService } from '@services/vat.service';
import { Observable } from 'rxjs';
import { DataHelper } from 'src/app/helpers/data-helper';

@Component({
  selector: 'app-new-vat',
  templateUrl: './new-vat.component.html',
  styleUrls: ['./new-vat.component.css']
})
export class NewVatComponent {
  vatForm!: FormGroup;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.vatForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.vatForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  constructor(
    private fb: FormBuilder,
    private vatService: VatService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.vatForm = this.fb.group({
      name: ['', Validators.required],
      value: ['', [Validators.required,Validators.min(0),Validators.max(100)]],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    if(!this.vatForm.valid){
      Object.values(this.vatForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.vatForm.getRawValue();
    const dataToSend = {
      name: formValue.name || 'string',
      value: formValue.value || null,
    };

    this.dataHelper.addNewRecord(
      this.vatForm,
      dataToSend,
      (data) => this.vatService.createVat(data),
      'Vat created successfully',
      '/masters/vats'
    );
  }

  cancel() {
    this.router.navigate(['/masters/vats']);
  }
}
