import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentDataService } from '@services/paymentData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../../helpers/data-helper';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { PaymentType } from '@interfaces/PaymentType.interface';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment-type.component.html',
  styleUrls: ['./edit-payment-type.component.css'],
})
export class EditPaymentTypeComponent implements OnInit {
  paymentForm!: FormGroup;
  paymentId!: number;
  paymentName: string = '';
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: PaymentType| null = null;


  constructor(
    private fb: FormBuilder,
    private paymentService: PaymentDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.paymentForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.paymentForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.paymentForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.paymentId = Number(params.get('id')); // Extract paymentId from the route

      if (!isNaN(this.paymentId)) {
        this.paymentService
          .getById(this.paymentId)
          .subscribe((payment) => {
            if (Array.isArray(payment) && payment.length > 0) {
              // If payment is an array, get the name from the first element of the array
              this.paymentName = payment[0].name;
              this.initialFormData = { ...payment[0] };

              this.paymentForm.patchValue({ name: this.paymentName }); // Prefill the "Name" field
            } else if (payment) {
              // If payment is a single object, get the name from it
              this.paymentName = payment.name;
              this.paymentForm.patchValue({ name: this.paymentName }); // Prefill the "Name" field
              this.initialFormData = { ...payment };

            }
            this.breadcrumbService.set('@typeName', this.paymentName );
          });
      } else {
        console.error('Invalid paymentId');
      }
    });
  }



  onSubmit() {
    if(!this.paymentForm.valid){
      Object.values(this.paymentForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.paymentForm.valid) {
      const updatedPayment = this.paymentForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this payment? Some changes may affect major functionalities',
        () =>
          this.paymentService.updatePayment(this.paymentId, updatedPayment),
        'Payment updated successfully',
        () => {
          this.paymentForm.markAsPristine();
          this.paymentForm.markAsUntouched();
          this.paymentForm.reset();
          this.router.navigate(['/masters/paymentTypes']);
        }
      );
    }
  }

  cancel() {
    if (this.paymentForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.paymentForm.patchValue(this.initialFormData);
    }
    this.paymentForm.markAsPristine();
    this.paymentForm.markAsUntouched();
    this.isEditMode = false;
  }


  exitView(){
    this.router.navigate(['/masters/paymentTypes']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this payment type? Some changes may affect major functionalities',
      () => this.paymentService.deletePayment(this.paymentId),
      'Payment type deleted successfully',
      () => this.router.navigate(['/masters/paymentTypes']),
      () => null
    );
  }
}
