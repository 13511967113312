import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { backend } from '@apis/api-endpoints';
import { Currency } from '@interfaces/Currency.interface';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<Currency[]> {
    return this.http.get<Currency[]>(backend.currencyData.getAll).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  getById(currencyId: number): Observable<Currency> {
    return this.http.get<Currency>(backend.currencyData.getById(currencyId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  createCurrency(currencyData: Currency): Observable<Currency> {
    return this.http.post<Currency>(backend.currencyData.post, currencyData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  updateCurrency(currencyId: number, updatedCurrencyData: Currency): Observable<Currency> {
    return this.http.put<Currency>( backend.currencyData.updateById(currencyId), updatedCurrencyData).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  deleteCurrency(currencyId: number): Observable<void> {
    return this.http.delete<void>( backend.currencyData.deleteById(currencyId)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
