import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Using a subject to handle the search term updates
  private searchSubject = new Subject<string>();
  currentSearchTerm: string = ''; // Add this line
  // Exposing the subject as an observable
  searchObservable$ = this.searchSubject.asObservable();

  // Method to update the search term
  updateSearchTerm(term: string) {
    this.searchSubject.next(term);
    this.currentSearchTerm = term; // And this line
  }
}
