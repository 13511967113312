import { Component, OnInit } from '@angular/core';
import { CategoryService } from '@services/category.service';
import { Category } from '@interfaces/Category.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { Column } from '@interfaces/Column.interface';
import { EquipmentCategory } from '@interfaces/EquipmentCategory.interface';
import { EquipmentCategoryService } from '@services/equipmentCategory.service';

@Component({
  selector: 'app-equipment-categories',
  templateUrl: './equipment-categories.component.html',
  styleUrls: ['./equipment-categories.component.css'],
})
export class EquipmentCategoriesComponent implements OnInit {
  displayedColumns:  Column[] = [
    { prop: 'id', header: this.translate.instant('ID') },
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'showInDashboard', header: this.translate.instant('Show in dashboard') },
  ];
  categories: EquipmentCategory[] = [];
  filteredCategories: EquipmentCategory[] = [];
  error: string | null = null;

  constructor(
    private translate: TranslateService,
    private categoryService: EquipmentCategoryService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  ngOnInit(): void {
    this.fetchCategories();
  }

  fetchCategories(): void {
    this.dataHelper.fetchItems(
      this.categoryService.getAll(),
      (data: EquipmentCategory[]) => {
        this.categories = data;
        this.filteredCategories = [...data];
      }
    );
  }

  applyFilter(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value.trim().toLowerCase();

    this.filteredCategories = this.categories.filter(
      (category) =>
        category.name.toLowerCase().includes(filterValue)
    );
  }

  deleteCategory(element: EquipmentCategory): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this category? Some changes may affect major functionalities',
      () => this.categoryService.deleteCategory(element.id),
      'Category deleted successfully',
      () => this.fetchCategories(),
      () => null
    );
  }

  addCategory() {
    this.router.navigate(['/masters/equipmentCategories/new_equipment_category']);
  }

  editCategory(element: EquipmentCategory): void {
    this.router.navigate(['/masters/equipmentCategories/edit_equipment_category', element.id]);
  }

  handleToggleChange(event: {item: EquipmentCategory, newValue: boolean}): void {
    // Prepare the data for the backend call
    const updatedData = {
      ...event.item,
      showInDashboard: event.newValue // Update the property with the new value
    };
  
    // Call the service to update the backend
    this.categoryService.updateCategory(updatedData.id, updatedData).subscribe(() => {
      // Handle successful update
      console.log('Update successful');
    }, error => {
      // Handle error
      console.error('Update failed', error);
    });
  }
  
}
