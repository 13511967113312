// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: auto;
  }
  
  .main-container {
    display: flex;
    flex: 1;
   
  }
  
  
  app-top-nav {
    position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
    /* flex: 0 0 auto; Don't grow, don't shrink, auto basis */
  }
  
  app-left-nav {
    width: 225px; /* Adjust as needed */
    background: linear-gradient(#1F2937, #3E5371);
    color: white;
    overflow-y: auto;
    
    padding-top: 60px;
  }
  
  .content {
    flex: 1; /* Grow to take up remaining space */
    overflow-y: auto; /* Add scrollbar if content overflows */
    padding: 20px 20px;
    
    padding-top: 60px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,OAAO;;EAET;;;EAGA;IACE,eAAe;EACjB,MAAM;EACN,WAAW;EACX,aAAa;IACX,yDAAyD;EAC3D;;EAEA;IACE,YAAY,EAAE,qBAAqB;IACnC,6CAA6C;IAC7C,YAAY;IACZ,gBAAgB;;IAEhB,iBAAiB;EACnB;;EAEA;IACE,OAAO,EAAE,oCAAoC;IAC7C,gBAAgB,EAAE,uCAAuC;IACzD,kBAAkB;;IAElB,iBAAiB;EACnB","sourcesContent":[".app-container {\r\n    display: flex;\r\n    height: 100vh;\r\n    flex-direction: column;\r\n    overflow: auto;\r\n  }\r\n  \r\n  .main-container {\r\n    display: flex;\r\n    flex: 1;\r\n   \r\n  }\r\n  \r\n  \r\n  app-top-nav {\r\n    position: fixed;\r\n  top: 0;\r\n  width: 100%;\r\n  z-index: 1000; \r\n    /* flex: 0 0 auto; Don't grow, don't shrink, auto basis */\r\n  }\r\n  \r\n  app-left-nav {\r\n    width: 225px; /* Adjust as needed */\r\n    background: linear-gradient(#1F2937, #3E5371);\r\n    color: white;\r\n    overflow-y: auto;\r\n    \r\n    padding-top: 60px;\r\n  }\r\n  \r\n  .content {\r\n    flex: 1; /* Grow to take up remaining space */\r\n    overflow-y: auto; /* Add scrollbar if content overflows */\r\n    padding: 20px 20px;\r\n    \r\n    padding-top: 60px;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
