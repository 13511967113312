// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-input, .pdfTitle-input {
    width: auto !important; /* Remove global width */
    min-width: auto !important; /* Remove global min-width */
    display: inline-block !important;
  }
`, "",{"version":3,"sources":["webpack://./src/app/helpers/email/email.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,wBAAwB;IAChD,0BAA0B,EAAE,4BAA4B;IACxD,gCAAgC;EAClC","sourcesContent":[".checkbox-input, .pdfTitle-input {\r\n    width: auto !important; /* Remove global width */\r\n    min-width: auto !important; /* Remove global min-width */\r\n    display: inline-block !important;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
