import { Component } from '@angular/core';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent {
  isLoading = true; // Initially set to true
  profilePic = 'path-to-profile-pic'; // Placeholder path

  userName: string | null = null;
  private subscription: Subscription = new Subscription();

  constructor(private userService: UserService) {
    this.isLoading = true; // set to false when the profile data is loaded
    this.profilePic = 'path-to-profile-pic'; // update with the actual path when the data is loaded
  }

  ngOnInit() {
    this.subscription.add(
      this.userService.name$.subscribe((userName) => {
        this.userName = userName;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // Clean up the subscription
  }
}
