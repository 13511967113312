// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content-to-download {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #000;
    font-family: Arial, sans-serif;
  }  
  .scrollable-container {
    overflow-x: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/sales/invoices/invoices.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC;EACA;IACE,gBAAgB;EAClB","sourcesContent":["#content-to-download {\r\n    width: 100%;\r\n    max-width: 800px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border: 1px solid #000;\r\n    font-family: Arial, sans-serif;\r\n  }  \r\n  .scrollable-container {\r\n    overflow-x: auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
