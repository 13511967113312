// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #f0f0f0;
    position:sticky

  }

  .language-selector {
    display: flex;
    align-items: center;
  }

  .selected-flag {
    margin-right: 10px; /* Adjust as needed */
  }


  .left img {
    height: 40px;
  }

  .right-settings {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    align-items: center;
    flex: 1;
    justify-content: end;
  }
  .erp-logo{
    width: 225px;
    height: 100%;
    padding-right: 20px;
    background-color: #1F2937;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-dropdown{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }

.top-nav-logo-image
{
  width: 140px;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/top-nav/top-nav.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB;;EAEF;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,kBAAkB,EAAE,qBAAqB;EAC3C;;;EAGA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,OAAO;IACP,oBAAoB;EACtB;EACA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;EACpB;;AAEF;;EAEE,YAAY;AACd","sourcesContent":[".top-nav {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 60px;\r\n    background-color: #f0f0f0;\r\n    position:sticky\r\n\r\n  }\r\n\r\n  .language-selector {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  .selected-flag {\r\n    margin-right: 10px; /* Adjust as needed */\r\n  }\r\n\r\n\r\n  .left img {\r\n    height: 40px;\r\n  }\r\n\r\n  .right-settings {\r\n    display: flex;\r\n    flex-direction: row;\r\n    padding-right: 20px;\r\n    align-items: center;\r\n    flex: 1;\r\n    justify-content: end;\r\n  }\r\n  .erp-logo{\r\n    width: 225px;\r\n    height: 100%;\r\n    padding-right: 20px;\r\n    background-color: #1F2937;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n\r\n  .language-dropdown{\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    margin-right: 10px;\r\n  }\r\n\r\n.top-nav-logo-image\r\n{\r\n  width: 140px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
