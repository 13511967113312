import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '@services/customer.service'; // Adjust the import as per your project structure
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from '../../../../helpers/data-helper';
import { CustomersResponse } from '@interfaces/CustomerResponse.interfaece';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { Customer } from '@interfaces/Customer.interface';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css'],
})
export class EditCustomerComponent implements OnInit {
  customerForm!: FormGroup;
  customerId!: number;
  customerData: CustomersResponse | null = null;
  isEditMode: boolean = false;
  initialFormData: Customer | null = null;


  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    this.customerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['',Validators.required],
      address: [''],
    });
  }

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.customerForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.customerForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.customerId = Number(params.get('id'));
      if (!isNaN(this.customerId)) {
        this.customerService.getCustomerById(this.customerId).subscribe(
          (customerResponse) => {
            if (customerResponse && customerResponse.items.length > 0) {
              this.customerData = customerResponse;
              this.customerForm.patchValue(customerResponse.items[0]);
              this.initialFormData = { ...customerResponse.items[0] };
              this.breadcrumbService.set('@customerName', `${this.customerData?.items[0].firstName} ${this.customerData?.items[0].lastName}`);
            } else {
              console.error('Customer not found');
            }
          },
          (error) => {
            console.error('Error fetching customer data:', error);
          }
        );
      } else {
        console.error('Invalid customerId');
      }
    });
  }

  onSubmit() {
    if(this.customerForm.invalid){
      Object.values(this.customerForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.customerForm.valid && this.customerData) {
      const updatedCustomer = this.customerForm.getRawValue();

      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this customer? Some changes may affect major functionalities',
        () =>
          this.customerService.updateCustomerById(
            this.customerId,
            updatedCustomer
          ),
        'Customer updated successfully',
        () => {
          this.customerForm.markAsPristine();
          this.customerForm.markAsUntouched();
          this.customerForm.reset(); 
          this.router.navigate(['/sales/customers'])
        }
      );
    }
  }

  onEdit(){
    this.isEditMode=true;

  }
  cancel() {
    if (this.customerForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.customerForm.patchValue(this.initialFormData);
    }
    this.customerForm.markAsPristine();
    this.customerForm.markAsUntouched();
    this.isEditMode = false;
  }

  onDelete(){
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this customer? Some changes may affect major functionalities',
      () => this.customerService.deleteCustomer(this.customerId),
      'Customer deleted successfully',
      () => this.router.navigate(['/sales/customers']),
      () => null
    );
  }

}
