import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobTitleService } from '@services/JobTitle.service'; // Adjust the import as per your project structure
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from 'src/app/helpers/data-helper';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-jobTitle',
  templateUrl: './new-jobTitle.component.html',
  styleUrls: ['./new-jobTitle.component.css'],
})
export class NewJobTitleComponent implements OnInit {
  jobTitleForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private jobTitleService: JobTitleService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.jobTitleForm = this.fb.group({
      name: ['', Validators.required],
    });
  }
  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.jobTitleForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.jobTitleForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {}

  onSubmit() {
    if(!this.jobTitleForm.valid){
      Object.values(this.jobTitleForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    const formValue = this.jobTitleForm.getRawValue();
    const dataToSend = {
      name: formValue.name || 'string',
    };
    
    this.dataHelper.addNewRecord(
      this.jobTitleForm,
      dataToSend,
      (data) => this.jobTitleService.createjobTitle(data), 
      'Job Title created successfully',
      '/masters/jobTitles' 
    );
  }
  

  cancel() {
    this.router.navigate(['/masters/jobTitles']);
  }
}
