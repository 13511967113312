import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@apis/api-endpoints';
import { UserService } from '@services/user.service';
import { Observable, Subscription, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private currentLocale: string | null = null;
  private localeSubscription?: Subscription;

  constructor(private userService: UserService,private http: HttpClient) {
    this.localeSubscription = this.userService.locale$.subscribe(locale => {
      this.currentLocale = locale;
    });
  }

  getCurrentLocale(): string | null {
       return this.currentLocale;

  }

  updateLocale(locale: string): Observable<any> {
    console.log(backend, backend?.localeType);
    return this.http.put(backend.localeType.put(locale), {},{ responseType: 'text' }).pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }

  ngOnDestroy(): void {
    this.localeSubscription?.unsubscribe();
  }
}
