import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandService } from '@services/brand.service'; // Adjust the import as per your project structure
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from 'src/app/helpers/data-helper';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { Brand } from '@interfaces/Brand.interface';

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.css'],
})
export class EditBrandComponent implements OnInit {
  brandForm!: FormGroup;
  brandId!: number;
  brandName: string = '';
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  initialFormData: Brand | null = null;

  constructor(
    private fb: FormBuilder,
    private brandService: BrandService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.brandForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.brandId = Number(params.get('id')); // Extract brandId from the route

      if (!isNaN(this.brandId)) {
        this.brandService
          .getById(this.brandId)
          .subscribe((brand) => {
            if (Array.isArray(brand) && brand.length > 0) {
              // If brand is an array, get the name from the first element of the array
              this.brandName = brand[0].name;
              this.brandForm.patchValue({ name: this.brandName }); // Prefill the "Name" field
              this.initialFormData = { ...brand[0] };
            } else if (brand) {
              // If brand is a single object, get the name from it
              this.brandName = brand.name;
              this.brandForm.patchValue({ name: this.brandName }); // Prefill the "Name" field
              this.initialFormData = { ...brand };
            }
            this.breadcrumbService.set('@brandName', this.brandName);
          });
      } else {
        console.error('Invalid brandId');
      }
    });
  }

  dataHelper: DataHelper = new DataHelper(this.snackBar, this.router, this.translate, this.dialog);

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.brandForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.brandForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  onSubmit() {
    if(!this.brandForm.valid){
      Object.values(this.brandForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.brandForm.valid) {
      const updatedBrand = this.brandForm.getRawValue();
      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this brand? Some changes may affect major functionalities',
        () =>
          this.brandService.updateBrand(this.brandId, updatedBrand),
        'Brand updated successfully',
        () => {
          this.brandForm.markAsPristine();
          this.brandForm.markAsUntouched();
          this.brandForm.reset();
          this.router.navigate(['/masters/brands']);
        }
      );
    }
  }

  cancel() {
    if (this.brandForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.brandForm.patchValue(this.initialFormData);
    }
    this.brandForm.markAsPristine();
    this.brandForm.markAsUntouched();
    this.isEditMode = false;
  }


  exitView(){
    this.router.navigate(['/masters/brands']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this brand? Some changes may affect major functionalities',
      () => this.brandService.deleteBrand(this.brandId),
      'Brand deleted successfully',
      () => this.router.navigate(['/masters/brands']),
      () => null
    );
  }
}
