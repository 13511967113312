import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataHelper } from '../../helpers/data-helper';
import { HResourceDataService } from '@services/resourceData.service';
import { InventoryDataService } from '@services/inventoryData.service';
import { PaginationData } from '../../helpers/pagination-data';
import { Column } from '@interfaces/Column.interface';
import { Module } from '@interfaces/Module.interface';
import { LeftNavService } from '@services/left-nav.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { GetEquipment } from '@interfaces/GetEquipment.interface';
import { EquipmentService } from '@services/equipment.service';
import { EquipmentResponse } from '@interfaces/EquipmentResponse.interface';

@Component({
  selector: 'app-employee',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css'],
})
export class EquipmentComponent implements OnInit {

  
  equipment: GetEquipment[] = [];
  currentPage: number = PaginationData.currentPage;
  pageSize: number = PaginationData.pageSize;  
  totalEquipment: number = 0;
  error: string | null = null;
  modules!: Module[];
  equipmentCategoryName: string | null = null;

  dataHelper: DataHelper = new DataHelper(
    this.snackBar,
    this.router,
    this.translate,
    this.dialog
  );

  displayedColumns:  Column[] = [
    { prop: 'name', header: this.translate.instant('Name') },
    { prop: 'status', header: this.translate.instant('Status') },
    { prop: 'equipmentCategory', header: this.translate.instant('EquipmentCategory') }
  ];

  constructor(
    private translate: TranslateService,
    private equipmentService: EquipmentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private hResourceDataService: HResourceDataService,
    private inventoryDataService: InventoryDataService,
    private route: ActivatedRoute,
    private leftNavService: LeftNavService,
    private breadCrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.fetchEquipment();
  }



  fetchEquipment(page: number = this.currentPage, size: number = this.pageSize): void {
    this.route.paramMap.subscribe(params => {
      this.equipmentCategoryName = params.get('equipmentCategoryName');
      this.leftNavService.getData().subscribe((response: Module[]) => {
        this.modules = response;
        // console.log("HERE")
        this.modules.forEach((module)=>{
          if(module.name.toLowerCase() == "equipment"){
            if(module.childModules){
              module.childModules.forEach((child)=>{
                if(child.name.toLowerCase() == this.equipmentCategoryName?.toLowerCase()){
                  console.log(child.orderBy)
                  this.dataHelper.fetchItems(
                    this.equipmentService.getEquipments(child.orderBy,page, size),
                    (response: EquipmentResponse) => {
                      this.equipment = response.items;
                      this.totalEquipment = response.totalItems;
                     
                    }
                  );  
                }
              })
            }
          }
        })
          });
      }, error => {
        console.error('Failed to load invoice number:', error);
      });
        
      
     
    
  }

  pageChanged(event: any): void {
    this.currentPage = event.pageIndex + 1; // MatPaginator is 0-based, but typically APIs are 1-based.
    this.pageSize = event.pageSize;
    this.fetchEquipment(this.currentPage, this.pageSize);
  }

  deleteEquipment(element: GetEquipment): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this equipment? Some changes may affect major functionalities',
      () => this.equipmentService.deleteEquipment(element.id),
      'Equipment deleted successfully',
      () => this.fetchEquipment(),
      () => null
    );
  }

  addEquipment() {
    this.router.navigate(['/equipment', this.equipmentCategoryName, 'new_equipment']); // Navigate to the new equipment route
  }

  editEquipment(element: GetEquipment): void {
    this.router.navigate(['/equipment',this.equipmentCategoryName, 'edit_equipment', element.id]); // Adjust the route as per your routing configuration
  }
}
