import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetProduct } from '@interfaces/GetProduct.interface';
import { backend } from '@apis/api-endpoints';
import { ProductsResponse } from '@interfaces/ProductResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProducts(categoryId:number,pageNumber: number, pageSize: number): Observable<ProductsResponse> {
    return this.http.get<ProductsResponse>(backend.productsData.getAll(categoryId,pageNumber, pageSize)).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  addProduct(product: any): Observable<any> {
    return this.http.post(backend.productsData.post, product, { responseType: 'text' }).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }


  deleteProduct(productId: number): Observable<void> {
    return this.http.delete<void>(`${backend.productsData.deleteById(productId)}`).pipe(
      catchError((err) => {
        console.error(err.error);
          return throwError(() => err.error);
      })
    );
  }

  getProductById(productId: number): Observable<GetProduct> {
    return this.http.get<GetProduct>(`${backend.productsData.getById(productId)}`).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }

  updateProductById(productId: number, product: any): Observable<any> {
    return this.http.put(backend.productsData.updateById(productId), product, {
      responseType: 'text',
    }).pipe(
      catchError((err) => {
        console.error(err.error);
        return throwError(() => err.error);
      })
    );
  }
}
