import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobTitleService } from '@services/JobTitle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataHelper } from 'src/app/helpers/data-helper';
import { faArrowLeftRotate, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Observable } from 'rxjs';
import { JobTitle } from '@interfaces/JobTitle.interface';

@Component({
  selector: 'app-edit-jobTitle',
  templateUrl: './edit-jobTitle.component.html',
  styleUrls: ['./edit-jobTitle.component.css'],
})
export class EditJobTitleComponent implements OnInit {
  jobTitleForm!: FormGroup;
  jobTitleId!: number;
  faEdit = faPen
  faDelete = faTrashCan
  faExit = faArrowLeftRotate
  isEditMode: boolean = false
  jobTitleName: string = ""
  initialFormData: any | null = null;


  constructor(
    private fb: FormBuilder,
    private jobTitleService: JobTitleService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    this.jobTitleForm = this.fb.group({
      name: ['', Validators.required],
    });
  }
  dataHelper: DataHelper = new DataHelper(this.snackBar,this.router,this.translate,this.dialog)

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.dataHelper.getBeforeUnloadEventMessage($event, this.jobTitleForm.dirty);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.jobTitleForm.dirty) {
      return true;
    }
    return this.dataHelper.confirmLeave();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.jobTitleId = Number(params.get('jobTitleId')); // Extract jobTitleId from the route

      if (!isNaN(this.jobTitleId)) {
        this.jobTitleService
          .getjobTitle(this.jobTitleId)
          .subscribe((jobTitle) => {
            console.log('jobTitle Data:', jobTitle); // Log the fetched jobTitle data
            if (Array.isArray(jobTitle) && jobTitle.length > 0) {
              // If jobTitle is an array, patch the form with the first element of the array
              this.jobTitleForm.patchValue(jobTitle[0]);
              this.initialFormData = { ...jobTitle[0] };

            } else if (jobTitle) {
              // If jobTitle is a single object, patch the form with it
              this.jobTitleForm.patchValue(jobTitle);
              this.initialFormData = { ...jobTitle };

            }
          this.jobTitleName = Array.isArray(jobTitle) ? jobTitle[0].name : jobTitle.name;
          this.breadcrumbService.set('@jobTitleName', this.jobTitleName);
          });
      } else {
        console.error('Invalid jobTitleId');
      }
    });
  }

  onSubmit() {
    if(!this.jobTitleForm.valid){
      Object.values(this.jobTitleForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.dataHelper.Toast.fire({
        icon: 'warning',
        title: 'Please fill in all the required fields.'
      });
      return;
    }
    if (this.jobTitleForm.valid) {
      const updatedjobTitle = this.jobTitleForm.getRawValue();

      this.dataHelper.withConfirmation(
        'Update Confirmation',
        'Do you confirm the update of this Job Title? Some changes may affect major functionalities',
        () =>
          this.jobTitleService.updatejobTitle(
            this.jobTitleId,
            updatedjobTitle
          ), // Wrapped inside a function
        'Job Title updated successfully',
        () =>{
          this.jobTitleForm.markAsPristine();
          this.jobTitleForm.markAsUntouched();
          this.jobTitleForm.reset(); 
          this.router.navigate(['/masters/jobTitles'])
        }
      );
    }
  }

  cancel() {
    if (this.jobTitleForm.dirty) {
      this.dataHelper.confirmLeave().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.revertForm();
        }
      });
    } else {
      this.revertForm();
    }
  }

  revertForm() {
    if (this.initialFormData) {
      this.jobTitleForm.patchValue(this.initialFormData);
    }
    this.jobTitleForm.markAsPristine();
    this.jobTitleForm.markAsUntouched();
    this.isEditMode = false;
  }


  exitView(){
    this.router.navigate(['/masters/jobTitles']);
  }


  onEdit(){
    this.isEditMode = true;
  }
  onDelete(): void {
    this.dataHelper.withConfirmation(
      'Delete Confirmation',
      'Do you confirm the deletion of this job title? Some changes may affect major functionalities',
      () => this.jobTitleService.deletejobTitle(this.jobTitleId),
      'Job Title deleted successfully',
      () => this.router.navigate(['/masters/jobTitles']),
      () => null
    );
  }
}
